import { timelineDotClasses, timelineItemClasses } from '@mui/lab';
import { Card, CardHeader, Grid, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EcommerceWidgetSummary, ReportNote, TableCustom, TimelineCustom, TimeLineStep } from 'src/components';
import { TableHeadType } from 'src/features/leads-page';

import { MainBudget, NoteSettingType } from '../../../../../../types';
import { TimelineCashflowSkeleton } from './timeline-cashflow-skeleton';
import { TimelineCollapse } from './timeline-collapse';
import { TimelineContentChild } from './timeline-content-child';

export interface TimelineCashflowProps {
  showData?: boolean;
  totalCosts?: string;
  costPSQM?: string;
  niagea?: string;
  totalNia?: string;
  purchaseComplete?: string;
  constructionStarts?: string;
  constructionComplete?: string;
  lastUnitSold?: string;
  cashflowItems?: MainBudget[];
  cashflowItemHeader?: TableHeadType[];
  noteSetting?: NoteSettingType;
}

export const TimelineCashflow = (props: TimelineCashflowProps) => {
  const {
    showData = false,
    cashflowItems = [],
    cashflowItemHeader = [],
    costPSQM = 'Unspecified',
    totalCosts = 'Unspecified',
    totalNia = 'Unspecified',
    niagea = 'Unspecified',
    purchaseComplete = 'Unspecified',
    constructionStarts = 'Unspecified',
    constructionComplete = 'Unspecified',
    lastUnitSold = 'Unspecified',
    noteSetting,
  } = props;
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const { t } = useTranslation(['report', 'common']);

  const handleRowClick = (key: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const timelineSteps: TimeLineStep[] = [
    {
      dotColor: 'success',
      showConnector: true,
      timeLineDotChild: 1,
      timeLineContent: <TimelineContentChild title={t('purchaseComplete')} subTitle={purchaseComplete} />,
    },
    {
      dotColor: 'success',
      showConnector: true,
      timeLineDotChild: 2,
      timeLineContent: <TimelineContentChild title={t('constructionStart')} subTitle={constructionStarts} />,
    },
    {
      dotColor: 'success',
      showConnector: true,
      timeLineDotChild: 3,
      timeLineContent: <TimelineContentChild title={t('constructionComplete')} subTitle={constructionComplete} />,
    },
    {
      dotColor: 'success',
      timeLineDotChild: 4,
      timeLineContent: <TimelineContentChild title={t('lastUnitSold')} subTitle={lastUnitSold} />,
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>{t('reportSections.timelineAndCashflow')}</Typography>
      </Grid>
      {showData ? (
        <Grid container item spacing={3}>
          {noteSetting?.isActive &&
              <Grid item md={12}>
                {showData
                  ? <ReportNote note={noteSetting.note} files={noteSetting.files} />
                  : <Skeleton variant='rounded' height='150px' width='100%' />
                }
              </Grid>
          }
          <Grid item xs={4}>
            <Card sx={{ p: '2px 8px' }}>
              <TimelineCustom
                timelineSteps={timelineSteps}
                timelineSx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                  [`& .${timelineDotClasses.root}`]: {
                    backgroundColor: '#3698af',
                    p: '0 7px',
                  },
                }}
              />
            </Card>
          </Grid>
          <Grid container item xs={8} spacing={3} height='356px'>
            <Grid item xs={6}>
              <EcommerceWidgetSummary
                title={t('projectProperties.totalCostsUpper')}
                total={totalCosts}
              />
            </Grid>
            <Grid item xs={6}>
              <EcommerceWidgetSummary
                title={t('projectProperties.costPSQM')}
                total={costPSQM}
              />
            </Grid>
            <Grid item xs={6}>
              <EcommerceWidgetSummary
                title={t('projectProperties.niaGea')}
                total={niagea}
              />
            </Grid>
            <Grid item xs={6}>
              <EcommerceWidgetSummary
                title={t('projectProperties.totalNia')}
                total={totalNia}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title={t('reportSections.cashflow')} />
              <TableCustom
                headLabels={[{
                  label: t('reportSections.cashflow'),
                  _id: 'cashflow',
                }, ...cashflowItemHeader]}
                tableHeadSx={{ whiteSpace: 'nowrap' }}
                noDataFound={cashflowItems.length === 0}
                tableContainerSx={{ marginTop: '24px' }}
                bodyCells={
                  <>
                    {cashflowItems?.map((item, index) => {
                      return (
                        <TimelineCollapse
                          key={index}
                          item={item}
                          level={1}
                          maxLevel={3}
                          borderLevel={2}
                          siblingsItems={cashflowItems}
                          parentKey={String(index)}
                          expandedRows={expandedRows}
                          onRowClick={handleRowClick}
                        />
                      );
                    })}
                  </>
                }
              />
            </Card>
          </Grid>
        </Grid>
      ) :
        <TimelineCashflowSkeleton />
      }
    </Grid>
  );
};
