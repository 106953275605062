import { Grid, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import { ChangeEvent, memo, MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomTab, TableCustom, TableFiltersResult, TablePaginationCustom, TableToolbar, TabType, UploadedFilesType } from 'src/components';
import { useBoolean, useTable } from 'src/hooks';
import { declinePartnerDashboardItem } from 'src/store/storage';

import { PartnerDashboardInterface, PartnerDashboardListResponse, ReportKeyType } from '../../../../types';
import { RootState } from '../../store';
import { _projectTypeList } from '../projects-offering-page';
import { DealsRow, UploadDealTs } from './components';
import { allFilters, dealsTableHead, defaultDealsTableCorrespondingBody, defaultFilters } from './mock-data';
import { DealsTableFilters, DealsTableFilterValue, DealsTableParams } from './types';

export interface DealsPageProps {
  loadDealsFunc: (params: DealsTableParams) => Promise<PartnerDashboardListResponse | undefined>;
  onRowClick: (item: PartnerDashboardInterface) => void;
  uploadTsFileHandler: (item: PartnerDashboardInterface, file: UploadedFilesType, callBack?: VoidFunction) => void;
  dashboardType: ReportKeyType;
}

export const DealsPage = memo((props: DealsPageProps) => {
  const {
    loadDealsFunc,
    uploadTsFileHandler,
    onRowClick,
    dashboardType,
  } = props;

  const [filters, setFilters] = useState(defaultFilters);
  const table = useTable({ tableKey: `deals-${dashboardType}` });
  const [correspondingBody, setCorrespondingBody] = useState<DealsTableParams>({
    ...defaultDealsTableCorrespondingBody,
    limit: table.rowsPerPage,
  });
  const [currentItem, setCurrentItem] = useState<PartnerDashboardInterface | null>(null);
  const [dealsList, setDealsList] = useState<PartnerDashboardListResponse>();

  const drawer = useBoolean();
  const isLoading = useBoolean();
  const user = useSelector((state: RootState) => state.Auth.user);

  const getDealsList = useCallback(async (requestBody: Partial<DealsTableParams>) => {
    try {
      const newBody: DealsTableParams = {
        ...correspondingBody,
        ...requestBody,
      };
      const response = await loadDealsFunc(newBody);
      setCorrespondingBody(newBody);
      setDealsList(response);
    } catch (error) {
      console.log(error);
    }
  }, [correspondingBody, loadDealsFunc]);

  useEffect(() => {
    getDealsList(correspondingBody);
  }, []);

  const TABS: TabType[] = useMemo(() => {
    return [
      {
        value: 'New',
        label: 'New',
        count: dealsList?.counter['New'],
      },
      {
        value: 'In Progress',
        label: 'In Progress',
        count: dealsList?.counter['In Progress'],
      },
      {
        value: 'Done',
        label: 'Done',
        count: dealsList?.counter['Done'],
      },
      {
        value: 'Offer declined',
        label: 'Offer declined',
        count: dealsList?.counter['Offer declined'],
      },
      {
        value: 'Rejected',
        label: 'Rejected',
        count: dealsList?.counter['Rejected'],
      },
      {
        value: 'All',
        label: 'All',
        count: dealsList?.counter.All,
      },
    ];
  }, [dealsList?.counter]);

  const handleFilters = useCallback((name: keyof DealsTableFilters, value: DealsTableFilterValue) => {
    getDealsList({
      [name]: value,
      page: 1,
    });
    table.onResetPage();
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, [table, getDealsList]);

  const handleFilterStatus = useCallback((_: SyntheticEvent, newValue: string) => {
    handleFilters('status', newValue);
  }, [handleFilters]);

  const handleResetFilters = useCallback(() => {
    setFilters(allFilters);
    getDealsList({
      ...allFilters,
      limit: table.rowsPerPage,
    });
  }, [getDealsList, table.rowsPerPage]);

  const canReset =
    !!filters.text ||
    filters.projectType?.length > 0 ||
    filters.status !== 'All';

  const noDataFound = ((dealsList?.projects?.length || 0) === 0 && canReset) || (dealsList?.projects?.length || 0) === 0;

  const onPageChange = debounce((event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    getDealsList({ page: page + 1 });
    table.onChangePage(event, page);
  }, 200);

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    getDealsList({
      limit: +event.target.value,
      page: 1,
    });
    table.onChangeRowsPerPage(event);
  };

  const onFiltersDeleteHandler = useCallback((filterKey: keyof DealsTableFilters, valueToDelete: DealsTableFilterValue) => {
    const filterValue = filters[filterKey];
    let newValue = defaultFilters[filterKey];
    if (Array.isArray(filterValue)) {
      newValue = filters.projectType.filter((item) => item !== valueToDelete);
    }
    handleFilters(filterKey, newValue);
  }, [filters, handleFilters]);

  const handleDeleteRow = useCallback((id: string) => async () => {
    if (!user?.partnerId) return;
    await declinePartnerDashboardItem(id, dashboardType, 'reject', user?.partnerId);
    await getDealsList(correspondingBody);
  }, [correspondingBody, dashboardType, getDealsList, user?.partnerId]);

  const handleDeclineRow = useCallback((id: string) => async () => {
    if (!user?.partnerId) return;
    await declinePartnerDashboardItem(id, dashboardType, 'decline', user?.partnerId);
    await getDealsList(correspondingBody);
  }, [correspondingBody, dashboardType, getDealsList, user?.partnerId]);

  const onRowClickHandler = (item: PartnerDashboardInterface) => () => {
    onRowClick(item);
  };

  const onUploadTs = (item: PartnerDashboardInterface) => () => {
    setCurrentItem(item);
    drawer.onTrue();
  };

  const onSubmitClickUpload = async (file: UploadedFilesType) => {
    if (currentItem?._id) {
      isLoading.onTrue();
      const callBack = async () => {
        await getDealsList(correspondingBody);
      };
      uploadTsFileHandler(currentItem, file, callBack);
    }
    drawer.onFalse();
    isLoading.onFalse();
    setCurrentItem(null);
  };

  const onSortChange = debounce((id: string) => {
    const {
      orderBy,
      order,
    } = table;
    const isAsc = orderBy === id && order === 'asc';
    getDealsList({
      sort: id,
      sortType: isAsc ? 'desc' : 'asc',
    });
    table.onSort(id);
  }, 200);

  return (
    <Container maxWidth={false}>
      <Grid container style={{ marginBottom: '40px' }}>
        <Grid item xs={6}>
          <Typography variant='h4'>
            My Deals
          </Typography>
        </Grid>
      </Grid>
      <Card>
        <CustomTab
          tabs={TABS}
          defaultTabValue='New'
          currentTabValue={filters.status}
          handleTabOnChange={handleFilterStatus}
        />
        <TableToolbar
          selectValue={filters.projectType}
          selectOptions={_projectTypeList}
          selectedOptions={filters.projectType}
          onFilters={handleFilters}
          selectLabel='Project type'
          selectKey='projectType'
          searchFieldValue={filters.text}
          searchFieldKey='text'
        />
        {canReset && (
          <Stack
            sx={{
              pt: 1.5,
              paddingLeft: '20px',
              paddingBottom: '16px',
            }}
          >
            <TableFiltersResult
              filters={filters}
              defaultFilters={allFilters}
              onFiltersDeleteHandler={onFiltersDeleteHandler}
              onResetFilters={handleResetFilters}
              results={dealsList?.metadata.total}
            />
          </Stack>
        )}

        <TableCustom
          headLabels={dealsTableHead}
          table={table}
          rowCount={dealsList?.metadata.total}
          noDataFound={noDataFound}
          onSort={onSortChange}
          bodyCells={(
            <>
              {dealsList?.projects?.map((row) => (
                <DealsRow
                  key={row._id}
                  row={row}
                  onViewRow={onRowClickHandler(row)}
                  onUploadTs={onUploadTs(row)}
                  onDeleteRow={handleDeleteRow(row._id)}
                  onDeclineRow={handleDeclineRow(row._id)}
                />
              ))}
            </>
          )}
        />

        <TablePaginationCustom
          count={dealsList?.metadata?.total || 0}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Card>

      <UploadDealTs
        drawer={drawer}
        isLoading={isLoading}
        onSubmitClick={onSubmitClickUpload}
      />
    </Container>
  );
});

