import { NavListProps, NavSectionProps } from 'src/components/menu';
import { flattenArray } from 'src/utils';

type ItemProps = {
  group: string;
  title: string;
  disabled?: boolean;
  path?: string;
};

export const getAllItems = ({ data }: NavSectionProps) => {
  const items = flattenArray(data).map((option) => {
    const group = splitPath(data, option.path || '');

    return {
      group: group && group.length > 1 ? group[0] : option.title,
      title: option.title,
      disabled: option.disabled,
      path: option.path,
    };
  });

  return items;
};


type FilterProps = {
  inputData: ItemProps[];
  query: string;
};
type LoopReturnType = NavListProps & {
  subheader: string;
};

export const applyFilter = ({
  inputData,
  query,
}: FilterProps) => {
  if (query) {
    inputData = inputData.filter(
      (item) =>
        item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.path?.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    );
  }

  return inputData;
};

export const splitPath = (array: NavListProps[], key: string) => {
  let stack = array.map((item) => ({
    path: [item.title],
    currItem: item,
  }));

  while (stack.length) {
    const {
      path,
      currItem,
    } = stack.pop() as {
      path: string[];
      currItem: NavListProps;
    };

    if (currItem.path === key) {
      return path;
    }

    if (currItem.children?.length) {
      stack = stack.concat(
        currItem.children.map((item: NavListProps) => ({
          path: path.concat(item.title),
          currItem: item,
        })),
      );
    }
  }
  return null;
};

export const handleLoop = (array: NavListProps[], subheader: string = ''): LoopReturnType[] => {
  return array?.map((list) => ({
    subheader,
    ...list,
    ...(list.children && { children: handleLoop(list.children, subheader) }),
  }));
};

type GroupsProps = {
  [key: string]: ItemProps[];
};

export const groupedData = (array: ItemProps[]) => {
  const group = array.reduce((groups: GroupsProps, item) => {
    groups[item.group] = groups[item.group] || [];

    groups[item.group].push(item);

    return groups;
  }, {});

  return group;
};
