import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserGroupIcon } from 'src/assets/svg';
import { blueGradient } from 'src/constants/colors';
import { getReportSettingsFromDetailViewData } from 'src/features/report-detail/helpers';
import ReportDetail from 'src/features/report-detail/report-detail';
import { RootState } from 'src/store';
import { addNewReportSettings, setPreviewDetailsData } from 'src/store/storage';

import { General, MergedType } from './general';

enum TabType {
  general = 'General',
  preview = 'Preview',
  investing = 'Investing',
  emailTemplate = 'Email Template',
}

export type AssetProps = {
  leadId?: string;
}

export const Assets = (props: AssetProps) => {
  const { leadId } = props;
  const theme = useTheme();
  const [tab, setTab] = useState<TabType>(TabType.general);
  const [isPreviewClicked, setIsPreviewClicked] = useState<boolean>(false);
  const dispatch = useDispatch();
  const generalFormRef = useRef<() => MergedType>(() => ({} as MergedType));
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const previewDetailsData = useSelector((state: RootState) => state.Storage.previewDetailsData);

  const onPreviewClickHandler = () => {
    if (!leadId || !detailsData.assets?._id) {
      return;
    }
    const previewAssets = generalFormRef.current();
    const previewDetailsData = {
      ...detailsData,
      assets: { ...detailsData.assets, ...previewAssets },
    };
    setTab(TabType.preview);
    dispatch(setPreviewDetailsData(previewDetailsData));
    const reportSettings = getReportSettingsFromDetailViewData(leadId, previewDetailsData, 'calculator');
    dispatch(addNewReportSettings(reportSettings));
  };

  const onBackIconClickHandler = () => {
    setIsPreviewClicked(true);
    setTab(TabType.general);
  };

  return (
    <Stack direction='row' gap={5}>
      <Stack sx={{ minWidth: 180 }} gap={3}>
        <Stack
          sx={{
            gap: 2,
            p: 2,
            borderRadius: 1,
            background: blueGradient,
          }}
        >
          <Stack
            textAlign='center'
            alignItems='center'
            justifyContent='center'
            gap={1}
          >
            <UserGroupIcon color={theme.palette.info.main} width={32} height={32} />
            <Typography variant='body2' color='text.primary'>
              Preview how recipient of report will see it
            </Typography>
          </Stack>
          <Button
            variant='contained'
            size='small'
            onClick={onPreviewClickHandler}
            fullWidth
          >
            Preview
          </Button>
        </Stack>
      </Stack>

      {tab === TabType.general && <General
        detailsData={isPreviewClicked ? previewDetailsData : detailsData}
        setFormGetter={(getter) => (generalFormRef.current = getter)}
      />}
      {tab === TabType.preview && <ReportDetail
        isPreview
        onBackIconClickHandler={onBackIconClickHandler}
        type='calculator'
      />}
    </Stack>
  );
};
