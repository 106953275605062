import Box, { BoxProps } from '@mui/material/Box';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { HEADER, NAV } from 'src/constants/constants';
import { useResponsive, useSettingsContext } from 'src/hooks';
import { RootState } from 'src/store';

const SPACING = 8;

export const Main = memo((props: BoxProps) => {
  const {
    children,
    sx,
    ...other
  } = props;

  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');

  const isDeepSideMenuOpen = useSelector((state: RootState) => state.Storage.isDeepSideMenuOpen);

  return (
    <Box
      component='main'
      sx={{
        flexGrow: 1,
        minHeight: 1,
        maxWidth: settings.themeStretch ? '100%' : 'xl',
        display: 'flex',
        flexDirection: 'column',
        mx: 'auto',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(!lgUp && { width: '100%' }),
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: isDeepSideMenuOpen
            ? `calc(100% - ${NAV.W_MINI_WITH_SECTION}px)`
            : `calc(100% - ${NAV.W_MINI}px)`,
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
});
