import { StackProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { NavListMiniItem } from 'src/components/menu/components/mini/nav-list-mini-item';
import { toggleDeepSideMenu } from 'src/store/storage';

import { NavListProps } from '../../types';

export interface NavSectionMiniProps extends StackProps {
  data: NavListProps[];
  openedSection: NavListProps | null;
  setOpenedSection: (item: NavListProps | null) => void;
  isSubItem?: boolean;
}

export const NavSectionMini = memo((props: NavSectionMiniProps) => {
  const {
    data,
    openedSection,
    isSubItem,
    setOpenedSection,
    ...other
  } = props;
  const dispatch = useDispatch();

  const onSectionClickHandler = (item: NavListProps) => () => {
    const isTheSameSection = item.title === openedSection?.title;
    setOpenedSection(isTheSameSection ? null : item);
    dispatch(toggleDeepSideMenu(!isTheSameSection));
  };

  return (
    <Stack gap={0.5} {...other}>
      {data.map((item, index) => (
        <NavListMiniItem
          isSubItem={isSubItem}
          key={index}
          data={item}
          onSectionClickHandler={onSectionClickHandler(item)}
          openedSection={openedSection}
          isSectionDisabled={item.disabled}
        />
      ))}
    </Stack>
  );
});

