import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

type TablePaginationCustomProps = TablePaginationProps & {
  sx?: SxProps<Theme>;
  rowsPerPageOptions?: number[];
};

export const TablePaginationCustom = memo((props: TablePaginationCustomProps) => {
  const {
    rowsPerPageOptions = [5, 10, 25],
    sx,
    count = 0,
    ...other
  } = props;
  const { t } = useTranslation();

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={count}
        labelRowsPerPage={t('tableRows.rowsPerPage')}
        {...other}
        sx={{ borderTopColor: 'transparent' }}
      />
    </Box>
  );
});
