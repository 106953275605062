import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Chip, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CrumbType, FormProvider, ProjectToolbar, RHFAutocomplete, RHFEditor, RHFTextField, UploadedFilesType } from 'src/components';
import { paths } from 'src/config';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import InvestorProjectDetails from 'src/features/investor-portal/projects/project-details/project-details';
import { ProjectDocuments } from 'src/features/project-preparation-details/components/assets/project-documents/project-documents';
import { useBoolean, useDocuments, useResponsive, useRouter, useScrollToError } from 'src/hooks';
import { RootState } from 'src/store';
import { publishOffering } from 'src/store/partners-portal';
import { convertToOnlyNumber, formatFullName, getMarketplaceUrl } from 'src/utils';
import * as Yup from 'yup';

import { AssetDocumentsType, AssigneeType, AssignmentMember, DetailViewData, FinanceUploadFileType, OmittedPublishOpportunityType } from '../../../../../../../types';
import { SalesManager } from './sales-manager/sales-manager';
import { SuccessModal } from './success-modal';

export const OpportunityPublish = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const [documentsModalList, setDocumentsModalList] = useState<UploadedFilesType[]>([]);
  const [view, setView] = useState<'form' | 'investor'>('form');
  const theme = useTheme();
  const successDrawer = useBoolean();
  const mdUp = useResponsive('up', 'md');
  const { createAndUploadDocument } = useDocuments();
  const router = useRouter();
  const dispatch = useDispatch();

  const detailsData = useSelector((store: RootState) => store.Storage.detailsData);
  const partnerId = useSelector((store: RootState) => store.Auth.user?.partnerId) || '';
  const marketplaceUrl = getMarketplaceUrl(id);
  const assets = detailsData.assets;
  const lead = detailsData.lead;
  const project = detailsData.project;
  const offeringDetails = detailsData.offeringDetails;

  const loadDocuments = useCallback(() => {
    setDocumentsModalList(assets?.documents || []);
  }, [assets?.documents]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  const crumbs = useMemo<CrumbType[]>(() => {
    return [
      {
        title: 'Investments',
        href: paths.partnerPortal.opportunities.list,
      },
      {
        title: 'New Opportunities',
        href: paths.partnerPortal.opportunities.list,
      },
      {
        title: project?.name,
        color: theme.palette.text.disabled,
      },
    ];
  }, [project?.name, theme.palette.text.disabled]);

  const PublishSchema: Yup.ObjectSchema<OmittedPublishOpportunityType> = Yup.object().shape({
    listingName: Yup.string().required('Marketplace Listing Name is required'),
    investmentAmount: Yup.object().shape({
      min: Yup.string().required('Minimum amount is required').test('min', 'Minimum investment amount must be greater than 0', value => (convertToOnlyNumber(value) || 0) > 0),
      max: Yup.string().required('Maximum amount Name is required')
        .test(
          'investmentAmount-max',
          'Maximum investment amount must be greater than minimum investment amount',
          (value, { parent }) => {
            return (convertToOnlyNumber(value) || 0) > (convertToOnlyNumber(parent.min) || 0);
          },
        ),
    }),
    designLayout: Yup.string(),
    tags: Yup.array().of(Yup.string().required()),
    documents: Yup.mixed(),
    documentsToDelete: Yup.mixed(),
    assignedSalesManager: Yup.mixed(),
    assignedSalesManagerToDelete: Yup.mixed(),
    reportConfigurations: Yup.mixed(),
  });

  const defaultValues: OmittedPublishOpportunityType = useMemo(() => ({
    listingName: offeringDetails?.listingName || assets?.general?.projectName || '',
    investmentAmount: {
      min: offeringDetails?.investmentAmount?.min?.toString() || assets?.investing?.minInvestmentAmount?.toString() || '0',
      max: offeringDetails?.investmentAmount?.max?.toString() || assets?.investing?.maxInvestmentAmount?.toString() || '0',
    },
    designLayout: offeringDetails?.designLayout || assets?.marketPlaceListing?.description || '',
    tags: offeringDetails?.tags || assets?.marketPlaceListing?.tags || [],
    documents: offeringDetails?.documents || [],
    documentsToDelete: [],
    assignedSalesManager: lead?.assignees?.find(assignee => assignee.designation === 'Sales manager'),
    assignedSalesManagerToDelete: undefined,
  }), [assets?.general?.projectName, assets?.investing?.maxInvestmentAmount, assets?.investing?.minInvestmentAmount, assets?.marketPlaceListing?.description, assets?.marketPlaceListing?.tags, lead?.assignees, offeringDetails?.designLayout, offeringDetails?.documents, offeringDetails?.investmentAmount?.max, offeringDetails?.investmentAmount?.min, offeringDetails?.listingName, offeringDetails?.tags]);

  const methods = useForm<OmittedPublishOpportunityType>({
    resolver: yupResolver(PublishSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: {
      isSubmitting,
      errors,
    },
  } = methods;
  useScrollToError(errors);
  const values = watch();

  let assignees: AssigneeType[] = lead?.assignees?.length ? lead.assignees : [];
  if (values.assignedSalesManager?.profileLink) {
    assignees = [...assignees, {
      ...values.assignedSalesManager,
      name: formatFullName(values.assignedSalesManager.name, values.assignedSalesManager.surname),
    }];
  }

  const customDetailData: DetailViewData = {
    ...detailsData,
    lead: {
      ...lead,
      _id: lead?._id || '',
      price: lead?.price || 0,
      source: lead?.source || 'Manual',
      status: lead?.status || 'New',
      assignees: assignees,
    },
    assets: {
      ...assets,
      _id: assets?._id || '',
      investing: {
        ...assets?.investing,
        minInvestmentAmount: convertToOnlyNumber(values.investmentAmount?.min),
      },
      marketPlaceListing: {
        ...assets?.marketPlaceListing,
        description: values.designLayout,
        tags: values.tags,
      },
    },
    offeringDetails: {
      ...offeringDetails,
      documents: values.documents,
    },
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    const updatedData = {
      ...data,
      investmentAmount: {
        min: convertToOnlyNumber(data.investmentAmount?.min),
        max: convertToOnlyNumber(data.investmentAmount?.max),
      },
    };
    const response = await publishOffering(id, updatedData, partnerId, lead?._id, offeringDetails?._id);
    if (response) {
      successDrawer.onTrue();
    }
  });

  const modalUploadFileHandler = async (file: FinanceUploadFileType) => {
    const uploadedFile = await createAndUploadDocument(file, partnerId, project?._id, lead?._id);
    if (uploadedFile) {
      setDocumentsModalList([...documentsModalList, {
        ...uploadedFile,
        size: Math.round((uploadedFile.size || 0) / 1024),
        filename: uploadedFile.sectionFilename || uploadedFile.filename,
      }]);
    }
  };

  const onRemoveUploadedFileClickHandler = async (file: UploadedFilesType) => {
    const filesToDelete = values.documentsToDelete || [];
    const updatedFiles = values.documents?.filter(doc => doc._id !== file._id);
    setValue('documentsToDelete', [...filesToDelete, file]);
    setValue('documents', updatedFiles);
  };

  const onAddDocumentsClickHandler = (files: UploadedFilesType[], missingDocs: UploadedFilesType[] = []) => {
    const selectedFiles = values.documents || [];
    const documentsToDelete = values.documentsToDelete || [];
    const filteredSelectedDocuments = selectedFiles.filter(doc => !missingDocs.find(file => file._id === doc._id));
    setValue('documents', [...filteredSelectedDocuments, ...files as AssetDocumentsType[]]);
    setValue('documentsToDelete', [...documentsToDelete, ...missingDocs as AssetDocumentsType[]]);
  };

  const setSalesManager = (manager?: AssignmentMember) => {
    const assignedSalesManager = values.assignedSalesManager?._id ? values.assignedSalesManager : undefined;
    assignedSalesManager && setValue('assignedSalesManagerToDelete', values.assignedSalesManager);
    !manager?._id && setValue('assignedSalesManager', manager);
  };

  const onClose = () => {
    router.push('overview');
  };

  const onPreviewCLickHandler = () => {
    setView('investor');
  };
  const onInvestorBackIconClickHandler = () => {
    setView('form');
  };

  const settings = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Settings
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            Investment Amount, basic Details, reports displaying and documents
          </Typography>
        </Grid>
      )}
      <Grid xs={12} md={8}>
        <Stack spacing={3} sx={{ p: 3 }} flexWrap='wrap'>
          <Card>
            {!mdUp
              && <CardHeader
                title='Settings'
                subheader={<Typography variant='body2' color='text.secondary'>
                  Investment Amount, basic Details,
                  <br />
                  reports displaying and documents
                </Typography>
                }
              />
            }
            <Stack sx={{ p: 3 }} gap={3} flexWrap='wrap' flexDirection='row'>
              <RHFTextField
                useSeparators
                name='investmentAmount.min'
                label={`* Minimum investment amount, ${DEFAULT_CURRENCY}`}
                sx={{ width: 'calc(50% - 12px)' }}
              />
              <RHFTextField
                useSeparators
                name='investmentAmount.max'
                label={`* Maximum investment amount, ${DEFAULT_CURRENCY}`}
                sx={{ width: 'calc(50% - 12px)' }}
              />
            </Stack>
          </Card>
          <Card>
            <Stack sx={{ p: 3 }} spacing={3}>
              <RHFTextField
                name='listingName'
                label='* Marketplace Listing Name'
              />
              <RHFEditor simple name='designLayout' />
              <RHFAutocomplete
                multiple
                freeSolo
                isTagAutoComplete
                name='tags'
                label='Tags'
                placeholder='Tags'
                options={[]}
                getOptionLabel={undefined}
                renderOption={undefined}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option}
                      label={option}
                      size='small'
                      variant='soft'
                    />
                  ))
                }
              />
            </Stack>
          </Card>
          <ProjectDocuments
            onRemoveUploadedFileClickHandler={onRemoveUploadedFileClickHandler}
            modalUploadFileHandler={modalUploadFileHandler}
            uploadedDocuments={values.documents}
            documentsModalList={documentsModalList}
            subTitle='Add documents that will be displayed in the marketplace listing'
            subheader='These documents will be displayed in the marketplace listing'
            onAddDocumentsClickHandler={onAddDocumentsClickHandler}
          />
          <SalesManager
            manager={values.assignedSalesManager}
            setManager={setSalesManager}
          />
        </Stack>
      </Grid>
    </>
  );

  const renderActions = (
    <Stack gap={2} direction='row' ml='auto'>
      <Button
        variant='outlined'
        size='large'
        onClick={onPreviewCLickHandler}
      >
        Preview
      </Button>
      <LoadingButton
        type='submit'
        variant='contained'
        size='large'
        loading={isSubmitting}
      >
        Publish
      </LoadingButton>
    </Stack>
  );

  return (
    <>
      {view === 'form' &&
        <>
          <ProjectToolbar
            crumbs={crumbs}
            title={detailsData.project?.name}
            showBadge={false}
          />
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid
              container
              sx={{
                mx: 0,
                px: 3,
              }}
              rowGap={3}
            >
              {settings}
              {renderActions}
            </Grid>
          </FormProvider>
          <SuccessModal
            drawer={successDrawer}
            onClose={onClose}
            marketplaceUrl={marketplaceUrl}
            refLink={marketplaceUrl}
          />
        </>
      }
      {view === 'investor' &&
        <InvestorProjectDetails
          isPreview
          showBackArrow
          customDetailsData={customDetailData}
          customBackArrowClick={onInvestorBackIconClickHandler}
        />
      }
    </>
  );
};
