import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import { debounce } from 'lodash';
import { ChangeEvent, MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomTab, TableCustom, TableFiltersResult, TablePaginationCustom, TabType } from 'src/components';
import { investmentDefaultFilters } from 'src/features/marketplace-details/components/partners-fees/transactions/mock-data';
import { TABLE_HEAD } from 'src/features/marketplace-details/components/project-details/mock-data';
import { useTable } from 'src/hooks';
import { RootState } from 'src/store';
import { GetInvestmentActivityParams } from 'src/store/partners-portal/types';
import { sortCustom } from 'src/utils';

import {
  ActivityStatus,
  InvestmentActivityListResponse,
  InvestmentActivityTableFilters,
  MarketplaceProjectActivity,
  MarketplaceProjectActivityStatusType,
} from '../../../../../../../../../types';
import { OfferingActivityTableRow } from './offering-activity-table-row';

type OfferingActivityListProps = {
  isPartner?: boolean;
  isPartnerOffering?: boolean;
  useCustomPagination?: boolean;
  loadTableDataFn?: (params: GetInvestmentActivityParams, partnerId: string) => Promise<InvestmentActivityListResponse | undefined>;
}

export const OfferingActivityList = (props: OfferingActivityListProps) => {
  const user = useSelector((state: RootState) => state.Auth.user);

  const {
    isPartner,
    isPartnerOffering,
    loadTableDataFn,
    useCustomPagination,
  } = props;

  const table = useTable({ tableKey: 'offering-activity' });
  const [filters, setFilters] = useState(investmentDefaultFilters);
  const [tableData, setTableData] = useState<InvestmentActivityListResponse>({
    page: 1,
    total: 0,
    data: [],
  });
  const [correspondingBody, setCorrespondingBody] = useState<GetInvestmentActivityParams>({
    page: 1,
    limit: table.rowsPerPage,
    status: 'All',
  });

  const dataFiltered: MarketplaceProjectActivity[] = useCustomPagination ? sortCustom(tableData.data || [], table) : tableData.data || [];
  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage,
  );

  const getItems = useCallback(async (params: GetInvestmentActivityParams) => {
    const data = await loadTableDataFn?.(params, user?.partnerId || '');
    if (data) {
      setTableData(data);
    }
  }, [loadTableDataFn, user?.partnerId]);

  useEffect(() => {
    getItems?.(correspondingBody);
  }, [getItems]);

  const TABS: TabType[] = useMemo(() => {
    return [
      {
        value: 'All',
        label: 'All',
        count: tableData?.counter?.All,
      },
      {
        value: 'Reserved',
        label: 'Reserved',
        count: tableData?.counter?.Reserved,
      },
      {
        value: 'Soft commitment ready for sign',
        label: 'Commitment Sent',
        count: tableData?.counter?.['Soft commitment ready for sign'],
      },
      {
        value: 'Commitment Signed',
        label: 'Commitment Signed',
        count: tableData?.counter?.['Commitment Signed'],
      },
      {
        value: 'Invoice Sent',
        label: 'Invoice Sent',
        count: tableData?.counter?.['Invoice Sent'],
      },
      {
        value: 'Secured Capital',
        label: 'Secured Capital',
        count: tableData?.counter?.['Secured Capital'],
      },
      {
        value: 'Rejected',
        label: 'Rejected',
        count: tableData?.counter?.Rejected,
      },
    ];
  }, [tableData?.counter]);

  const canReset = filters.status !== 'All';

  const updatePagination = useCallback((newBodyPart: Partial<GetInvestmentActivityParams>) => {
    const newBody: GetInvestmentActivityParams = {
      ...correspondingBody,
      ...newBodyPart,
    };
    getItems?.(newBody);
    setCorrespondingBody(newBody);
  }, [correspondingBody, getItems]);

  const noDataFound = (tableData?.data?.length === 0 && canReset) || dataFiltered?.length === 0;

  const onPageChange = debounce((event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    updatePagination({ page: page + 1 });
    table.onChangePage(event, page);
  }, 200);

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updatePagination({
      limit: +event.target.value,
      page: 1,
    });
    table.onChangeRowsPerPage(event);
  };

  const handleFilters = useCallback((name: keyof InvestmentActivityTableFilters, value: ActivityStatus) => {
    updatePagination({
      [name]: value,
      page: 1,
    });
    table.onResetPage();
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, [table, updatePagination]);

  const handleFilterStatus = useCallback((_: SyntheticEvent, newValue: string) => {
    handleFilters('status', newValue as MarketplaceProjectActivityStatusType);
  }, [handleFilters]);

  const handleResetFilters = useCallback(() => {
    updatePagination(investmentDefaultFilters);
    setFilters(investmentDefaultFilters);
  }, [updatePagination]);

  const onFiltersDeleteHandler = useCallback((filterKey: keyof InvestmentActivityTableFilters) => {
    const newValue = investmentDefaultFilters[filterKey];
    handleFilters(filterKey, newValue);
  }, [handleFilters]);

  return (
    <Card>
      {isPartnerOffering &&
        <CardHeader
          title='Activity'
          sx={{
            px: 3,
            pt: 3,
            pb: 4,
          }}
        />
      }

      {isPartner &&
        <>
          <CustomTab
            tabs={TABS}
            defaultTabValue='All'
            currentTabValue={filters.status}
            handleTabOnChange={handleFilterStatus}
          />
          {canReset && (
            <Stack
              sx={{
                paddingLeft: '20px',
                paddingBottom: '16px',
              }}
            >
              <TableFiltersResult
                filters={filters}
                defaultFilters={investmentDefaultFilters}
                onFiltersDeleteHandler={onFiltersDeleteHandler}
                onResetFilters={handleResetFilters}
                results={dataFiltered.length}
              />
            </Stack>
          )}
        </>
      }

      <TableCustom
        headLabels={TABLE_HEAD}
        table={table}
        rowCount={tableData.total}
        noDataFound={noDataFound}
        bodyCells={(
          <>
            {(useCustomPagination ? dataInPage : dataFiltered)?.map((row) => (
              <OfferingActivityTableRow
                key={row._id}
                row={row}
                refetchFunction={() => getItems(correspondingBody)}
              />
            ))}
          </>
        )}
      />

      <TablePaginationCustom
        count={tableData.total || 0}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Card>
  );
};
