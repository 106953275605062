import { enUS as enUSCore, svSE as svSECore } from '@mui/material/locale';
import { enUS as enUSDataGrid, svSE as svSEDataGrid } from '@mui/x-data-grid';
import { enUS as enUSDate, svSE as svSEDate } from '@mui/x-date-pickers/locales';
import { enUS as enUSAdapter, sv as svSEAdapter } from 'date-fns/locale';
import merge from 'lodash/merge';

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'Svenska',
    value: 'sv',
    systemValue: merge(svSEDate, svSEDataGrid, svSECore),
    adapterLocale: svSEAdapter,
    icon: 'flagpack:se',
  },
];

export const defaultLang = allLangs[0]; // English
