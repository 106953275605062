import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';

import { Page } from './page';

interface PageWrapProps {
  children: ReactNode;
  mainSx?: SxProps<Theme>;
}

export const PageWrap = (props: PageWrapProps) => {
  const {
    children,
    mainSx,
  } = props;

  return (
    <Page mainSx={mainSx}>
      {children}
    </Page>
  );
};
