import { yupResolver } from '@hookform/resolvers/yup';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CustomDialog, FormProvider, RHFAutocomplete, SubmitButton } from 'src/components';
import { UseBooleanReturnType } from 'src/hooks';
import { isEmailValid } from 'src/utils';
import * as Yup from 'yup';

import { ApiFetchReturnType } from '../../../../types';

export interface SharePartnersDialogProps {
  shareBool: UseBooleanReturnType;
  onCopyClickHandler: VoidFunction;
  onSubmitClickHandler: (data: SharePartnersDialogFormType) => Promise<ApiFetchReturnType>;
}

export type SharePartnersDialogFormType = {
  emails: string[];
}

export const SharePartnersDialog = (props: SharePartnersDialogProps) => {
  const {
    shareBool,
    onCopyClickHandler,
    onSubmitClickHandler,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const schema: Yup.ObjectSchema<SharePartnersDialogFormType> = Yup.object().shape({
    emails: Yup.array().min(1, 'Write at least one email').required().test('partners-email', 'One of email is invalid', value => {
      return value.every(item => isEmailValid(item));
    }),
  });

  const defaultValues: SharePartnersDialogFormType = useMemo(() => ({ emails: [] }), []);

  const methods = useForm<SharePartnersDialogFormType>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const onClose = () => {
    shareBool.onFalse();
    reset(defaultValues);
  };

  const onSubmit = handleSubmit(async (data) => {
    const response = await onSubmitClickHandler(data);
    if (!response?.success) {
      enqueueSnackbar(response.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      return;
    }
    onClose();
    enqueueSnackbar(response.message, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  });

  return (
    <CustomDialog
      showDialogTitle
      dialogProps={{
        open: shareBool.value,
        onClose: onClose,
      }}
      dialogHeadChildren={
        <Stack flexDirection='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='h6'>Share Report</Typography>
          <Button
            variant='text'
            color='primary'
            onClick={onCopyClickHandler}
            startIcon={<ContentCopyIcon color='primary' />}
          >
            Copy Link
          </Button>
        </Stack>
      }
      dialogContentChildren={
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <RHFAutocomplete
            isTagAutoComplete
            multiple
            freeSolo
            clearOnBlur
            name='emails'
            placeholder='Type email'
            options={[]}
            getOptionLabel={undefined}
            renderOption={undefined}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option}
                  label={option}
                  size='small'
                  variant='soft'
                />
              ))
            }
          />
        </FormProvider>
      }
      dialogActionsChildren={
        <Stack direction='row' gap={1.5}>
          <SubmitButton
            loading={isSubmitting}
            onClick={onSubmit}
            title='Share'
          />
          <Button
            variant='outlined'
            onClick={onClose}
          >
            Close
          </Button>
        </Stack>
      }
    />
  );
};
