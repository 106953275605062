import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { TableCustom, UploadedFilesType } from 'src/components';
import { QUOTATIONS_TABLE_HEAD } from 'src/features/tendering-page/mock-data';
import { useTable } from 'src/hooks';

import { DocumentListResponse, QuotationType } from '../../../../../../../../types';
import { QuotationTableRow } from './quotation-table-row';
import { RequestDrawerSubmit } from './request-drawer';
import { UploadFormDrawerSubmit } from './upload-form-drawer';

interface QuotationsProps {
  quotations: QuotationType[];
  deleteOnClickHandler: (quotationId: string) => void;
  sendReqItemizedHandler: (item: QuotationType) => (data: RequestDrawerSubmit) => void;
  sendItemizedHandler: (item: QuotationType) => (data: UploadFormDrawerSubmit) => void;
  sendReqDetailedHandler: (item: QuotationType) => (data: RequestDrawerSubmit) => void;
  sendDetailedHandler: (item: QuotationType) => (data: UploadFormDrawerSubmit) => void;
  sendReqTermSheetHandler: (item: QuotationType) => (data: RequestDrawerSubmit) => void;
  sendUploadTermSheetHandler: (item: QuotationType) => (data: UploadedFilesType) => void;
  getUploadedFiles: (item: QuotationType) => Promise<DocumentListResponse[]>;
}

export const Quotations = (props: QuotationsProps) => {
  const {
    quotations,
    deleteOnClickHandler,
    sendDetailedHandler,
    sendReqDetailedHandler,
    sendReqTermSheetHandler,
    sendUploadTermSheetHandler,
    getUploadedFiles,
    sendReqItemizedHandler,
    sendItemizedHandler,
  } = props;
  const table = useTable({ tableKey: 'quotations' });
  const noDataFound = quotations?.length === 0;

  return (
    <Card>
      <CardHeader sx={{ pb: 3 }} title='Quotations' />
      <TableCustom
        headLabels={QUOTATIONS_TABLE_HEAD}
        table={table}
        noDataFound={noDataFound}
        bodyCells={(
          <>
            {quotations?.map((row) => (
              <QuotationTableRow
                deleteOnClickHandler={deleteOnClickHandler}
                sendDetailedHandler={sendDetailedHandler(row)}
                sendReqDetailedHandler={sendReqDetailedHandler(row)}
                sendReqTermSheetHandler={sendReqTermSheetHandler(row)}
                sendUploadTermSheetHandler={sendUploadTermSheetHandler(row)}
                getUploadedFiles={getUploadedFiles}
                sendReqItemizedHandler={sendReqItemizedHandler(row)}
                sendItemizedHandler={sendItemizedHandler(row)}
                key={row._id}
                row={row}
              />
            ))}
          </>
        )}
      />
    </Card>
  );
};
