import { Stack } from '@mui/material';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TeamMemberCard, TeamMemberCardProps } from 'src/components';
import { RootState } from 'src/store';
import { getUserList } from 'src/store/app';

import { AssignmentMember, TeamRoleType, User } from '../../../../../types';

export interface TeamProps extends Partial<TeamMemberCardProps> {
  projectManager?: AssignmentMember;
  analyst?: AssignmentMember;
  handleAssignRole?: (designation: TeamRoleType, assignedUser?: AssignmentMember) => (user: User) => void;
}

export const Team = memo((props: TeamProps) => {
  const {
    projectManager,
    analyst,
    handleAssignRole,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const userList = useSelector((state: RootState) => state.App.userList);

  useEffect(() => {
    dispatch(getUserList('investor'));
  }, [dispatch]);

  return (
    <Stack spacing={3} direction='row' width='712px'>
      <TeamMemberCard
        {...rest}
        users={userList}
        teamRole='Project Manager'
        handleAssign={handleAssignRole?.('Project manager', projectManager)}
        assignedUser={projectManager}
      />
      <TeamMemberCard
        {...rest}
        users={userList}
        teamRole='Analyst'
        handleAssign={handleAssignRole?.('Analyst', analyst)}
        assignedUser={analyst}
      />
    </Stack>
  );
});
