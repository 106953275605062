import { AboutProject, AboutProjectProps } from 'src/features/fin-upraisal/about-project';

import {
  Comparables,
  ComparablesProps,
  Construction,
  ConstructionProps,
  Finance,
  FinanceProps,
  LocalArea,
  LocalAreaType,
  LocalMarket,
  LocalMarketType,
  ProjectMultiples,
  ProjectMultiplesProps,
  ProposedUnits,
  ProposedUnitsProps,
  Sales,
  SalesProps,
  SensitivityAnalysis,
  SensitivityAnalysisProps,
  SiteAndPurchase,
  SiteAndPurchaseType,
  TimelineCashflow,
  TimelineCashflowProps,
} from './components';
import { ReportType } from './types';

export const getAboutProject = (props: AboutProjectProps): ReportType => {
  return {
    name: 'About Project',
    translationKey: 'report:reportSections.aboutProject',
    component: <AboutProject {...props} />,
  };
};

export const getSiteAndPurchase = (props: SiteAndPurchaseType): ReportType => {
  return {
    name: 'Site & Purchase',
    translationKey: 'report:reportSections.siteAndPurchase',
    component: <SiteAndPurchase {...props} />,
  };
};

export const getProposedUnits = (props: ProposedUnitsProps): ReportType => {
  return {
    name: 'Proposed Units',
    translationKey: 'report:reportSections.proposedUnits',
    component: <ProposedUnits {...props} />,
  };
};

export const getConstruction = (props: ConstructionProps): ReportType => {
  return {
    name: 'Construction',
    translationKey: 'report:reportSections.construction',
    component: <Construction {...props} />,
  };
};

export const getFinance = (props: FinanceProps): ReportType => {
  return {
    name: 'Finance',
    translationKey: 'report:reportSections.finance',
    component: <Finance {...props} />,
  };
};

export const getTimelineCashflow = (props: TimelineCashflowProps): ReportType => {
  return {
    name: 'Timeline & Cashflow',
    translationKey: 'report:reportSections.timelineAndCashflow',
    component: <TimelineCashflow {...props} />,
  };
};

export const getSales = (props: SalesProps): ReportType => {
  return {
    name: 'Sales',
    translationKey: 'report:reportSections.sales',
    component: <Sales {...props} />,
  };
};

export const getProjectMultiples = (props: ProjectMultiplesProps): ReportType => {
  return {
    name: 'Project Multiples',
    translationKey: 'report:reportSections.projectMultiples',
    component: <ProjectMultiples {...props} />,
  };
};

export const getSensitivityAnalysis = (props: SensitivityAnalysisProps): ReportType => {
  return {
    name: 'Sensitivity Analysis',
    translationKey: 'report:reportSections.sensitivityAnalysis',
    component: <SensitivityAnalysis {...props} />,
  };
};

export const getLocalArea = (props: LocalAreaType): ReportType => {
  return {
    name: 'Local Area',
    translationKey: 'report:reportSections.localArea',
    component: <LocalArea {...props} />,
  };
};

export const getLocalMarket = (props: LocalMarketType): ReportType => {
  return {
    name: 'Local Market',
    translationKey: 'report:reportSections.localMarket',
    component: <LocalMarket {...props} />,
  };
};

export const getComparables = (props: ComparablesProps): ReportType => {
  return {
    name: 'Comparables',
    translationKey: 'report:reportSections.comparables',
    component: <Comparables {...props} />,
  };
};
