import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useBoolean } from 'src/hooks/use-boolean';
import { RootState } from 'src/store';
import { getUserListAsync } from 'src/store/app';

import { User } from '../../../types';

export type DeleteUserType = User & { label: string };

export const useDeleteUser = () => {
  const user = useSelector((state: RootState) => state.Auth.user);
  const loadingUsers = useBoolean();
  const deleteUserBool = useBoolean();
  const isLastAdmin = useBoolean();
  const isLastUserBool = useBoolean();
  const [usersInCompany, setUsersInCompany] = useState<DeleteUserType[]>([]);

  const onDeleteAccountClickHandler = async () => {
    loadingUsers.onTrue();
    const usersInCompany = await getUserListAsync(user?.partnerId || '');
    const filteredUsers = usersInCompany?.filter(userInCompany => userInCompany?.id !== user?.id);
    const uniqueUsers = filteredUsers?.reduce((acc, user) => {
      if (!acc.some(u => u.email === user.email)) {
        acc.push({
          ...user,
          label: user.email,
        });
      }
      return acc;
    }, [] as (User & { label: string })[]);
    setUsersInCompany(uniqueUsers);
    const isAtLeastOneAdmin = filteredUsers?.some(user => user?.role === 'admin');
    const usersAmount = filteredUsers?.length;

    loadingUsers.onFalse();
    if (usersAmount === 0) {
      isLastUserBool.onTrue();
      return;
    }
    if (!isAtLeastOneAdmin) {
      isLastAdmin.onTrue();
      return;
    }
    deleteUserBool.onTrue();
  };
  return {
    loadingUsers,
    deleteUserBool,
    isLastAdmin,
    isLastUserBool,
    usersInCompany,
    onDeleteAccountClickHandler,
  };
};
