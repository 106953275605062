import { identity, omit, pickBy } from 'lodash';

import { CompanyType, CoverImagesType, PartnerItemKeys, PartnerItemType, PartnersListType, PartnerUserListType, ProductType, SupplierProductType } from '../../../../types';
import { apiFetch } from '../api';
import { setIsLoading } from '../storage';
import { AppThunk } from '../store';
import {
  addPartner,
  addProduct,
  deletePartner,
  deleteProduct,
  setCurrentPartner,
  setCurrentProduct,
  setPartners,
  setPartnerUsers,
  updateCurrentPartner,
  updateCurrentProduct,
} from './index';
import { GetProductsResponse, LoadProductsParams } from './types';

export const getPartners = (partnerVariant?: PartnersListType, storeKey?: PartnerItemKeys): AppThunk => async (dispatch, getState) => {
  const partnerId = getState().Auth.user?.partnerId || '';

  try {
    dispatch(setIsLoading(true));
    const partnersList: PartnerItemType[] = await apiFetch(`partner/list${partnerVariant ? `/${partnerVariant}` : ''}`, {
      method: 'get',
      headers: { partnerId },
    });
    if (!partnersList) {
      throw new Error('Can not get partners');
    }

    dispatch(setIsLoading(false));
    if (!storeKey) return;
    dispatch(setPartners({
      partnerKey: storeKey,
      partners: partnersList,
    }));
  } catch (error) {
    console.error(error);
    if (!storeKey) return;
    dispatch(setPartners({
      partnerKey: storeKey,
      partners: [],
    }));
    dispatch(setIsLoading(false));
  }
};

export const getPartnerUserList = (partnerUserListType: PartnerUserListType = 'registered', companyType?: CompanyType): AppThunk => async (dispatch, getState) => {
  try {
    const partnersList: PartnerItemType[] = await apiFetch('partner-user/list', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        companyType,
        partnerUserListType,
      }),
    });
    dispatch(setPartnerUsers(partnersList));
  } catch (error) {
    console.error(error);
  }
};

export const deletePartnerThunk = (partnerId: string, storeKey?: PartnerItemKeys, deleteUsers?: boolean, callBack?: VoidFunction): AppThunk => async (dispatch, getState) => {
  const partnerIdOwner = getState().Auth.user?.partnerId || '';

  try {
    const response = await apiFetch(`partner/delete/${partnerId}/${deleteUsers}`, {
      method: 'delete',
      headers: { partnerId: partnerIdOwner },
    });
    if (!response) {
      throw new Error('Can not delete partner');
    }
    callBack?.();
    if (storeKey) {
      dispatch(deletePartner({
        partnerId,
        partnerKey: storeKey,
      }));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getPartnerDetail = (partnerId: string): AppThunk => async (dispatch, getState) => {
  const partnerIdOwner = getState().Auth.user?.partnerId || '';

  try {
    dispatch(setIsLoading(true));
    const response = await apiFetch(`partner/details/${partnerId}`, {
      method: 'get',
      headers: { partnerId: partnerIdOwner },
    });
    if (!response) {
      throw new Error('Can not get partner details');
    }
    dispatch(setCurrentPartner(response));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    console.error(error);
  }
};

export const createPartner = (partner: Partial<PartnerItemType>, storeKey?: PartnerItemKeys, callBack?: (partner: PartnerItemType) => void, selfOwned?: boolean): AppThunk => async (dispatch, getState) => {
  const partnerIdOwner = selfOwned ? 'self' : getState().Auth.user?.partnerId || '';
  const filteredPartner = pickBy(partner, identity);
  const updatedBody = omit(filteredPartner, ['logo', 'logoUrl']);

  try {
    const response: { partnerId: string } = await apiFetch(`partner${partner._id ? `/${partner._id}` : ''}`, {
      method: 'post',
      body: JSON.stringify({ ...updatedBody }),
      headers: {
        'Content-Type': 'application/json',
        partnerId: partnerIdOwner,
      },
    });
    if (!response) {
      throw new Error('Can not create partner');
    }
    const updatedPartner: PartnerItemType = {
      ...updatedBody,
      _id: response.partnerId,
    };

    callBack?.(updatedPartner);

    if (!storeKey) return;

    dispatch(updateCurrentPartner({
      partner: updatedPartner,
      partnerKey: storeKey,
    }));
    if (!partner._id) {
      dispatch(addPartner({
        partnerKey: storeKey,
        newPartner: updatedPartner,
      }));
    }

    if (filteredPartner.logo) {
      dispatch(uploadPartnerImg({
        ...filteredPartner,
        _id: response.partnerId,
      }, storeKey));
    }
  } catch (error) {
    console.error(error);
  }
};

export const uploadPartnerImg = (partner: PartnerItemType, storeKey: PartnerItemKeys): AppThunk => async (dispatch, getState) => {
  const partnerIdOwner = getState().Auth.user?.partnerId || '';

  try {
    const response = await apiFetch(`partner/upload-logo/${partner._id}`, {
      method: 'post',
      body: partner.logo,
      headers: {
        'Content-Type': partner.logo?.type || 'image/png',
        partnerId: partnerIdOwner,
      },
    });
    if (!response) {
      throw new Error('Can not upload logo');
    }
    const updatedPartner = omit({
      ...partner,
      ...response,
    }, 'logo');
    dispatch(updateCurrentPartner({
      partner: updatedPartner,
      partnerKey: storeKey,
    }));
  } catch (error) {
    console.error(error);
  }
};

export const createProduct = (partnerId: string, data: Partial<SupplierProductType>, callBack?: VoidFunction): AppThunk => async (dispatch, getState) => {
  const partnerIdOwner = getState().Auth.user?.partnerId || '';

  try {
    const updatedData = {
      ...omit(data, ['images', '_id', 'coverImage', 'imagesToDelete']),
      partnerId: partnerId,
    };

    if (data.roofAngle || data.roofAngle === 0) {
      updatedData.roofAngle = String(data.roofAngle);
    }

    const res: { productId: string } = await apiFetch(`partner/product/create${data._id ? `/${data._id}` : ''}`, {
      method: 'post',
      body: JSON.stringify(updatedData),
      headers: {
        'Content-Type': 'application/json',
        partnerId: partnerIdOwner,
      },
    });
    const productId = res.productId;
    if (productId) {
      const files = [];
      for (let index = 0; index < (data.images || []).length; index++) {
        const image = data.images?.[index];
        if (image?.file) {
          const uploadedFile = await uploadProductCoverImage(productId, image, index + 1, partnerIdOwner);
          files.push(uploadedFile);
        } else {
          files.push(image);
        }
      }
      for (const image of data.imagesToDelete || []) {
        deleteProductCoverImage(image._id, partnerId);
      }
      const coverImage = files.find(file => file?.isCoverImage);
      coverImage && await setProductCoverImageAsDefault(coverImage._id, partnerId);
    }


    data._id
      ? dispatch(updateCurrentProduct(data))
      : dispatch(addProduct({
        partnerId,
        product: {
          ...data,
          _id: productId,
        },
      }));
    callBack?.();
  } catch (error) {
    console.error(error);
  }
};

export const deleteProductThunk = (partnerId: string, productId: string): AppThunk => async (dispatch, getState) => {
  const partnerIdOwner = getState().Auth.user?.partnerId || '';

  await apiFetch(`partner/product/delete/${productId}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      partnerId: partnerIdOwner,
    },
  });
  try {
    dispatch(deleteProduct({
      partnerId,
      productId,
    }));

  } catch (error) {
    console.error(error);
  }
};

export const loadProducts = (params: LoadProductsParams): AppThunk => async (dispatch, getState) => {
  const partnerId = getState().Auth.user?.partnerId || '';

  try {
    const response: GetProductsResponse = await apiFetch('partner/product/list', {
      method: 'post',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
    dispatch(setCurrentPartner({
      products: response.products,
      productAmount: response.products?.length,
      netCount: response.netCount,
      ...response.partner,
    }));

  } catch (error) {
    console.error(error);
  }
};

export const loadProductDetails = (productId: string): AppThunk => async (dispatch, getState) => {
  const partnerId = getState().Auth.user?.partnerId || '';

  try {
    const response: ProductType = await apiFetch(`partner/product/details/${productId}`, {
      method: 'get',
      headers: { partnerId },
    });
    dispatch(setCurrentProduct(response));

  } catch (error) {
    console.error(error);
  }
};

export const uploadProductCoverImage = async (productId: string, file: CoverImagesType, index: number, partnerIdOwner: string) => {
  try {
    const response: {
      _id: string
    } = await apiFetch(`partner/product/cover-image/${productId}/${file.isCoverImage}/${index}`, {
      method: 'post',
      headers: {
        'Content-Type': file.file?.type || file.filetype || 'image/png',
        partnerId: partnerIdOwner,
      },
      body: file.file,
    });
    return {
      ...file,
      _id: response._id,
    };
  } catch (error) {
    console.error(error);
  }
};

export const setProductCoverImageAsDefault = async (imagerId: string, partnerIdOwner: string) => {
  try {
    await apiFetch(`partner/product/cover-image-default/${imagerId}/`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId: partnerIdOwner,
      },
    });
    return;
  } catch (error) {
    console.error(error);
  }
};

export const deleteProductCoverImage = async (imagerId: string, partnerIdOwner: string) => {
  try {
    await apiFetch(`partner/product/cover-image-delete/${imagerId}/`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        partnerId: partnerIdOwner,
      },
    });
    return;
  } catch (error) {
    console.error(error);
  }
};
