import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Card, CardHeader, Container, Grid, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { omit } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmDialog, CrumbType, FormProvider, MoreOptions, OptionType, ProjectToolbar, RHFAutocomplete, RHFRadioGroup, RHFTextField } from 'src/components';
import { paths } from 'src/config';
import { useBoolean, usePopover, useResponsive, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { createProduct, deleteProductThunk, getPartnerDetail } from 'src/store/partners';
import * as Yup from 'yup';

import { BankProductType } from '../../../../../../types';
import { payOutTermsVariants, repaymentTermsVariants, shortDebtTypeName } from '../../mock-data';

export interface BankProductFormProps {
  currentProduct?: BankProductType;
  partnerId: string;
}

export const BankProductForm = (props: BankProductFormProps) => {
  const {
    currentProduct,
    partnerId,
  } = props;
  const mdUp = useResponsive('up', 'md');
  const router = useRouter();
  const dispatch = useDispatch();
  const partner = useSelector((state: RootState) => state.Partners.currentPartner);
  const popover = usePopover();
  const confirm = useBoolean();

  useEffect(() => {
    dispatch(getPartnerDetail(partnerId));
  }, [dispatch, partnerId]);

  const moreOptions: OptionType[] = [
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: confirm.onTrue,
      color: 'error.main',
    },
  ];

  const NewBankProductSchema: Yup.ObjectSchema<Omit<BankProductType, '_id'>> = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    subType: Yup.string().oneOf(['Senior', 'Junior', 'Bridge']).required('SubType is required'),
    monthlyRateStartingFrom: Yup.number().required('Monthly Rate Starting From is required').moreThan(0, 'Monthly Rate Starting From must be greater than 0'),
    fees: Yup.number().required('Fees is required').moreThan(0, 'Fees must be greater than 0'),
    minLoan: Yup.number().required('Min loan is required').moreThan(0, 'Min loan must be greater than 0'),
    maxLoan: Yup.number()
      .required('Max loan is required')
      .test(
        'loan-max',
        'Max loan must be greater than min loan',
        (value, { parent }) => value > parent.minLoan,
      ),
    maxLtv: Yup.number().required('Max ltv is required').moreThan(0, 'Max ltv must be greater than 0'),
    maxLtgdv: Yup.number().required('Max ltgdv is required').moreThan(0, 'Max ltgdv must be greater than 0'),
    minLoanTermInMonths: Yup.number().required('Min loan term in months is required').moreThan(0, 'Min apartment size must be greater than 0'),
    maxLoanTermInMonths: Yup.number().required('Max loan term in months is required')
      .test(
        'loan-in-months-max',
        'Max loan term in months must be greater than min apartment size',
        (value, { parent }) => value > parent.minLoanTermInMonths,
      ),
    payoutTerms: Yup.string().required('Payout Terms is required'),
    repaymentTerms: Yup.string().required('Repayment Terms is required'),
    description: Yup.string().optional(),
    debtType: Yup.string().optional(),
    type: Yup.string().optional(),
  });

  const defaultValues: Omit<BankProductType, '_id'> = useMemo(() => ({
    name: currentProduct?.name || '',
    subType: currentProduct?.subType || 'Senior',
    monthlyRateStartingFrom: currentProduct?.monthlyRateStartingFrom || 0,
    fees: currentProduct?.fees || 0,
    minLoan: currentProduct?.minLoan || 0,
    maxLoan: currentProduct?.maxLoan || 0,
    maxLtv: currentProduct?.maxLtv || 0,
    maxLtgdv: currentProduct?.maxLtgdv || 0,
    minLoanTermInMonths: currentProduct?.minLoanTermInMonths || 0,
    maxLoanTermInMonths: currentProduct?.maxLoanTermInMonths || 0,
    payoutTerms: currentProduct?.payoutTerms || '',
    repaymentTerms: currentProduct?.repaymentTerms || '',
    description: currentProduct?.description || '',
  }), [currentProduct]);

  const methods = useForm<Omit<BankProductType, '_id'>>({
    resolver: yupResolver(NewBankProductSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentProduct) {
      reset(defaultValues);
    }
  }, [currentProduct, defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const updatedData: BankProductType = {
        ...data,
        debtType: data.subType,
        type: 'Bank',
        _id: currentProduct?._id || '',
      };

      dispatch(createProduct(partnerId, omit(updatedData, ['coverImage']), () => {
        router.push(paths.bankPartners.products(partnerId));
      }));

    } catch (error) {
      console.error(error);
    }
  });

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Details
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Title, type, build system...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title='Details' />}

          <Stack spacing={3} sx={{ p: 3 }} direction='row' flexWrap='wrap'>
            <RHFTextField name='name' label='Name *' />
            <Stack direction='row' width='100%'>
              <RHFRadioGroup
                row
                name='subType'
                spacing={2}
                options={shortDebtTypeName.map(variant => ({
                  label: variant,
                  value: variant,
                }))}
              />
            </Stack>
            <RHFTextField
              replaceZeroAfterStartTyping
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='monthlyRateStartingFrom'
              label='Monthly Rates Starting From *'
            />
            <RHFTextField
              replaceZeroAfterStartTyping
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='fees'
              label='Fees *'
            />
            <RHFTextField
              replaceZeroAfterStartTyping
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='minLoan'
              label='Min Loan *'
            />
            <RHFTextField
              replaceZeroAfterStartTyping
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='maxLoan'
              label='Max Loan *'
            />
            <RHFTextField
              replaceZeroAfterStartTyping
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='maxLtv'
              label='Max LTV, % *'
            />
            <RHFTextField
              replaceZeroAfterStartTyping
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='maxLtgdv'
              label='Max LTGDV, % *'
            />
            <RHFTextField
              replaceZeroAfterStartTyping
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='minLoanTermInMonths'
              label='Min Loan Term, M *'
            />
            <RHFTextField
              replaceZeroAfterStartTyping
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='maxLoanTermInMonths'
              label='Max Loan Term, M *'
            />
            <RHFAutocomplete
              sx={{ width: 'calc(50% - 12px)' }}
              name='payoutTerms'
              label='Pay Out Terms *'
              autoHighlight
              options={payOutTermsVariants.map((option) => option)}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option}>
                  {option}
                </li>
              )}
            />
            <RHFAutocomplete
              sx={{ width: 'calc(50% - 12px)' }}
              name='repaymentTerms'
              label='Repayment Terms *'
              autoHighlight
              options={repaymentTermsVariants.map((option) => option)}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option}>
                  {option}
                </li>
              )}
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderDescription = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Description
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Additional info
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title='Description' />}
          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField
              multiline
              rows={4}
              name='description'
              label='Description'
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const crumbs: CrumbType[] = [
    {
      title: 'Partners',
      href: paths.bankPartners.list,
    },
    {
      title: 'Banks',
      href: paths.bankPartners.list,
    },
    {
      title: partner?.companyName,
      href: paths.bankPartners.products(partner?._id || ''),
    },
    { title: currentProduct?._id ? currentProduct.name : 'New Product' },
  ];

  const renderActions = (
    <LoadingButton
      sx={{ ml: 'auto' }}
      type='submit'
      variant='contained'
      size='large'
      loading={isSubmitting}
    >
      {!currentProduct ? 'Create Product' : 'Save Changes'}
    </LoadingButton>
  );

  const onDeleteClickHandlerWrapper = () => {
    if (currentProduct?._id) {
      dispatch(deleteProductThunk(partnerId, currentProduct?._id));
    }
    confirm.onFalse();
    router.push(paths.bankPartners.products(partnerId));
  };

  return (
    <Container maxWidth={false} sx={{ px: 2 }}>
      <ProjectToolbar
        showSkeleton={false}
        showBadge={false}
        showBackIcon={false}
        crumbs={crumbs}
        title={currentProduct?._id ? 'Edit' : 'Create a new product'}
      >
        {currentProduct?._id &&
          <MoreOptions
            options={moreOptions}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
            popoverOpen={popover.open}
          />
        }
      </ProjectToolbar>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid
          container
          sx={{
            mx: 0,
            px: 3,
          }}
          rowGap={3}
        >
          {renderDetails}
          {renderDescription}
          {renderActions}
        </Grid>
      </FormProvider>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Are you sure want to delete?'
        action={
          <Button variant='contained' color='error' onClick={onDeleteClickHandlerWrapper}>
            Delete
          </Button>
        }
      />
    </Container>
  );
};
