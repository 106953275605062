import { Card, CardHeader, Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CardDonutChart, CardRadialBarChart, EcommerceWidgetSummary, ReportNote } from 'src/components';
import { DEFAULT_AREA, DEFAULT_CURRENCY } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';
import { RootState } from 'src/store';
import { fNumber, removeSubstrings } from 'src/utils';

import { NoteSettingType } from '../../../../../../types';
import { InfoBlock } from '../info-block';
import { useConstructionResult } from './use-construction-result';

export interface ConstructionProps {
  noteSetting?: NoteSettingType;
}

export const Construction = (props: ConstructionProps) => {
  const { noteSetting } = props;
  const scenario = useSelector((state: RootState) => state.Storage.detailsData.project?.scenario);
  const {
    rows,
    series,
  } = useConstructionResult(scenario?.calculate?.input || {}, scenario?.calculate?.output || {});
  const mdUp = useResponsive('up', 'md');
  const { t } = useTranslation(['report', 'common']);

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>{t('reportSections.construction')}</Typography>
      </Grid>
      {noteSetting?.isActive &&
        <Grid hidden={!mdUp} item md={12}>
          {scenario
            ? <ReportNote note={noteSetting.note} files={noteSetting.files} />
            : <Skeleton variant='rounded' height='150px' width='100%' />
          }
        </Grid>
      }
      <Grid item md={6} xs={12}>
        <CardDonutChart
          showWidget={!!scenario}
          title={t('projectProperties.totalBuildCostsUpper')}
          chartHeader={`${t('projectProperties.totalBuildCosts')}, ${DEFAULT_CURRENCY}`}
          chart={{ series: series[0] }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CardRadialBarChart
          showWidget={!!scenario}
          title={t('projectProperties.otherCosts')}
          chartHeader={t('projectProperties.totalCosts')}
          chart={{ series: series[1] }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title={`${t('projectProperties.buildCosts')} ${DEFAULT_CURRENCY}/${DEFAULT_AREA}`}
          total={scenario?.calculate?.output?.['Build costs £/sqft']}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title={t('projectProperties.buildTime')}
          total={scenario?.calculate?.output?.['Build time']}
          postfix={` ${t('projectProperties.months')}`}
        />
      </Grid>
      <Grid hidden={!mdUp} item md={12}>
        {scenario ?
          <Card>
            <CardHeader title={t('projectProperties.buildCostsBreakdown')} />
            <Table sx={{ marginTop: '24px' }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('tableRows.type', { ns: 'common' })}
                  </TableCell>
                  <TableCell align='right'>{t('tableRows.total', { ns: 'common' })}, {DEFAULT_CURRENCY}</TableCell>
                  <TableCell align='right'>{t('tableRows.percentageOfBuildCost', { ns: 'common' })}</TableCell>
                  <TableCell align='right'>{t('tableRows.percentageOfTotalCosts', { ns: 'common' })}</TableCell>
                  <TableCell align='right'>{t('tableRows.perUnit', { ns: 'common' })}, {DEFAULT_CURRENCY}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  const correspondingRowType = removeSubstrings(['Construction -', 'development costs'], row.type.toString());
                  return (
                    <TableRow
                      key={row.type}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component='th' scope='row'>{correspondingRowType}</TableCell>
                      <TableCell align='right'>{fNumber(row.total)}</TableCell>
                      <TableCell align='right'>{fNumber(row.build_costs)}%</TableCell>
                      <TableCell align='right'>{fNumber(row.total_costs)}%</TableCell>
                      <TableCell align='right'>{fNumber(row.per_unit)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card>
          : <Skeleton variant='rounded' height='310px' width='100%' />
        }
      </Grid>
      <Grid hidden={mdUp} item xs={12}>
        <InfoBlock />
      </Grid>
    </Grid>
  );
};
