import { Button, Card, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CrumbType, generateSummaryField, ProjectToolbar, Summary } from 'src/components';
import { UNSPECIFIED_VALUE } from 'src/constants/constants';
import { RootState } from 'src/store';

import { PartnerItemType } from '../../../../../types';

export interface PartnersProductListProps {
  crumbMiddleItem?: CrumbType;
  partner?: PartnerItemType;
  productTable?: ReactNode;
  onAddProductClickHandler: VoidFunction;
  onEditClickHandler: VoidFunction;
}

export const PartnersProductList = (props: PartnersProductListProps) => {
  const {
    crumbMiddleItem = {
      href: '',
      title: UNSPECIFIED_VALUE,
    },
    partner,
    productTable,
    onAddProductClickHandler,
    onEditClickHandler,
  } = props;
  const isLoading = useSelector((state: RootState) => state.Storage.isLoading);

  const crumbs: CrumbType[] = [
    {
      title: 'Partners',
      href: crumbMiddleItem?.href,
    },
    {
      title: crumbMiddleItem?.title,
      href: crumbMiddleItem?.href,
    },
    { title: partner?.companyName },
  ];

  const contactPersonSummary = useMemo(() => {
    return generateSummaryField({
      contactPerson: partner?.companyContactName,
      personPosition: partner?.designation,
      email: partner?.companyContactEmail,
      phone: partner?.companyContactPhone,
    });
  }, [partner?.companyContactEmail, partner?.companyContactName, partner?.companyContactPhone, partner?.designation]);

  const aboutSummary = useMemo(() => {
    return generateSummaryField({
      type: partner?.companySubType?.join(', '),
      added: partner?.added,
      website: partner?.website,
    });
  }, [partner?.added, partner?.companySubType, partner?.website]);

  return (
    <Stack>
      <ProjectToolbar
        showBackIcon
        showSkeleton={isLoading}
        showBadge={false}
        crumbs={crumbs}
        title={partner?.companyName}
      >
        <Button
          variant='outlined'
          onClick={onEditClickHandler}
        >
          Edit
        </Button>
      </ProjectToolbar>
      <Stack px={3} direction='row' justifyContent='space-between' gap={3} alignItems='flex-start'>
        <Card sx={{ flexGrow: 1 }}>
          <Stack p='24px 16px 24px 24px' direction='row' justifyContent='space-between'>
            <Typography fontWeight={700} fontSize='18px'>
              Products
            </Typography>
            <Button variant='contained' onClick={onAddProductClickHandler}>
              Add Product
            </Button>
          </Stack>
          {productTable}
        </Card>
        <Stack rowGap={3} minWidth={280}>
          <Summary
            cardSx={{ pb: 3 }}
            summaryListItemSX={{ maxWidth: 250 }}
            skeletonHeight={350}
            showSkeleton={isLoading}
            title='Contact Person'
            items={contactPersonSummary}
          />
          <Summary
            cardSx={{ pb: 3 }}
            summaryListItemSX={{ maxWidth: 250 }}
            skeletonHeight={290}
            showSkeleton={isLoading}
            title='About'
            items={aboutSummary}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
