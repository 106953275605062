import { alpha, ListItemButton, ListItemIcon } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { useChildrenActive } from 'src/hooks';

import { NavListProps } from '../../types';

type NavListRootProps = {
  data: NavListProps;
  openedSection: NavListProps | null;
  isSectionDisabled?: boolean;
  isSubItem?: boolean;
  onSectionClickHandler: () => void;
};

export const NavListMiniItem = (props: NavListRootProps) => {
  const {
    data,
    isSectionDisabled,
    isSubItem,
    openedSection,
    onSectionClickHandler,
  } = props;

  const active = useChildrenActive(data.children?.map(children => ({ path: children.path })));
  const theme = useTheme();
  const {
    title,
    icon,
  } = data;

  const activeStyles = {
    color: theme.palette.extended.active,
    backgroundColor: alpha(theme.palette.primary.main, 0.16),
    '&:hover': { backgroundColor: alpha(theme.palette.primary.main, 0.16) },
  };
  const applyStyles = openedSection?.title
    ? openedSection?.title === title
    : active;

  return (
    <ListItemButton
      disabled={isSectionDisabled}
      onClick={onSectionClickHandler}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.common.white,
        alignItems: 'center',
        borderRadius: 1,
        minHeight: 54,
        ...(applyStyles && activeStyles),
        ...(isSubItem && {
          flexGrow: 0,
          minHeight: 44,
          color: theme.palette.text.primary,
        }),
      }}
    >
      {icon && (
        <ListItemIcon sx={{ mr: 0 }}>
          {icon}
        </ListItemIcon>
      )}

      <ListItemText
        primary={title}
        sx={{ ...(isSubItem && { alignSelf: 'flex-start' }) }}
        primaryTypographyProps={{
          noWrap: true,
          fontSize: 10,
          lineHeight: '16px',
          fontWeight: active ? 'fontWeightBold' : 'fontWeightSemiBold',
          ...(isSubItem && {
            color: theme.palette.common.white,
            fontSize: 14,
            lineHeight: '22px',
          }),
        }}
      />
    </ListItemButton>
  );
};
