import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CrossIcon } from 'src/assets';
import { CustomDialog, FormProvider, HelperTextWithIcon, RHFSelect, RHFTextField, SubmitButton, UploadedFilesType, UploadFile } from 'src/components';
import { productRequestSupportList } from 'src/constants/constants';
import { UseBooleanReturnType } from 'src/hooks';
import { sendSupportRequest } from 'src/store/integration/action';
import { isEmailValid } from 'src/utils';
import * as Yup from 'yup';

export interface ProductSupportProps {
  productSupportBool: UseBooleanReturnType;
}

export interface SendRequestFormType {
  email: string;
  typeOfRequest: string;
  description: string;
  files?: UploadedFilesType[];
}

export const ProductSupport = (props: ProductSupportProps) => {
  const { productSupportBool } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: SendRequestFormType = useMemo(() => ({
    description: '',
    email: '',
    typeOfRequest: '',
    files: [],
  }), []);

  const SendRequestSchema: Yup.ObjectSchema<SendRequestFormType> = Yup.object().shape({
    email: Yup.string().required('Email is required').test(
      'email',
      'Email must be a valid email address',
      (value) => value ? isEmailValid(value) : true,
    ),
    typeOfRequest: Yup.string().required('Type of request is required'),
    description: Yup.string().required('Description is required').max(500, 'Description must be less than 500 characters'),
    files: Yup.array(),
  });

  const methods = useForm<SendRequestFormType>({
    resolver: yupResolver(SendRequestSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;
  const { files } = watch();

  const onClose = () => {
    reset(defaultValues);
    productSupportBool.onFalse();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    const res = await sendSupportRequest(data);
    if (res._id) {
      onClose();
      enqueueSnackbar('Your request was successfully sent!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  });

  const uploadFileHandler = (files: UploadedFilesType[]) => {
    setValue('files', files);
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <CustomDialog
        showDialogTitle
        dialogProps={{
          open: productSupportBool.value,
          onClose: onClose,
          PaperProps: {
            sx: {
              width: 480,
              m: 'auto',
            },
          },
        }}
        dialogHeadChildren={
          <Stack flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Typography variant='h6'>Send Request</Typography>
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </Stack>
        }
        dialogContentChildren={
          <>
            <RHFTextField
              name='email'
              label='Email Address'
            />
            <RHFSelect
              name='typeOfRequest'
              label='Type of request'
            >
              {productRequestSupportList.map(item => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </RHFSelect>
            <RHFTextField
              multiline
              name='description'
              label='Description of request'
              rows={4}
              helperText={(<HelperTextWithIcon icon='eva:info-fill' text='Max 500 symbols' />)}
            />
            <UploadFile
              multiple
              containerSx={{ width: '100%' }}
              uploadFileHandler={uploadFileHandler}
              uploadedFiles={files}
            />
          </>
        }
        dialogActionsChildren={
          <Stack direction='row' gap={1.5}>
            <SubmitButton
              loading={isSubmitting}
              onClick={onSubmit}
              title='Send Request'
            />
            <Button
              variant='outlined'
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        }
      />
    </FormProvider>
  );
};
