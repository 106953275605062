import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateUserType } from 'src/features/users-page/types';

import { Config, FileSectionsType, FinanceUploadFileType, ShortUser, UpdateStatus, User } from '../../../../types';

export * from './actions';

interface State {
  config?: Config;
  configStatus: UpdateStatus;
  configError?: string;
  userStatus: UpdateStatus;
  userListStatus: UpdateStatus;
  user?: User;
  resetPasswordError?: string;
  userList: User[];
  fileSections: FileSectionsType[];
  shortUserList: ShortUser[];
}

export const initialState: State = {
  configStatus: 'new',
  userStatus: 'new',
  userListStatus: 'new',
  userList: [],
  fileSections: [],
  shortUserList: [],
};

const Slice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    setConfig(state, action: PayloadAction<Config>) {
      state.config = action.payload;
    },
    setConfigStatus(state, action: PayloadAction<UpdateStatus>) {
      state.configStatus = action.payload;
      state.configError = action.payload === 'error' ? state.configError : undefined;
    },
    setConfigError(state, action: PayloadAction<string>) {
      state.configError = action.payload;
    },
    setUserStatus(state, action: PayloadAction<UpdateStatus>) {
      state.userStatus = action.payload;
    },
    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload || undefined;
    },
    setResetPasswordError(state, action: PayloadAction<string>) {
      state.resetPasswordError = action.payload || '';
    },
    updateUser(state, action: PayloadAction<UpdateUserType>) {
      const {
        email,
        name,
        role,
        phoneNumber,
        id,
      } = action.payload;
      state.user = {
        role: 'user',
        id,
        ...state.user,
        roleNP: role,
        email,
        phoneNumber,
        name,
      };
      const currentUserInList = state.userList.find(user => user.id === id);
      if (currentUserInList) {
        state.userList = state.userList.map(user => {
          return user.id === id ? {
            ...user,
            roleNP: role,
            email,
            phoneNumber,
            name,
          } : user;
        });
      }
    },
    setUserListStatus(state, action: PayloadAction<UpdateStatus>) {
      state.userListStatus = action.payload;
    },
    setUserList(state, action: PayloadAction<User[]>) {
      state.userList = action.payload;
    },
    deleteUser(state, action: PayloadAction<string>) {
      state.userList = state.userList.filter(user => user.id !== action.payload);
    },
    addUser(state, action: PayloadAction<User>) {
      state.userList = [...state.userList, action.payload];
    },
    setFileSections(state, action: PayloadAction<FileSectionsType[]>) {
      state.fileSections = action.payload;
    },
    addFileSection(state, action: PayloadAction<FileSectionsType>) {
      state.fileSections = state.fileSections.map(section => {
        if (section.section === action.payload.section) {
          return {
            ...section,
            filenames: [...section.filenames, ...action.payload.filenames],
          };
        }
        return section;
      });
    },
    updateSelectedFileInSection(state, action: PayloadAction<Partial<FinanceUploadFileType>>) {
      state.fileSections = state.fileSections.map(section => {
        if (section.section === action.payload.section) {
          return {
            ...section,
            filenames: section.filenames.map(fileNameObj => {
              if (fileNameObj._id === action.payload._id) {
                return {
                  ...fileNameObj,
                  _id: action.payload._id,
                  selected: !fileNameObj.selected,
                };
              }
              return fileNameObj;
            }),
          };
        }
        return section;
      });
    },
    resetSelectedSections(state, action: PayloadAction<string>) {
      state.fileSections = state.fileSections.map(section => {
        if (section.section === action.payload) {
          return {
            ...section,
            filenames: section.filenames.map(fileNameObj => {
              return {
                ...fileNameObj,
                selected: false,
              };
            }),
          };
        }
        return section;
      });
    },
    setShortUserList(state, action: PayloadAction<ShortUser[]>) {
      state.shortUserList = action.payload;
    },
  },
});

export const {
  reducer: AppReducer,
  actions: {
    setConfig,
    setResetPasswordError,
    setConfigStatus,
    setConfigError,
    setUserStatus,
    setUser,
    setUserListStatus,
    setUserList,
    setFileSections,
    addFileSection,
    updateSelectedFileInSection,
    resetSelectedSections,
    setShortUserList,
    deleteUser,
    addUser,
    updateUser,
  },
} = Slice;
