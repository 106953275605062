import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { memo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Logo, NavListMiniItem, NavListProps, NavSectionMini, useMenuData } from 'src/components';
import { NAV } from 'src/constants/constants';
import { useClickOutside, useRouter } from 'src/hooks';
import { toggleDeepSideMenu } from 'src/store/storage';

export interface NavMiniProps {
  closeNavHandler?: VoidFunction;
}

export const NavMini = memo((props: NavMiniProps) => {
  const { closeNavHandler } = props;
  const menuData = useMenuData();
  const theme = useTheme();
  const [openedSection, setOpenedSection] = useState<NavListProps | null>(null);
  const dispatch = useDispatch();
  const router = useRouter();
  const navRef = useRef<HTMLDivElement>(null);

  useClickOutside(navRef, () => {
    setOpenedSection(null);
    dispatch(toggleDeepSideMenu(false));
  });

  const onSubSectionClickHandler = (item: NavListProps) => () => {
    if (item.path) {
      router.push(item.path);
    }
    setOpenedSection(null);
    dispatch(toggleDeepSideMenu(false));
    closeNavHandler?.();
  };

  return (
    <Stack
      ref={navRef}
      sx={{ minWidth: openedSection?.title ? NAV.W_MINI_WITH_SECTION : NAV.W_MINI }}
    >
      <Stack
        direction='row'
        sx={{
          height: '100%',
          position: 'fixed',
        }}
      >
        <Stack
          sx={{
            background: theme.palette.extended.background,
            width: NAV.W_MINI,
            p: '24px 6px',
          }}
        >
          <Logo
            sx={{
              mx: 'auto',
              mb: 3,
            }}
          />

          <NavSectionMini
            openedSection={openedSection}
            setOpenedSection={setOpenedSection}
            data={menuData}
          />
        </Stack>
        {openedSection?.title && (
          <Stack
            sx={{
              background: theme.palette.extended.secondaryBackground,
              minWidth: NAV.W_MINI_SECTION,
              width: NAV.W_MINI_SECTION,
              p: '24px 16px',
              gap: 0.5,
            }}
          >
            {openedSection.children?.map((item, index) => (
              <NavListMiniItem
                isSubItem
                key={index}
                data={item}
                onSectionClickHandler={onSubSectionClickHandler(item)}
                openedSection={openedSection}
                isSectionDisabled={item.disabled}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
});
