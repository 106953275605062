import { useEffect, useState } from 'react';
import { TableCustom, UploadedFilesType } from 'src/components';
import { TableHeadType } from 'src/features/leads-page';
import { useTable } from 'src/hooks';

import { DocumentsTableRow } from './documents-table-row';
import { TABLE_HEAD } from './mock-data';

type DocumentsProps = {
  tableHead?: TableHeadType[];
  files?: UploadedFilesType[]
  showUploadedBy?: boolean;
  hideUploadCell?: boolean;
}

export const Documents = (props: DocumentsProps) => {
  const {
    tableHead,
    showUploadedBy,
    hideUploadCell,
    files = [],
  } = props;

  const [tableData, setTableData] = useState<UploadedFilesType[]>([]);
  const table = useTable({ tableKey: 'documents-page' });
  const noDataFound = tableData?.length === 0;

  useEffect(() => {
    if (files.length === 0 && tableData.length === 0) {
      return;
    }
    setTableData(files);
  }, [files, tableData.length]);

  return (
    <TableCustom
      headLabels={tableHead || TABLE_HEAD}
      table={table}
      rowCount={tableData?.length}
      noDataFound={noDataFound}
      tableMinWidth={0}
      bodyCells={(
        <>
          {tableData?.map((row) => (
            <DocumentsTableRow
              key={row._id}
              row={row}
              hideUploadCell={hideUploadCell}
              showUploadedBy={showUploadedBy}
            />
          ))}
        </>
      )}
      scrollbar={false}
      separateRows
    />
  );
};
