import { Button, Stack } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { TableCustom } from 'src/components/table';
import { TableHeadType } from 'src/features/leads-page';
import { useTable } from 'src/hooks';

import { RaiseMonitoringType } from '../../../../../types';
import { RaiseMonitoringTableHead } from './mock-data';
import { RaiseMonitoringTableRow } from './raise-monitoring-table-row';

export interface RaiseMonitoringTableProps {
  tableTitle: string;
  btnTitle?: string;
  btnConfig?: ButtonProps;
  lastColumnName: TableHeadType;
  tableData?: RaiseMonitoringType[];
  addOfferClickHandler?: (item: RaiseMonitoringType) => () => void;
  deleteClickHandler: (item: RaiseMonitoringType) => () => void;
}

export const RaiseMonitoringTable = (props: RaiseMonitoringTableProps) => {
  const {
    tableTitle,
    lastColumnName,
    tableData = [],
    deleteClickHandler,
    addOfferClickHandler,
    btnTitle,
    btnConfig,
  } = props;

  const table = useTable({ tableKey: 'raise-monitoring' });
  const noDataFound = tableData?.length === 0;

  const newHeadLabels = [...RaiseMonitoringTableHead];
  newHeadLabels.splice(2, 1, lastColumnName);

  return (
    <Card>
      <CardHeader sx={{ pb: 3 }} title={
        <Stack
          direction='row'
          justifyContent='space-between'
        >
          {tableTitle}
          {btnTitle &&
            <Button
              variant='outlined'
              {...btnConfig}
            >
              {btnTitle}
            </Button>
          }
        </Stack>
      } />
      <TableCustom
        headLabels={newHeadLabels}
        table={table}
        noDataFound={noDataFound}
        bodyCells={(
          <>
            {tableData?.map((row) => (
              <RaiseMonitoringTableRow
                key={row._id}
                row={row}
                lastColumnName={lastColumnName.label || ''}
                deleteClickHandler={deleteClickHandler(row)}
                addOfferClickHandler={addOfferClickHandler?.(row)}
              />
            ))}
          </>
        )}
      />
    </Card>
  );
};
