import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { paths } from 'src/config';
import { RootState } from 'src/store';

import { User } from '../../../../types';
import { SvgColor } from '../svg-color';
import { NavListProps } from './types';

const icon = (name: string) => {
  return <SvgColor
    src={`/assets/menu-icons/${name}-icon.svg`}
    sx={{
      width: 22,
      height: 22,
    }}
  />;
};

const ICONS = {
  leadHub: icon('ic-solar_eye-bold'),
  projectHub: icon('packaging'),
  suppliersHub: icon('ic-solar_share-bold'),
  bankHub: icon('partners'),
  investorHub: icon('marketplaces'),

  dashboard: icon('dashboard'),
  canban: icon('canban'),
  opportunities: icon('search'),
  packaging: icon('packaging'),
  distribution: icon('distribution'),
  marketplaces: icon('cart'),
  performance_control: icon('performance'),
  users: icon('users'),
  partners: icon('partners'),
  templates: icon('templates'),
  settings: icon('settings'),
  integrations: icon('integrations'),
  sell: icon('arrow'),
  projects: icon('ecommerce'),
  investment: icon('letter-doc'),
  commissions: icon('dollar-doc'),
  user: icon('user'),
};

export const useMenuData = (): NavListProps[] => {
  const user = useSelector((state: RootState) => state.Auth.user) || {} as User;

  const isUserInvestorRole = user?.partner?.role === 'investor';
  const isPartnerInvestorRole = isUserInvestorRole || user?.partner?.companyType === 'Investor';

  const equityPartnerHub: NavListProps = useMemo(() => {
    return {
      title: 'Investor Hub',
      icon: ICONS.investorHub,
      children: [
        {
          title: 'Overview',
          path: paths.partnerPortal.overview,
        },
        {
          title: 'Projects',
          path: paths.partnerPortal.projects,
        },
        {
          title: 'Investment Activity',
          path: paths.partnerPortal.investmentActivity,
        },
        {
          title: 'Marketplace preview',
          path: paths.investorPortal.projects.list,
        },
      ],
    };
  }, []);

  const investorHub: NavListProps = useMemo(() => {
    return {
      title: 'Investor Hub',
      icon: ICONS.investorHub,
      children: [
        {
          title: 'Portfolio',
          path: paths.investorPortal.portfolio.list,
        },
        {
          title: 'Projects',
          path: paths.investorPortal.projects.list,
        },
        {
          title: 'Investment Activity',
          path: paths.investorPortal.activity.list,
        },
      ],
    };
  }, []);

  const defaultList: NavListProps[] = useMemo(
    () => [
      {
        title: 'Lead Hub',
        icon: ICONS.leadHub,
        disabled: isUserInvestorRole,
        children: [
          {
            title: 'New Lead',
            path: paths.leads.newLead,
          },
          {
            title: 'Leads',
            path: paths.leads.list,
          },
          {
            title: 'Opportunities',
            path: 'lead/opportunities',
            disabled: true,
          },
        ],
      },
      {
        title: 'Project Hub',
        icon: ICONS.projectHub,
        disabled: isUserInvestorRole,
        children: [
          {
            title: 'Dashboard',
            path: paths.dashboard,
          },
          {
            title: 'Research',
            path: paths.research.list,
          },
          {
            title: 'Project Preparation',
            path: paths.projectPreparation.list,
          },
          {
            title: 'Tendering',
            path: paths.tendering.list,
          },
          {
            title: 'Debt Financing',
            path: paths.financing.list,
          },
          {
            title: 'Equity Financing',
            path: paths.readyForInvestors.list,
          },
          {
            title: 'Sell Project',
            path: paths.sell.list,
          },
        ],
      },
      {
        title: 'Suppliers Hub',
        icon: ICONS.suppliersHub,
        disabled: isUserInvestorRole,
        children: [
          {
            title: 'Dashboard',
            path: paths.supplierDashboard,
          },
          {
            title: 'Opportunities',
            path: 'supplier/opportunities',
            disabled: true,
          },
        ],
      },
      {
        title: 'Bank Hub',
        icon: ICONS.bankHub,
        disabled: isUserInvestorRole,
        children: [
          {
            title: 'Dashboard',
            path: paths.bankDashboard,
          },
          {
            title: 'Opportunities',
            path: 'bank/opportunities',
            disabled: true,
          },
        ],
      },
      (isPartnerInvestorRole ? investorHub : equityPartnerHub),
    ], [equityPartnerHub, investorHub, isPartnerInvestorRole, isUserInvestorRole]);
  return defaultList;
};
