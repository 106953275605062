import { Box, Drawer } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Main, NavMini } from 'src/components';
import { NAV } from 'src/constants/constants';
import { useBoolean, useResponsive } from 'src/hooks';
import { RootState } from 'src/store';
import { toggleDeepSideMenu } from 'src/store/storage';

interface PageProps {
  children: ReactNode;
  mainSx?: SxProps<Theme>;
}

export const Page = (props: PageProps) => {
  const {
    children,
    mainSx,
  } = props;
  const lgUp = useResponsive('up', 'lg');
  const nav = useBoolean();
  const isDeepSideMenuOpen = useSelector((state: RootState) => state.Storage.isDeepSideMenuOpen);
  const dispatch = useDispatch();

  const openNavClickHandler = () => {
    dispatch(toggleDeepSideMenu(false));
    nav.onTrue();
  };

  return (
    <>
      <Header onOpenNav={openNavClickHandler} />
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {lgUp &&
          <NavMini />
        }
        {!lgUp && (
          <Drawer
            open={nav.value}
            onClose={nav.onFalse}
            PaperProps={{ sx: { width: isDeepSideMenuOpen ? NAV.W_MINI_WITH_SECTION : NAV.W_MINI } }}
          >
            <NavMini closeNavHandler={nav.onFalse} />
          </Drawer>
        )}

        <Main sx={mainSx}>{children}</Main>
      </Box>
    </>
  );
};
