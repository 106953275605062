import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { IcAnalyticsIcon, IcFileIcon } from 'src/assets';
import { Iconify } from 'src/components/iconify';

export type OverviewButtonsProps = {
  openDetailedProjectClickHandler?: VoidFunction;
  openDocumentsClickHandler?: VoidFunction;
}

export const OverviewButtons = (props: OverviewButtonsProps) => {
  const {
    openDetailedProjectClickHandler,
    openDocumentsClickHandler,
  } = props;
  const { t } = useTranslation();

  return (
    <Stack gap={3}>
      <Card>
        <CardHeader
          sx={{
            px: 3,
            py: 2.5,
          }}
          title={
            <Stack direction='row' gap={2}>
              <IcAnalyticsIcon width={32} height={32} />
              <Typography variant='h6'>
                {t('report:detailedProjectReport')}
              </Typography>
            </Stack>
          }
          action={
            <Button
              variant='outlined'
              size='medium'
              onClick={openDetailedProjectClickHandler}
              endIcon={
                <Iconify
                  width={16}
                  height={16}
                  icon='eva:arrow-ios-forward-fill' />
              }
            >
              {t('open')}
            </Button>
          }
        />
      </Card>
      <Card>
        <CardHeader
          sx={{
            px: 3,
            py: 2.5,
          }}
          title={
            <Stack direction='row' gap={2}>
              <IcFileIcon width={32} height={32} />
              <Typography variant='h6'>
                {t('sections.documents')}
              </Typography>
            </Stack>
          }
          action={
            <Button
              variant='outlined'
              size='medium'
              onClick={openDocumentsClickHandler}
              endIcon={
                <Iconify
                  width={16}
                  height={16}
                  icon='eva:arrow-ios-forward-fill' />
              }
            >
              {t('open')}
            </Button>
          }
        />
      </Card>
    </Stack>
  );
};
