import { LoadingButton } from '@mui/lab';
import { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { SxProps, Theme } from '@mui/material/styles';

export interface SubmitButtonProps extends LoadingButtonProps {
  title: string;
  btnSx?: SxProps<Theme>;
}

export const SubmitButton = (props: SubmitButtonProps) => {
  const {
    title,
    btnSx,
    loading,
    ...rest
  } = props;

  return (
    <LoadingButton
      sx={{ ml: 'auto', ...btnSx }}
      type='submit'
      variant='contained'
      size='large'
      loading={loading}
      {...rest}
    >
      {title}
    </LoadingButton>
  );
};
