import _ from 'lodash';

import {
  ChipColorsType,
  ChipVariantsType,
  CompanyType,
  DebtInvestmentType,
  DeptNameType,
  DocumentListBadgeType,
  InvestorStatusListType,
  MarketplaceProjectActivityStatusType,
  MarketplaceStatusType,
  PartnerDashboardStatusList,
  PartnerDashboardTableStatusList,
  PartnerTransactionStatusType,
  PartnerVisibilityType,
  PhaseType,
  ProjectPreparationStatusType,
  ProjectsOfferingStatusType,
  ProjectType,
  SellStatusListType,
  SourceType,
  StageType,
  StatusType,
  VisibilityListType,
} from '../../../types';
import { CheckboxOptionType } from '../components';

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80 - 16,
};

export const MAX_FILE_SIZE_MB = 100;

export const NAV = {
  W_MINI: 88,
  W_MINI_WITH_SECTION: 368,
  W_MINI_SECTION: 280,
};

export const INVESTOR_PORTAL = {
  MAX_WIDTH: 1152,
  HEADER_H: 64,
};

export const DEFAULT_CURRENCY = 'kr';
export const DEFAULT_AREA = 'm²';
export const UNSPECIFIED_VALUE = 'Unspecified';
export const NOT_USED = 'Not used';
export const EMPTY_VALUE = '-';
export const navianSalesEmail = 'sales@navian.io';

export const closeIcon = 'solar:close-circle-bold';
export const checkboxIcon = 'eva:checkmark-circle-2-fill';

export type ChipKeyVariants =
  SourceType
  | StatusType
  | StageType
  | ProjectType
  | MarketplaceStatusType
  | ProjectsOfferingStatusType
  | DebtInvestmentType
  | PhaseType
  | DocumentListBadgeType
  | SellStatusListType
  | DeptNameType
  | ProjectPreparationStatusType
  | VisibilityListType
  | PartnerTransactionStatusType
  | MarketplaceProjectActivityStatusType
  | InvestorStatusListType
  | PartnerDashboardStatusList
  | PartnerDashboardTableStatusList

export const allChipColors: Record<ChipKeyVariants, ChipColorsType> = {
  All: 'success',
  'New build apartments': 'default',
  'New build houses': 'default',
  'Investment': 'default',
  'Conversion': 'default',
  'Permitted development': 'default',
  'New build houses and apartments': 'default',
  Senior: 'primary',
  Junior: 'secondary',
  Bridge: 'success',
  Other: 'warning',
  Manual: 'error',
  Scraping: 'success',
  'Owner Request': 'info',
  Lead: 'secondary',
  Approved: 'success',
  New: 'secondary',
  'TS In Progress': 'info',
  'In Progress': 'info',
  'TS Signed': 'success',
  'DFA In Progress': 'primary',
  'DFA Signed': 'success',
  Done: 'success',
  Unlisted: 'error',
  'Ongoing Offerings': 'info',
  'Capital Raised': 'success',
  Recalculate: 'warning',
  Researched: 'success',
  Pending: 'secondary',
  'Waiting for Review': 'secondary',
  Rejected: 'error',
  Requested: 'info',
  Signed: 'success',
  Uploaded: 'success',
  'Term Sheet Requested': 'info',
  'Documents Added': 'success',
  'Documents Requested': 'secondary',
  'Agreement Requested': 'info',
  'Not used': 'default',
  Used: 'success',
  'Sent': 'secondary',
  'Not published': 'default',
  'Not Published': 'default',
  Published: 'success',
  'Not Selling': 'default',
  'On Sale': 'primary',
  Sold: 'success',
  Archive: 'secondary',
  Reserved: 'secondary',
  'Commitment Signed': 'success',
  'Invoice Sent': 'info',
  'Secured Capital': 'primary',
  'Soft commitment ready for sign': 'success',
  'Invoice Received': 'info',
  'No Shared': 'default',
  Raised: 'success',
  'TS signed': 'success',
  'New request': 'secondary',
  'TS requested': 'info',
  'Offer sent': 'info',
  'Offer declined': 'error',
  // TODO change colors
  Shared: 'success',
  'On marketplace': 'success',
  Finalised: 'success',
  'No Partners': 'success',
  Debt: 'success',
  Equity: 'success',
  Preliminary: 'success',
  Packaged: 'success',
  'Term Sheet': 'success',
  'Sign Loan': 'success',
};

export const allChipVariants: Record<ChipKeyVariants, ChipVariantsType> = {
  Manual: 'soft',
  Scraping: 'soft',
  New: 'outlined',
  Recalculate: 'outlined',
  'Owner Request': 'soft',
  All: 'outlined',
  Researched: 'outlined',
  Pending: 'outlined',
  Approved: 'outlined',
  'In Progress': 'outlined',
  'Waiting for Review': 'outlined',
  'No Partners': 'outlined',
  'Published': 'outlined',
  Rejected: 'outlined',
  'New build apartments': 'soft',
  'New build houses': 'soft',
  'Investment': 'soft',
  'Conversion': 'soft',
  'Permitted development': 'soft',
  'New build houses and apartments': 'soft',
  Lead: 'outlined',
  Requested: 'outlined',
  Signed: 'outlined',
  Uploaded: 'outlined',
  'Not Published': 'soft',
  Shared: 'outlined',
  'On marketplace': 'outlined',
  Finalised: 'outlined',
  'Ongoing Offerings': 'outlined',
  'Capital Raised': 'outlined',
  'Done': 'outlined',
  Unlisted: 'outlined',
  Debt: 'soft',
  Equity: 'soft',
  Preliminary: 'soft',
  Packaged: 'soft',
  'Sign Loan': 'soft',
  'Term Sheet': 'soft',
  'Term Sheet Requested': 'outlined',
  'TS In Progress': 'outlined',
  'TS Signed': 'outlined',
  'DFA In Progress': 'outlined',
  'DFA Signed': 'outlined',
  'Documents Added': 'outlined',
  'Documents Requested': 'outlined',
  'Agreement Requested': 'outlined',
  'Not Selling': 'outlined',
  'On Sale': 'outlined',
  Sold: 'outlined',
  'Not used': 'outlined',
  'Used': 'outlined',
  'No Shared': 'outlined',
  Raised: 'outlined',
  'TS signed': 'outlined',
  'New request': 'outlined',
  'TS requested': 'outlined',
  'Offer sent': 'outlined',
  'Offer declined': 'outlined',
  //   TODO change variant
  Senior: 'outlined',
  Junior: 'outlined',
  Bridge: 'outlined',
  Other: 'outlined',
  'Not published': 'outlined',
  Sent: 'outlined',
  Archive: 'outlined',
  Reserved: 'outlined',
  'Commitment Signed': 'outlined',
  'Invoice Sent': 'outlined',
  'Secured Capital': 'outlined',
  'Soft commitment ready for sign': 'outlined',
  'Invoice Received': 'outlined',
};

export const monthsShortLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const stringChipColors: ChipColorsType[] = ['primary', 'secondary', 'error', 'info', 'success', 'warning'];
export const sectionsList = ['Lead', 'Research', 'Financing', 'Asset', 'Tendering', 'Distribution', 'Sell'];
export const sellList = ['Not Selling', 'Sold', 'On Sale'];

export const acceptedFilesType = '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.jpeg,.png';

export const distributionCheckboxesList: CheckboxOptionType[] = [
  {
    label: 'Research',
    value: 'research',
  },
  {
    label: 'Project Preparation',
    value: 'projectPreparation',
  },
  {
    label: 'Tendering',
    value: 'tendering',
  },
  {
    label: 'Debt finance',
    value: 'debtFinancing',
  },
  {
    label: 'Equity finance',
    value: 'equityFinancing',
  },
  {
    label: 'Sell',
    value: 'sell',
  },
];

export const slicedDistributionCheckboxesList: CheckboxOptionType[] = _.slice(distributionCheckboxesList, 2, 6);

export const distributionCheckboxesListForLead = (reducedMenu: boolean): CheckboxOptionType[] => distributionCheckboxesList.map(item => {
  return {
    ...item,
    disabled: reducedMenu && ['tendering', 'equityFinancing'].includes(item.value),
  };
});

export const distributionCheckboxesListForAsset = (reducedMenu: boolean): CheckboxOptionType[] => distributionCheckboxesList.map(item => {
  return {
    ...item,
    disabled: ['research', 'projectPreparation'].includes(item.value) || (reducedMenu && ['tendering', 'equityFinancing'].includes(item.value)),
  };
});


export const companyTypeList: CompanyType[] = [
  'Developer',
  'Bank',
  'Equity Partner',
  'Supplier',
  'Investor',
  'Other',
];

export const partnerVisibilityTypeList: PartnerVisibilityType[] = [
  'public',
  'closed',
  'selected',
];

export const allCountriesList = [
  {
    code: 'AF',
    label: 'Afghanistan',
  },
  {
    code: 'AL',
    label: 'Albania',
  },
  {
    code: 'DZ',
    label: 'Algeria',
  },
  {
    code: 'AS',
    label: 'American Samoa',
  },
  {
    code: 'AD',
    label: 'Andorra',
  },
  {
    code: 'AO',
    label: 'Angola',
  },
  {
    code: 'AI',
    label: 'Anguilla',
  },
  {
    code: 'AQ',
    label: 'Antarctica',
  },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
  },
  {
    code: 'AR',
    label: 'Argentina',
  },
  {
    code: 'AM',
    label: 'Armenia',
  },
  {
    code: 'AW',
    label: 'Aruba',
  },
  {
    code: 'AU',
    label: 'Australia',
  },
  {
    code: 'AT',
    label: 'Austria',
  },
  {
    code: 'AZ',
    label: 'Azerbaijan',
  },
  {
    code: 'BS',
    label: 'Bahamas',
  },
  {
    code: 'BH',
    label: 'Bahrain',
  },
  {
    code: 'BD',
    label: 'Bangladesh',
  },
  {
    code: 'BB',
    label: 'Barbados',
  },
  {
    code: 'BY',
    label: 'Belarus',
  },
  {
    code: 'BE',
    label: 'Belgium',
  },
  {
    code: 'BZ',
    label: 'Belize',
  },
  {
    code: 'BJ',
    label: 'Benin',
  },
  {
    code: 'BM',
    label: 'Bermuda',
  },
  {
    code: 'BT',
    label: 'Bhutan',
  },
  {
    code: 'BO',
    label: 'Bolivia',
  },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
  },
  {
    code: 'BW',
    label: 'Botswana',
  },
  {
    code: 'BR',
    label: 'Brazil',
  },
  {
    code: 'BN',
    label: 'Brunei',
  },
  {
    code: 'BG',
    label: 'Bulgaria',
  },
  {
    code: 'BF',
    label: 'Burkina Faso',
  },
  {
    code: 'BI',
    label: 'Burundi',
  },
  {
    code: 'KH',
    label: 'Cambodia',
  },
  {
    code: 'CM',
    label: 'Cameroon',
  },
  {
    code: 'CA',
    label: 'Canada',
  },
  {
    code: 'CV',
    label: 'Cape Verde',
  },
  {
    code: 'KY',
    label: 'Cayman Islands',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
  },
  {
    code: 'TD',
    label: 'Chad',
  },
  {
    code: 'CL',
    label: 'Chile',
  },
  {
    code: 'CN',
    label: 'China',
  },
  {
    code: 'CO',
    label: 'Colombia',
  },
  {
    code: 'KM',
    label: 'Comoros',
  },
  {
    code: 'CG',
    label: 'Congo (Congo-Brazzaville)',
  },
  {
    code: 'CD',
    label: 'Congo (Congo-Kinshasa)',
  },
  {
    code: 'CR',
    label: 'Costa Rica',
  },
  {
    code: 'HR',
    label: 'Croatia',
  },
  {
    code: 'CU',
    label: 'Cuba',
  },
  {
    code: 'CY',
    label: 'Cyprus',
  },
  {
    code: 'CZ',
    label: 'Czech Republic (Czechia)',
  },
  {
    code: 'DK',
    label: 'Denmark',
  },
  {
    code: 'DJ',
    label: 'Djibouti',
  },
  {
    code: 'DM',
    label: 'Dominica',
  },
  {
    code: 'DO',
    label: 'Dominican Republic',
  },
  {
    code: 'EC',
    label: 'Ecuador',
  },
  {
    code: 'EG',
    label: 'Egypt',
  },
  {
    code: 'SV',
    label: 'El Salvador',
  },
  {
    code: 'GQ',
    label: 'Equatorial Guinea',
  },
  {
    code: 'ER',
    label: 'Eritrea',
  },
  {
    code: 'EE',
    label: 'Estonia',
  },
  {
    code: 'ET',
    label: 'Ethiopia',
  },
  {
    code: 'FI',
    label: 'Finland',
  },
  {
    code: 'FR',
    label: 'France',
  },
  {
    code: 'GA',
    label: 'Gabon',
  },
  {
    code: 'GM',
    label: 'Gambia',
  },
  {
    code: 'GE',
    label: 'Georgia',
  },
  {
    code: 'DE',
    label: 'Germany',
  },
  {
    code: 'GH',
    label: 'Ghana',
  },
  {
    code: 'GR',
    label: 'Greece',
  },
  {
    code: 'GD',
    label: 'Grenada',
  },
  {
    code: 'GT',
    label: 'Guatemala',
  },
  {
    code: 'GN',
    label: 'Guinea',
  },
  {
    code: 'GW',
    label: 'Guinea-Bissau',
  },
  {
    code: 'GY',
    label: 'Guyana',
  },
  {
    code: 'HT',
    label: 'Haiti',
  },
  {
    code: 'HN',
    label: 'Honduras',
  },
  {
    code: 'HU',
    label: 'Hungary',
  },
  {
    code: 'IS',
    label: 'Iceland',
  },
  {
    code: 'IN',
    label: 'India',
  },
  {
    code: 'ID',
    label: 'Indonesia',
  },
  {
    code: 'IR',
    label: 'Iran',
  },
  {
    code: 'IQ',
    label: 'Iraq',
  },
  {
    code: 'IE',
    label: 'Ireland',
  },
  {
    code: 'IL',
    label: 'Israel',
  },
  {
    code: 'IT',
    label: 'Italy',
  },
  {
    code: 'JM',
    label: 'Jamaica',
  },
  {
    code: 'JP',
    label: 'Japan',
  },
  {
    code: 'JO',
    label: 'Jordan',
  },
  {
    code: 'KZ',
    label: 'Kazakhstan',
  },
  {
    code: 'KE',
    label: 'Kenya',
  },
  {
    code: 'KI',
    label: 'Kiribati',
  },
  {
    code: 'KW',
    label: 'Kuwait',
  },
  {
    code: 'KG',
    label: 'Kyrgyzstan',
  },
  {
    code: 'LA',
    label: 'Laos',
  },
  {
    code: 'LV',
    label: 'Latvia',
  },
  {
    code: 'LB',
    label: 'Lebanon',
  },
  {
    code: 'LS',
    label: 'Lesotho',
  },
  {
    code: 'LR',
    label: 'Liberia',
  },
  {
    code: 'LY',
    label: 'Libya',
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
  },
  {
    code: 'LT',
    label: 'Lithuania',
  },
  {
    code: 'LU',
    label: 'Luxembourg',
  },
  {
    code: 'MO',
    label: 'Macao',
  },
  {
    code: 'MK',
    label: 'North Macedonia',
  },
  {
    code: 'MG',
    label: 'Madagascar',
  },
  {
    code: 'MW',
    label: 'Malawi',
  },
  {
    code: 'MY',
    label: 'Malaysia',
  },
  {
    code: 'MV',
    label: 'Maldives',
  },
  {
    code: 'ML',
    label: 'Mali',
  },
  {
    code: 'MT',
    label: 'Malta',
  },
  {
    code: 'MH',
    label: 'Marshall Islands',
  },
  {
    code: 'MQ',
    label: 'Martinique',
  },
  {
    code: 'MR',
    label: 'Mauritania',
  },
  {
    code: 'MU',
    label: 'Mauritius',
  },
  {
    code: 'YT',
    label: 'Mayotte',
  },
  {
    code: 'MX',
    label: 'Mexico',
  },
  {
    code: 'FM',
    label: 'Micronesia',
  },
  {
    code: 'MD',
    label: 'Moldova',
  },
  {
    code: 'MC',
    label: 'Monaco',
  },
  {
    code: 'MN',
    label: 'Mongolia',
  },
  {
    code: 'ME',
    label: 'Montenegro',
  },
  {
    code: 'MS',
    label: 'Montserrat',
  },
  {
    code: 'MA',
    label: 'Morocco',
  },
  {
    code: 'MZ',
    label: 'Mozambique',
  },
  {
    code: 'MM',
    label: 'Myanmar (Burma)',
  },
  {
    code: 'NA',
    label: 'Namibia',
  },
  {
    code: 'NR',
    label: 'Nauru',
  },
  {
    code: 'NP',
    label: 'Nepal',
  },
  {
    code: 'NL',
    label: 'Netherlands',
  },
  {
    code: 'NC',
    label: 'New Caledonia',
  },
  {
    code: 'NZ',
    label: 'New Zealand',
  },
  {
    code: 'NI',
    label: 'Nicaragua',
  },
  {
    code: 'NE',
    label: 'Niger',
  },
  {
    code: 'NG',
    label: 'Nigeria',
  },
  {
    code: 'NU',
    label: 'Niue',
  },
  {
    code: 'KP',
    label: 'North Korea',
  },
  {
    code: 'NO',
    label: 'Norway',
  },
  {
    code: 'OM',
    label: 'Oman',
  },
  {
    code: 'PK',
    label: 'Pakistan',
  },
  {
    code: 'PW',
    label: 'Palau',
  },
  {
    code: 'PA',
    label: 'Panama',
  },
  {
    code: 'PG',
    label: 'Papua New Guinea',
  },
  {
    code: 'PY',
    label: 'Paraguay',
  },
  {
    code: 'PE',
    label: 'Peru',
  },
  {
    code: 'PH',
    label: 'Philippines',
  },
  {
    code: 'PL',
    label: 'Poland',
  },
  {
    code: 'PT',
    label: 'Portugal',
  },
  {
    code: 'PR',
    label: 'Puerto Rico',
  },
  {
    code: 'QA',
    label: 'Qatar',
  },
  {
    code: 'RO',
    label: 'Romania',
  },
  {
    code: 'RU',
    label: 'Russia',
  },
  {
    code: 'RW',
    label: 'Rwanda',
  },
  {
    code: 'RE',
    label: 'Réunion',
  },
  {
    code: 'BL',
    label: 'Saint Barthélemy',
  },
  {
    code: 'SH',
    label: 'Saint Helena',
  },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
  },
  {
    code: 'LC',
    label: 'Saint Lucia',
  },
  {
    code: 'MF',
    label: 'Saint Martin',
  },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'WS',
    label: 'Samoa',
  },
  {
    code: 'SM',
    label: 'San Marino',
  },
  {
    code: 'ST',
    label: 'São Tomé and Príncipe',
  },
  {
    code: 'SA',
    label: 'Saudi Arabia',
  },
  {
    code: 'SN',
    label: 'Senegal',
  },
  {
    code: 'RS',
    label: 'Serbia',
  },
  {
    code: 'SC',
    label: 'Seychelles',
  },
  {
    code: 'SL',
    label: 'Sierra Leone',
  },
  {
    code: 'SG',
    label: 'Singapore',
  },
  {
    code: 'SX',
    label: 'Sint Maarten',
  },
  {
    code: 'SK',
    label: 'Slovakia',
  },
  {
    code: 'SI',
    label: 'Slovenia',
  },
  {
    code: 'SB',
    label: 'Solomon Islands',
  },
  {
    code: 'SO',
    label: 'Somalia',
  },
  {
    code: 'ZA',
    label: 'South Africa',
  },
  {
    code: 'KR',
    label: 'South Korea',
  },
  {
    code: 'SS',
    label: 'South Sudan',
  },
  {
    code: 'ES',
    label: 'Spain',
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
  },
  {
    code: 'SD',
    label: 'Sudan',
  },
  {
    code: 'SR',
    label: 'Suriname',
  },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
  },
  {
    code: 'SZ',
    label: 'Swaziland',
  },
  {
    code: 'SE',
    label: 'Sweden',
  },
  {
    code: 'CH',
    label: 'Switzerland',
  },
  {
    code: 'SY',
    label: 'Syria',
  },
  {
    code: 'TW',
    label: 'Taiwan',
  },
  {
    code: 'TJ',
    label: 'Tajikistan',
  },
  {
    code: 'TZ',
    label: 'Tanzania',
  },
  {
    code: 'TH',
    label: 'Thailand',
  },
  {
    code: 'TL',
    label: 'Timor-Leste',
  },
  {
    code: 'TG',
    label: 'Togo',
  },
  {
    code: 'TK',
    label: 'Tokelau',
  },
  {
    code: 'TO',
    label: 'Tonga',
  },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
  },
  {
    code: 'TN',
    label: 'Tunisia',
  },
  {
    code: 'TR',
    label: 'Turkey',
  },
  {
    code: 'TM',
    label: 'Turkmenistan',
  },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
  },
  {
    code: 'TV',
    label: 'Tuvalu',
  },
  {
    code: 'UG',
    label: 'Uganda',
  },
  {
    code: 'UA',
    label: 'Ukraine',
  },
  {
    code: 'AE',
    label: 'United Arab Emirates',
  },
  {
    code: 'GB',
    label: 'United Kingdom',
  },
  {
    code: 'US',
    label: 'United States',
  },
  {
    code: 'UY',
    label: 'Uruguay',
  },
  {
    code: 'UZ',
    label: 'Uzbekistan',
  },
  {
    code: 'VU',
    label: 'Vanuatu',
  },
  {
    code: 'VE',
    label: 'Venezuela',
  },
  {
    code: 'VN',
    label: 'Vietnam',
  },
  {
    code: 'WF',
    label: 'Wallis and Futuna',
  },
  {
    code: 'EH',
    label: 'Western Sahara',
  },
  {
    code: 'YE',
    label: 'Yemen',
  },
  {
    code: 'ZM',
    label: 'Zambia',
  },
  {
    code: 'ZW',
    label: 'Zimbabwe',
  },
];

export const productRequestSupportList = ['Technical issue', 'Payment issue', 'Bug'];

export const productionDisabledReportSections = ['Local Area', 'Local Market', 'Comparables'];
