import { Box, Card, CardHeader, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CardBarChart, EcommerceWidgetSummary, MapBox, ReportNote } from 'src/components';
import { DEFAULT_CURRENCY, UNSPECIFIED_VALUE } from 'src/constants/constants';
import { fBigStringRange } from 'src/utils';

import { MarkerType, MarketMappingValuesResult, NoteSettingType } from '../../../../../../types';

export interface LocalMarketType {
  marketMappingValues?: MarketMappingValuesResult[];
  showData?: boolean;
  quartile50?: number;
  quartile75?: number;
  quartile90?: number;
  narrative?: string;
  narrativeTitle?: string;
  coordinates?: number[] | null;
  medianPriceMonths?: string[];
  distributionLabels?: string[];
  medianPrices?: number[];
  distributionData?: number[];
  listingsPrices?: number[];
  noteSetting?: NoteSettingType;
}


export const LocalMarket = (props: LocalMarketType) => {
  const {
    marketMappingValues = [],
    medianPriceMonths = [],
    distributionLabels = [],
    medianPrices = [],
    distributionData = [],
    listingsPrices = [],
    quartile90,
    quartile50,
    quartile75,
    narrativeTitle,
    narrative,
    showData,
    noteSetting,
    coordinates = null,
  } = props;
  const { t } = useTranslation(['report', 'common']);

  const markers = useMemo(() => {
    const markers: MarkerType[] = marketMappingValues?.map((elem: MarketMappingValuesResult) => {
      return {
        location: {
          longitude: elem.longitude,
          latitude: elem.latitude,
        },
        info: { objectType: 'circle' },
        groupData: { color: elem.color },
      };
    });
    return markers;
  }, [marketMappingValues]);

  return <>
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>{t('reportSections.localMarket')}</Typography>
      </Grid>
      {noteSetting?.isActive &&
        <Grid item md={12}>
          {showData
            ? <ReportNote note={noteSetting.note} files={noteSetting.files} />
            : <Skeleton variant='rounded' height='150px' width='100%' />
          }
        </Grid>
      }
      <Grid item md={4}>
        <EcommerceWidgetSummary
          showWidget={showData}
          title={`${t('projectProperties.medPriceLastYear')}, ${DEFAULT_CURRENCY}`}
          subTitle={`${t('projectProperties.region')}: ${quartile50 || UNSPECIFIED_VALUE}`}
          total={quartile50 || UNSPECIFIED_VALUE}
        />
      </Grid>
      <Grid item xs={3} md={4}>
        <EcommerceWidgetSummary
          showWidget={showData}
          title={`${t('projectProperties.75PriceLastYear')}, ${DEFAULT_CURRENCY}`}
          subTitle={`${t('projectProperties.region')}: ${quartile75 || UNSPECIFIED_VALUE}`}
          total={quartile75 || UNSPECIFIED_VALUE}
        />
      </Grid>
      <Grid item xs={3} md={4}>
        <EcommerceWidgetSummary
          showWidget={showData}
          title={t('projectProperties.90PriceLastYear')}
          subTitle={`${t('projectProperties.region')}: ${quartile90 || UNSPECIFIED_VALUE}`}
          total={quartile90 || UNSPECIFIED_VALUE}
        />
      </Grid>
      {!showData &&
        <Grid item md={12}>
          <Skeleton variant='rounded' height='150px' width='100%' />
        </Grid>
      }
      <Grid item md={12}>
        {showData ?
          <Card>
            <CardHeader title={t('projectProperties.aboutLocalMarket')} />
            <Stack
              spacing={1.5}
              sx={{
                p: 3,
                typography: 'body2',
              }}
            >
              <Stack direction='row' alignItems='center'>
                <Typography variant='subtitle2' mr={0.5}>{t('projectProperties.area')}:</Typography>
                {narrativeTitle || 'Unspecified'}
              </Stack>
              <Stack direction='row' alignItems='center'>
                {narrative || 'Unspecified'}
              </Stack>
              <Box sx={{ marginTop: '24px' }}>
                <MapBox
                  height='443px'
                  width='100%'
                  mapStyle='street'
                  markers={markers}
                  coordinates={coordinates || null}
                />
              </Box>
            </Stack>
          </Card>
          : <Skeleton variant='rounded' height='660px' width='100%' />
        }
      </Grid>
      <Grid item md={12}>
        <CardBarChart
          title={t('projectProperties.medianPriceOverTimeAndMonthlyListings')}
          // TODO remove hardcode
          subheader={`(+43%) ${t('projectProperties.thanLastYear')}`}
          chart={{
            options: { xaxis: { type: 'category' } },
            labels: medianPriceMonths,
            series: [
              {
                name: t('projectProperties.monthlyListings'),
                type: 'column',
                fill: 'solid',
                data: listingsPrices,
              },
              {
                name: t('projectProperties.medianPrice'),
                type: 'area',
                fill: 'gradient',
                data: medianPrices,
              },
            ],
          }}
        />
      </Grid>
      <Grid item md={12}>
        <CardBarChart
          title={t('projectProperties.distributionOfPrices')}
          chart={{
            options: { xaxis: { type: 'category' } },
            labels: distributionLabels.map(distributionLabel => fBigStringRange(distributionLabel)),
            series: [
              {
                name: t('projectProperties.monthlyListings'),
                type: 'column',
                fill: 'solid',
                data: distributionData,
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  </>;
};
