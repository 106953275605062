import { useMemo } from 'react';
import { TableCustom } from 'src/components';
import { paths } from 'src/config';
import { MARKETPLACE_PROJECT_REPORTS_TABLE_HEAD } from 'src/features/marketplace-details/components/project-details/mock-data';
import { useRouter, useTable } from 'src/hooks';
import { fDate, getNewestDate, openInNewTab } from 'src/utils';

import { DetailViewData, MarketplaceProjectReport } from '../../../../../../../../types';
import { getPathForReport, reportCustomSort } from './helpers';
import { ReportTableRow } from './reports-table-row';

export interface ReportsProps {
  detailsData?: DetailViewData;
  showGeneralReport?: boolean;
  showGeneralReportOriginal?: boolean;
  showGeneralReportModified?: boolean;
  showSupplierReports?: boolean;
  showBankReports?: boolean;
}

export const Reports = (props: ReportsProps) => {
  const {
    detailsData = {},
    showGeneralReport = true,
    showGeneralReportOriginal,
    showGeneralReportModified,
    showBankReports = true,
    showSupplierReports = true,
  } = props;

  const table = useTable({
    tableKey: 'reports',
    defaultOrderBy: 'name',
  });
  const router = useRouter();
  const detailsReports = useMemo(() => detailsData.reports || [], [detailsData.reports]);
  const scenario = detailsData?.project?.scenario;
  const assets = detailsData?.assets;
  const offeringDetails = detailsData?.offeringDetails;
  const leadId = detailsData?.lead?._id || '';
  const projectId = detailsData?.project?._id || '';

  const generalReport: MarketplaceProjectReport = useMemo((): MarketplaceProjectReport => {
    return {
      _id: 'generalReport',
      reportName: 'General report',
      createdAt: fDate(scenario?.createdAt),
      updatedAt: getNewestDate([scenario?.updatedAt || '', assets?.updatedAt || '']),
      isOpenInNewTab: true,
      url: paths.reports.calculatorReport(leadId),
      svgColor: '#004b50',
      reportType: 'calculator',
      visibility: 'Published',
    };
  }, [assets?.updatedAt, leadId, scenario?.createdAt, scenario?.updatedAt]);

  const generalReportOriginal: MarketplaceProjectReport = useMemo((): MarketplaceProjectReport => {
    return {
      _id: 'generalReportOriginal',
      reportName: 'General Report Original',
      createdAt: fDate(scenario?.createdAt),
      updatedAt: getNewestDate([scenario?.updatedAt || '', assets?.updatedAt || '']),
      isOpenInNewTab: true,
      url: paths.reports.calculatorReport(leadId),
      svgColor: '#004b50',
      reportType: 'calculatorOriginal',
      visibility: 'Published',
    };
  }, [assets?.updatedAt, leadId, scenario?.createdAt, scenario?.updatedAt]);

  const generalReportModified = useMemo((): MarketplaceProjectReport => {
    return {
      _id: 'generalReportModified',
      reportName: 'General Report Modified',
      createdAt: fDate(scenario?.createdAt),
      updatedAt: getNewestDate([scenario?.updatedAt || '', offeringDetails?.updatedAt || '']),
      isOpenInNewTab: true,
      url: paths.investorPortal.projects.details(projectId, 'overview'),
      svgColor: '#004b50',
      reportType: 'calculatorModified',
      visibility: 'Published',
    };
  }, [offeringDetails?.updatedAt, projectId, scenario?.createdAt, scenario?.updatedAt]);

  const reports: MarketplaceProjectReport[] = useMemo(() => {
    const updatedReports = detailsReports.map(report => getPathForReport(leadId, report));
    return [generalReport, generalReportOriginal, generalReportModified, ...updatedReports];
  }, [detailsReports, generalReport, generalReportModified, generalReportOriginal, leadId]);

  const dataFiltered: MarketplaceProjectReport[] = reportCustomSort(reports, table, {
    showBankReports,
    showSupplierReports,
    showGeneralReport,
    showGeneralReportOriginal,
    showGeneralReportModified,
  });
  const noDataFound = dataFiltered?.length === 0;

  const onOpenClick = (row: MarketplaceProjectReport) => () => {
    const {
      isOpenInNewTab,
      url,
    } = row;
    isOpenInNewTab
      ? openInNewTab(url)
      : router.push(url);
  };

  return (
    <TableCustom
      separateRows
      scrollbar={false}
      headLabels={MARKETPLACE_PROJECT_REPORTS_TABLE_HEAD}
      table={table}
      rowCount={reports?.length}
      noDataFound={noDataFound}
      bodyCells={(
        <>
          {dataFiltered?.map((row) => (
            <ReportTableRow
              key={row._id}
              onOpenClick={onOpenClick(row)}
              row={row}
            />
          ))}
        </>
      )}
    />
  );
};
