import { NoteSettingType } from '../../../../../types';

export const reportList: NoteSettingType[] = [
  {
    isActive: true,
    section: 'Gallery',
    sectionTranslationKey: 'gallery',
  },
  {
    isActive: true,
    section: 'Project Summary',
    sectionTranslationKey: 'projectSummary',
  },
  {
    isActive: true,
    section: 'Our Request',
    sectionTranslationKey: 'ourRequest',
  },
  {
    isActive: true,
    section: 'Site & Purchase',
    sectionTranslationKey: 'siteAndPurchase',
  },
  {
    isActive: true,
    section: 'Proposed Units',
    sectionTranslationKey: 'proposedUnits',
  },
  {
    isActive: true,
    section: 'Construction',
    sectionTranslationKey: 'construction',
  },
  {
    isActive: true,
    section: 'Finance',
    sectionTranslationKey: 'finance',
  },
  {
    isActive: true,
    section: 'Timeline & Cashflow',
    sectionTranslationKey: 'timelineAndCashflow',
  },
  {
    isActive: true,
    section: 'Sales',
    sectionTranslationKey: 'sales',
  },
  {
    isActive: true,
    section: 'Project Multiples',
    sectionTranslationKey: 'projectMultiples',
  },
  {
    isActive: true,
    section: 'Sensitivity Analysis',
    sectionTranslationKey: 'sensitivityAnalysis',
  },
  {
    isActive: true,
    section: 'Local Area',
    sectionTranslationKey: 'localArea',
  },
  {
    isActive: true,
    section: 'Local Market',
    sectionTranslationKey: 'localMarket',
  },
  {
    isActive: true,
    section: 'Comparables',
    sectionTranslationKey: 'comparables',
  },
];

export const sectionKeyToValue: Record<string, string> = {
  gallery: 'Gallery',
  aboutProject: 'Project Summary',
  ourRequest: 'Our Request',
  siteAndPurchase: 'Site & Purchase',
  proposedUnits: 'Proposed Units',
  construction: 'Construction',
  finance: 'Finance',
  sales: 'Sales',
  timelineAndCashflow: 'Timeline & Cashflow',
  sensitivityAnalysis: 'Sensitivity Analysis',
  projectMultiples: 'Project Multiples',
  localArea: 'Local Area',
  localMarket: 'Local Market',
  comparables: 'Comparables',
};

export const notesList: NoteSettingType[] = reportList.map(report => {
  return {
    ...report,
    isActive: false,
  };
}).filter(item => item.section !== 'Gallery');
