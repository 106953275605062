import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from 'src/components/editor';

export interface SummaryTextProps {
  text: string;
  setText: Dispatch<SetStateAction<string>>;
}

export const SummaryText = (props: SummaryTextProps) => {
  const {
    setText,
    text,
  } = props;
  const { t } = useTranslation();

  return (
    <Editor
      id='minimal-quill-summary'
      value={text}
      onChange={setText}
      placeholder={t('description')}
      headings={[]}
      simple
    />
  );
};
