import { RoutePath } from './app-routes';

export const paths = {
  users: RoutePath.users,
  getStarted: RoutePath.getStarted,
  dashboard: RoutePath.dashboard,
  bankDashboard: RoutePath.bankDashboard,
  supplierDashboard: RoutePath.supplierDashboard,

  profile: {
    userProfile: RoutePath.userProfile,
    companyProfile: RoutePath.companyProfile,
    partners: RoutePath.partners,
    billing: RoutePath.billing,
  },

  leads: {
    details: (id: string, tab: string) => `${RoutePath.leads}/details/${id}/${tab}`,
    edit: (id: string) => `${RoutePath.leads}/edit/${id}`,
    newLead: `${RoutePath.leads}/new-lead`,
    list: RoutePath.leads,
  },
  research: {
    details: (id: string, tab: string = 'overview') => `${RoutePath.research}/details/${id}/${tab}`,
    list: RoutePath.research,
  },
  financing: {
    details: (id: string, tab: string = 'overview') => `${RoutePath.financing}/details/${id}/${tab}`,
    debtRequest: (id: string, debtIs: string) => `${RoutePath.financing}/details/${id}/financing-debt-request?debtId=${debtIs}`,
    debtFinancingAgreement: (id?: string) => `${RoutePath.financing}/debt-financing-agreement/${id ? id : ''}`,
    uploadFinancingAgreementDocuments: (id: string) => `${RoutePath.financing}/upload-financing-agreement-documents/${id}`,
    list: RoutePath.financing,
  },
  projectPreparation: {
    details: (id: string, tab: string = 'overview') => `${RoutePath.preparation}/details/${id}/${tab}`,
    list: RoutePath.preparation,
  },
  tendering: {
    details: (id: string, tab: string = 'overview') => `${RoutePath.tendering}/details/${id}/${tab}`,
    list: RoutePath.tendering,
  },
  readyForInvestors: {
    details: (id: string, tab: string = 'overview') => `${RoutePath.readyForInvestors}/details/${id}/${tab}`,
    list: RoutePath.readyForInvestors,
    equityPartners: (id: string) => `${RoutePath.readyForInvestors}/equity-partners/${id}`,
    debtPartners: (id: string) => `${RoutePath.readyForInvestors}/debt-partners/${id}`,
    marketplacePublish: (id: string) => `${RoutePath.readyForInvestors}/marketplace-publish/${id}`,
  },
  marketplaces: {
    projectDetail: (marketplaceId: string, id: string, tab: string) => `${RoutePath.marketplaces}/details/${marketplaceId}/projects/${id}/${tab}`,
    details: (id: string, tab: string) => `${RoutePath.marketplaces}/details/${id}/${tab}`,
    list: RoutePath.marketplaces,
  },
  supplierPartners: {
    list: RoutePath.supplierPartners,
    products: (id: string) => `${RoutePath.supplierPartners}/${id}/products`,
    editProduct: (id: string, productId: string) => `${RoutePath.supplierPartners}/${id}/products/${productId}/edit`,
    createProduct: (id: string) => `${RoutePath.supplierPartners}/${id}/products/new`,
  },
  equityPartners: { list: RoutePath.equityPartners },
  bankPartners: {
    list: RoutePath.bankPartners,
    products: (id: string) => `${RoutePath.bankPartners}/${id}/products`,
    editProduct: (id: string, productId: string) => `${RoutePath.bankPartners}/${id}/products/${productId}/edit`,
    createProduct: (id: string) => `${RoutePath.bankPartners}/${id}/products/new`,
  },
  investorPortal: {
    portfolio: {
      list: RoutePath.investorPortfolio,
      details: (id: string, tab: string) => `${RoutePath.investorPortfolio}/${id}/${tab}`,
    },
    projects: {
      list: RoutePath.investorProjects,
      details: (id: string, tab: string = 'overview') => `${RoutePath.investorProjects}/${id}/${tab}`,
      preview: (tab: string) => `${RoutePath.investorProjects}/preview/${tab}`,
    },
    activity: { list: RoutePath.investorActivity },
  },
  partnerPortal: {
    overview: RoutePath.partnerOverview,
    projects: RoutePath.partnerProjects,
    investmentActivity: RoutePath.partnerInvestmentActivity,
    opportunities: {
      list: RoutePath.partnerProjectOpportunities,
      publish: (id: string) => `${RoutePath.partnerProjectOpportunities}/${id}/offering-publish`,
      details: (id: string, tab: string) => `${RoutePath.partnerProjectOpportunities}/${id}/${tab}`,
    },
    offerings: {
      list: RoutePath.partnerProjectOfferings,
      details: (id: string, tab: string) => `${RoutePath.partnerProjectOfferings}/${id}/${tab}`,
    },
  },
  reports: {
    bankReport: (id: string, identifier: string) => `/report/bank/${id}/${identifier}`,
    saleReport: (id: string, identifier: string) => `/report/sale/${id}/${identifier}`,
    calculatorReport: (id: string) => `/report/calculator/${id}`,
    supplierReport: (id: string, identifier: string) => `/report/supplier/${id}/${identifier}`,
  },
  sell: {
    details: (id: string, tab: string = 'overview') => `${RoutePath.sell}/details/${id}/${tab}`,
    list: RoutePath.sell,
  },
};
