import { Card, Grid, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReportNote, TableCustom, TablePaginationCustom } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { TableHeadType } from 'src/features/leads-page';
import { useTable } from 'src/hooks';
import { getComparables } from 'src/store/storage';
import { LoadComparablesBody } from 'src/store/storage/types';

import { ComparablesResponse, ComparablesSortBy, NoteSettingType } from '../../../../../../types';
import { ComparablesTableRow } from './components';

export interface ComparablesProps {
  comparables: ComparablesResponse;
  coordinates?: number[] | null;
  noteSetting?: NoteSettingType;
}

export const Comparables = (props: ComparablesProps) => {
  const {
    comparables,
    noteSetting,
    coordinates,
  } = props;
  const { t } = useTranslation(['report', 'common']);
  const table = useTable({ tableKey: 'comparables' });
  const [correspondingBody, setCorrespondingBody] = useState<LoadComparablesBody>({
    page: 1,
    limit: table.rowsPerPage,
    longitude: coordinates?.[0],
    latitude: coordinates?.[1],
  });
  const dispatch = useDispatch();

  const noDataFound = comparables?.properties?.length === 0;

  useEffect(() => {
    setCorrespondingBody(correspondingBody);
    dispatch(getComparables(correspondingBody));
  }, [coordinates, dispatch]);

  const updatedComparables = (newBodyPart: Partial<LoadComparablesBody>) => {
    const newBody: LoadComparablesBody = {
      ...correspondingBody,
      ...newBodyPart,
    };
    setCorrespondingBody(newBody);
    dispatch(getComparables(newBody));
  };

  const tableSortHandler = debounce((_id: string) => {
    const {
      orderBy,
      order,
    } = table;
    const isAsc = orderBy === _id && order === 'asc';
    updatedComparables({
      sortBy: _id as ComparablesSortBy,
      sortMethod: isAsc ? 'desc' : 'asc',
    });
    table.onSort(_id);
  }, 200);

  const onPageChange = debounce((event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    updatedComparables({ page: page + 1 });
    table.onChangePage(event, page);
  }, 200);

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updatedComparables({
      limit: +event.target.value,
      page: 1,
    });
    table.onChangeRowsPerPage(event);
  };

  const TABLE_HEAD: TableHeadType[] = [
    {
      _id: 'displayAddress',
      label: t('tableRows.name', { ns: 'common' }),
      width: 420,
    },
    {
      _id: 'totalFloorAreaMeter',
      label: t('tableRows.size', { ns: 'common' }),
      width: 160,
      align: 'right',
    },
    {
      _id: 'price',
      label: DEFAULT_CURRENCY,
      width: 160,
      align: 'right',
    },
    {
      _id: 'propertySubType',
      label: t('tableRows.type', { ns: 'common' }),
      width: 160,
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>{t('reportSections.comparables')}</Typography>
      </Grid>
      {noteSetting?.isActive &&
        <Grid item md={12}>
          <ReportNote note={noteSetting.note} files={noteSetting.files} />
        </Grid>
      }
      <Grid item md={12}>
        <Card>
          <TableCustom
            headLabels={TABLE_HEAD}
            onSort={tableSortHandler}
            table={table}
            rowCount={comparables.properties?.length}
            noDataFound={noDataFound}
            bodyCells={(
              <>
                {comparables?.properties?.map((row) => (
                  <ComparablesTableRow key={row._id} row={row} />
                ))}
              </>
            )}
          />
          <TablePaginationCustom
            count={comparables.count ?? 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={onPageChange}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

