import _ from 'lodash';
import { FieldError, FieldErrors, FieldValues, Path } from 'react-hook-form';

export const phoneRegExp = /(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})/;
export const emailRegExp = /^[\w%+.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/;
export const numberRegExp = /^[\d,.]*$/;
export const urlRegExp = /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

export const isPhoneNumberValid = (value: string) => {
  return phoneRegExp.test(value) && value.length < 20;
};

export const isEmailValid = (value: string) => {
  return emailRegExp.test(value);
};

export const isNumberInput = (value: string) => {
  return numberRegExp.test(value);
};

export const isUrlValid = (value: string) => {
  return urlRegExp.test(value);
};

export const getNestedFormError = <T extends FieldValues>(errors: FieldErrors<T>, key: Path<T>): FieldError | undefined => {
  const errorValue = _.get(errors, key);
  return errorValue as FieldError;
};

export const checkPassword = (password: string): string | null => {
  if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w!"#$%&*:?@[\]^`{-~№-]{8,}$/.test(password)) {
    return null;
  }

  if (!/(.){8,}/.test(password)) {
    return passwordErrors.minimumEight;
  }

  if (!/[a-z]+/.test(password)) {
    return passwordErrors.lowerCase;
  }

  if (!/[A-Z]+/.test(password)) {
    return passwordErrors.upperCase;
  }

  if (!/[:[dgit]]/.test(password)) {
    return passwordErrors.digit;
  }

  return passwordErrors.other;
};

export enum passwordErrors {
  lowerCase = 'Use at least one lowercase sign',
  upperCase = 'Use at least one uppercase sign',
  digit = 'Use at least one digit sign',
  minimumEight = 'Password must contain at least 8 signs',
  other = 'Something wrong in your password',
}
