import { omit, pick } from 'lodash';
import { CreateNewUserType, UpdateUserType } from 'src/features/users-page/types';
import { deletePartnerThunk } from 'src/store/partners';
import { getReturnUrl } from 'src/utils';

import { AddFileSectionResponseType, CompanyInviteType, Config, FileSectionsType, FinanceUploadFileType, PartnerItemType, User, UserRole } from '../../../../types';
import { apiFetch } from '../api';
import { authInitAction, setUser as setUserAuth } from '../auth';
import { AppThunk } from '../store';
import {
  addFileSection,
  deleteUser,
  setConfig,
  setConfigError,
  setConfigStatus,
  setFileSections,
  setResetPasswordError,
  setUser,
  setUserList,
  setUserListStatus,
  setUserStatus,
} from '.';

export const loadConfig = (callBack?: () => void): AppThunk => async (dispatch) => {
  try {
    dispatch(setConfigStatus('load'));
    const config = await apiFetch('config', {
      method: 'get',
      returnError: true,
    }) as Config | {
      error: string
    };

    if ('error' in config) {
      dispatch(setConfigStatus('error'));
      dispatch(setConfigError(config.error));
    } else {
      dispatch(setConfig(config));
      dispatch(authInitAction());
      callBack?.();
      dispatch(setConfigStatus('success'));
    }

  } catch (error) {
    console.error(error);
    dispatch(setConfigStatus('error'));
    dispatch(setConfigError((error as Error).message));
  }
};

export const getUser = (userId?: string): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setUserStatus('load'));

    const user = await apiFetch(`user${userId ? `/${userId}` : ''}`);

    if (!user) {
      console.error('Can not update user');
      return;
    }

    dispatch(setUser(user));
    dispatch(setUserStatus('success'));
  } catch (error) {
    console.error(error);
    dispatch(setUser(null));
    dispatch(setUserStatus('error'));
  }
};

export const getUserListAsync = async (partnerId: string) => {
  const userList = await apiFetch('user/list', { headers: { partnerId } }) as User[];
  return userList || [];
};

export const getUserList = (excludeRole?: UserRole): AppThunk => async (dispatch, getState) => {
  const partnerId = getState().Auth.user?.partnerId || '';

  try {
    dispatch(setUserListStatus('load'));
    const excludeRoleStr = excludeRole ? `/${excludeRole}` : '';
    const userList = await apiFetch(`user/list${excludeRoleStr}`, { headers: { partnerId } }) as User[];
    if (!userList) {
      console.error('Can not update user list');
      return;
    }
    dispatch(setUserList((userList as User[])));
    dispatch(setUserListStatus('success'));
  } catch (error) {
    console.error(error);
    dispatch(setUserList([]));
    dispatch(setUserListStatus('error'));
  }
};

export type GetCompanyInvitesType = {
  isUser?: boolean;
  isPartner?: boolean;
  onlyPending?: boolean;
  email?: string;
  excludeRole?: UserRole;
}

export const changeUserEmailByToken = (token: string): AppThunk => async (dispatch) => {
  try {
    const res = await apiFetch(`user/email-change-token/${token}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
    });
    if (res.userId) {
      const url = new URL(window.location.href);
      url.searchParams.delete('email-change-token');
      window.history.replaceState({}, '', url);
      window.location.reload();
    }
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getCompanyInvites = async (params: GetCompanyInvitesType): Promise<CompanyInviteType[]> => {
  try {
    const {
      isUser = false,
      isPartner = false,
      onlyPending = false,
      email,
      excludeRole,
    } = params;
    const invites: CompanyInviteType[] = await apiFetch('user/invites', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        isPartner,
        isUser,
        onlyPending,
        excludeRole,
      }),
    }) || [];
    return invites.reduce((acc, invite) => {
      if (invite.partnerId && !acc.some(existingInvite => existingInvite.partnerId === invite.partnerId && existingInvite.email === invite.email)) {
        acc.push(invite);
      }
      return acc;
    }, [] as CompanyInviteType[]);
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const acceptCompanyInvites = (companyInvites: CompanyInviteType[] = []): AppThunk => async (_, getState) => {
  try {
    const user = getState().Auth.user;
    await Promise.all(companyInvites.map((invite) => apiFetch(`user/accept-invite/${invite.partnerId}`)));

    const partner = (companyInvites?.[0] || {});
    const updatedUser = { ...user, partner } as User;
    const url = getReturnUrl(updatedUser);

    window.location.href = url;
  } catch (error) {
    console.error(error);
  }
};

export const declineCompanyInvite = async (email: string) => {
  try {
    await apiFetch('user/decline-invite', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });
  } catch (error) {
    console.error(error);
  }
};
export const inviteUserToCompany = async (email: string) => {
  try {
    return await apiFetch('user/invite', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateUserProfileThunk = (user: Partial<User>, callBack?: VoidFunction): AppThunk => async (dispatch, getState) => {
  try {
    const userInStore = getState().Auth.user || {} as User;
    const pickedUser = pick(user, ['userFirstAction', 'name', 'surname',
      'phoneNumber', 'agreedTerms', 'agreedSubscription', 'jobTitle',
      'password', 'email', 'oldPassword', 'role',
    ]);
    const res = await apiFetch('auth/profile', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(pickedUser),
      returnError: true,
    });

    if (res.error) {
      dispatch(setResetPasswordError(res.error.replace('Error: ', '')));
      throw new Error(res.error);
    }

    const finalUser = {
      ...userInStore,
      ...user,
    };
    callBack?.();
    dispatch(setUser(finalUser));
    dispatch(setResetPasswordError(''));
  } catch (error) {
    console.error(error);
  }
};

export const uploadAvatarToAuthedUser = (avatar: File): AppThunk => async (_, getState) => {
  try {
    const user = getState().Auth.user || {} as User;
    return await apiFetch(`userpic/${user.id}`, {
      method: 'post',
      headers: { 'Content-Type': avatar.type || 'image/png' },
      body: avatar,
    });
  } catch (error) {
    console.error(error);
  }
};

export const addUserToPartner = (partnerId: string, partner: PartnerItemType, userData: Partial<User>, callback?: () => void): AppThunk => async (dispatch, getState) => {
  try {
    const user = getState().Auth.user;

    if (user) {
      dispatch(setUserAuth({
        ...user,
        ...userData,
        partner,
        partnerId,
      }));
    }

    await apiFetch(`user/add-to-partner/${partnerId}`);

    callback?.();
  } catch (error) {
    console.error(error);
  }
};

export const updatePartnerUser = async (userId: string, fieldsToUpdate: Partial<User>) => {
  try {
    const response: { _id: string } = await apiFetch(`user/update-partner-user/${userId}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(fieldsToUpdate),
      returnError: true,
    });
    if (response._id) {
      return response;
    }

  } catch (error) {
    console.error(error);
  }
};

export const sendWelcomeEmail = (name: string, email: string, partnerId: string): AppThunk => async () => {
  try {
    await apiFetch('user/send-welcome-email', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      body: JSON.stringify({
        name,
        email,
      }),
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteUserThunk = (userId: string, deletePartner?: boolean, callBack?: VoidFunction): AppThunk => async (dispatch, getState) => {
  try {
    const partnerId = getState().Auth.user?.partnerId || '';

    await apiFetch(`user/delete/${userId}`, {
      method: 'delete',
      headers: { partnerId },
    });
    if (deletePartner && partnerId) {
      dispatch(deletePartnerThunk(partnerId));
    }
    callBack?.();
    dispatch(deleteUser(userId));
  } catch (error) {
    console.error(error);
  }
};

export const createNewUser = (newUser: CreateNewUserType): AppThunk => async () => {
  try {
    const createdUser = await apiFetch('user/create', {
      method: 'post',
      body: JSON.stringify(omit(newUser, ['role', 'avatar'])),
    });

    if (newUser.avatar && createdUser) {
      // TODO shoud be abe to set picture to not authed user
      // await apiFetch('userpic', {
      //   method: 'post',
      //   body: newUser.avatar,
      //   headers: { 'Content-Type': newUser.avatar.type },
      // });
    }
  } catch (error) {
    console.error(error);
  }
};
export const updateUserThunk = (updatedUser: UpdateUserType): AppThunk => async (dispatch, getState) => {
  try {
    // const createdUser = await apiFetch('user/create', {
    //   method: 'post',
    //   body: JSON.stringify(omit(newUser, ['role', 'avatar'])),
    // });

    // if (updatedUser.avatar && createdUser) {
    //   const uploadedAvatar = await apiFetch('userpic', {
    //     method: 'post',
    //     body: updatedUser.avatar,
    //     headers: { 'Content-Type': updatedUser.avatar.type },
    //   });
    // }
  } catch (error) {
    console.error(error);
  }
};

export const getFileSections = (leadId: string = '', projectId: string = ''): AppThunk => async (dispatch, getState) => {
  const partnerId = getState().Auth.user?.partnerId || '';

  try {
    const fileSections: FileSectionsType[] = await apiFetch(`file-section/list/${leadId}/${projectId}`, {
      method: 'get',
      headers: { partnerId },
    });
    dispatch(setFileSections(fileSections || []));
  } catch (error) {
    console.error(error);
    dispatch(setFileSections([]));
  }
};

export const addFileSectionThunk = (paramBody: FinanceUploadFileType): AppThunk => async (dispatch, getState) => {
  try {
    const partnerId = getState().Auth.user?.partnerId || '';

    const correspondingBody = {
      section: paramBody.section,
      filename: paramBody.filename,
    };
    const response: AddFileSectionResponseType = await apiFetch('file-section/', {
      method: 'post',
      body: JSON.stringify(correspondingBody),
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });

    if (!response) {
      console.error('Can not create new file section');
      return;
    }

    const newSection: FileSectionsType = {
      section: paramBody.section || 'Lead',
      filenames: [{
        ...paramBody,
        files: [],
        _id: response.fileSectionId,
      }],
    };
    dispatch(addFileSection(newSection));
  } catch (error) {
    console.error(error);
    dispatch(setFileSections([]));
  }
};
