import { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material/styles';
import { debounce } from 'lodash';
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { CustomSelect, SearchField } from 'src/components';

export type TableToolbarProps<T, V, A> = {
  onFilters: (name: keyof T, value: V | V[] | A | A[] | string | string[] | null) => void;

  selectValue?: V[];
  selectOptions?: V[];
  selectedOptions?: V[];
  selectLabel?: string;
  selectKey?: keyof T;

  showAdditionalSelect?: boolean;
  additionalSelectValue?: A[];
  additionalSelectOptions?: A[];
  additionalSelectedOptions?: A[];
  additionalSelectLabel?: string;
  additionalSelectKey?: keyof T;

  searchFieldPlaceholder?: string;
  searchFieldValue?: string;
  searchFieldKey: keyof T;

  stackSx?: SxProps<Theme>;
  firstChildren?: ReactNode;
}

export const TableToolbar = <T, V, A, >(props: TableToolbarProps<T, V, A>) => {
  const {
    selectValue,
    selectOptions,
    onFilters,
    selectedOptions,
    selectLabel,
    selectKey,
    stackSx,
    firstChildren,
    searchFieldPlaceholder = 'Search ...',
    searchFieldValue = '',
    searchFieldKey,
    showAdditionalSelect = false,
    additionalSelectedOptions,
    additionalSelectOptions,
    additionalSelectLabel,
    additionalSelectKey,
    additionalSelectValue,
  } = props;
  const [inputValue, setInputValue] = useState<string>(searchFieldValue);
  const [selectValueState, setSelectValueState] = useState<V[] | undefined>(selectValue);

  const debouncedFilterName = useMemo(
    () => debounce((filterKey: keyof T, value: string | string[] | V[] | A[]) => onFilters(filterKey, value as V), 1000),
    [onFilters],
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedFilterName.cancel();
    debouncedFilterName(searchFieldKey, value);
  };

  const handleSelectOnChange = (filterKey: keyof T = selectKey!) => (event: SelectChangeEvent<V[] | A[]>) => {
    const targetValue: string | V[] | A[] = event.target.value;
    const value = typeof targetValue === 'string' ? targetValue.split(',') : targetValue;
    setSelectValueState(value as V[]);
    debouncedFilterName.cancel();
    debouncedFilterName(filterKey, value);
  };

  useEffect(() => {
    setInputValue(searchFieldValue);
    setSelectValueState(selectValue);
  }, [searchFieldValue, selectValue]);

  return (
    <Stack
      spacing={2}
      alignItems={{
        xs: 'flex-end',
        md: 'center',
      }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{ p: 2.5, ...stackSx }}
    >
      {firstChildren}
      {selectKey &&
        <CustomSelect
          multiple
          showCheckbox
          controlSx={{
            width: {
              xs: 1,
              md: 292,
            },
          }}
          value={selectValueState}
          handleSelectOnChange={handleSelectOnChange(selectKey)}
          label={selectLabel}
          options={selectOptions}
          selectedOptions={selectValueState}
        />
      }
      {showAdditionalSelect &&
        <CustomSelect
          multiple
          showCheckbox
          controlSx={{
            width: {
              xs: 1,
              md: 292,
            },
          }}
          value={additionalSelectValue}
          handleSelectOnChange={handleSelectOnChange(additionalSelectKey)}
          label={additionalSelectLabel}
          options={additionalSelectOptions}
          selectedOptions={additionalSelectedOptions}
        />
      }

      <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <SearchField
          value={inputValue}
          placeholder={searchFieldPlaceholder}
          handleFieldOnChange={handleInputChange}
        />
      </Stack>
    </Stack>
  );
};
