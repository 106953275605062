import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { fNumber, fPercent } from 'src/utils';

import { DebtDetailsType, FinancialStepType, Lead, QuotationType, Scenario } from '../../../types';

export const useGetProjectSummaryChainedItems = (scenario?: Scenario, lead?: Lead) => {
  const scenarioOutput = scenario?.calculate?.output;
  const { t } = useTranslation(['report']);
  return useMemo(() => {
    return [
      {
        title: t('projectProperties.type'),
        value: scenarioOutput?.['Project type'],
      },
      {
        title: t('projectProperties.location'),
        value: lead?.location?.address,
      },
      {
        title: t('projectProperties.gdv'),
        value: `${fNumber(scenarioOutput?.GDV)} ${DEFAULT_CURRENCY}`,
      },
      {
        title: t('projectProperties.netMargin'),
        value: fPercent(scenarioOutput?.['Net margin']),
      },
      {
        title: t('projectProperties.profitOnCost'),
        value: fPercent(scenarioOutput?.['Profit on cost']),
      },
      {
        title: t('projectProperties.netProfit'),
        value: fNumber(scenarioOutput?.['Net profit']),
      },
      {
        title: t('projectProperties.timeFrame'),
        value: `${scenarioOutput?.['Build time']} months`,
      },
    ];
  }, [lead?.location?.address, scenarioOutput, t]);
};

export const useGetQuotationChainedItems = (quotation?: QuotationType, scenario?: Scenario) => {
  const buildTime = scenario?.calculate?.input.build_time_months;
  const costs = scenario?.calculate?.input.total_construction_cost;
  const { t } = useTranslation(['report']);
  return useMemo(() => {
    return [
      {
        title: t('projectProperties.services'),
        value: quotation?.requestType,
      },
      {
        title: t('projectProperties.constructionCosts'),
        value: `${fNumber(quotation?.preliminaryOffer?.constructionCost || costs)} ${DEFAULT_CURRENCY}`,
      },
      {
        title: t('projectProperties.buildTimeMonths'),
        value: quotation?.preliminaryOffer?.buildTime || buildTime,
      },
      {
        title: t('projectProperties.partnerFee'),
        value: quotation?.preliminaryOffer?.partnerFee || quotation?.fee,
      },
    ];
  }, [buildTime, costs, quotation?.fee, quotation?.preliminaryOffer?.buildTime, quotation?.preliminaryOffer?.constructionCost, quotation?.preliminaryOffer?.partnerFee, quotation?.requestType, t]);
};

export const useGetDebtChainedItems = (scenario?: Scenario, firstPreliminaryRequest?: FinancialStepType, detailsList?: DebtDetailsType) => {
  const scenarioOutput = scenario?.calculate?.output;
  const { t } = useTranslation('common');
  return useMemo(() => {
    return [
      {
        title: t('tableRows.totalFunds'),
        value: `${fNumber(firstPreliminaryRequest?.totalFunds || detailsList?.totalFunds)} ${DEFAULT_CURRENCY}`,
      },
      {
        title: t('tableRows.rate'),
        value: fPercent(firstPreliminaryRequest?.rate || detailsList?.rate),
      },
      {
        title: t('tableRows.fee'),
        value: fPercent(firstPreliminaryRequest?.fee || detailsList?.fee),
      },
      {
        title: t('tableRows.ltc'),
        value: fPercent(firstPreliminaryRequest?.ltc || detailsList?.ltc),
      },
      {
        title: t('tableRows.ltgdv'),
        value: fPercent(firstPreliminaryRequest?.ltgdv || detailsList?.ltgdv),
      },
      {
        title: t('report:projectProperties.timeFrame'),
        value: `${scenarioOutput?.['Build time']} months`,
      },
    ];
  }, [detailsList?.fee, detailsList?.ltc, detailsList?.ltgdv, detailsList?.rate, detailsList?.totalFunds, firstPreliminaryRequest?.fee, firstPreliminaryRequest?.ltc, firstPreliminaryRequest?.ltgdv, firstPreliminaryRequest?.rate, firstPreliminaryRequest?.totalFunds, scenarioOutput, t]);
};

export const useGetHoldingItems = () => {
  const { t } = useTranslation(['login', 'report']);
  return useMemo(() => {
    return [
      {
        title: t('fields.companyType'),
        value: 'Navian Tech AB',
      },
      {
        title: t('report:projectProperties.companyNumber'),
        value: '559083-0179',
      },
      {
        title: t('report:projectProperties.website'),
        value: 'https://navian.io/',
      },
      {
        title: t('report:projectProperties.address'),
        value: `C/O K10, I Stockholm AB
        Kungsgatan 8
        Stockholm, Sweden, 111 43`,
      },
    ];
  }, [t]);
};

export const useGetBorrowerItems = () => {
  const { t } = useTranslation(['login', 'report']);
  return useMemo(() => {
    return [
      {
        title: t('fields.companyName'),
        value: 'N/A',
      },
      {
        title: t('report:projectProperties.companyNumber'),
        value: 'N/A',
      },
      {
        title: t('report:projectProperties.legalStructure'),
        value: 'N/A',
      },
    ];
  }, [t]);
};
export const useGetBusinessItems = () => {
  const { t } = useTranslation('financing');
  return useMemo(() => {
    return [
      {
        title: t('holding.beneficialOwners'),
        value: 'N/A',
      },
      {
        title: t('holding.businessDescription'),
        value: 'Real Estate Developer',
      },
    ];
  }, [t]);
};
