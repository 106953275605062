import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { DialogProps } from '@mui/material/Dialog';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';

import { SearchField, SearchFieldProps } from '../search-field';

interface CustomDialogProps {
  dialogProps: DialogProps;
  showDialogTitle?: boolean;
  showCustomHead?: boolean;
  customHeadTitle?: string;
  customHeadButtonTitle?: string;
  customHeadBtnProps?: ButtonProps;
  searchFieldProps?: SearchFieldProps;
  dialogHeadChildren?: ReactNode | string;
  dialogContentChildren?: ReactNode;
  dialogActionsChildren?: ReactNode;
  customFilters?: ReactNode;
  dialogTitleSx?: SxProps<Theme>;
  dialogContentSx?: SxProps<Theme>;
  dialogActionsSx?: SxProps<Theme>;
}

export const CustomDialog = (props: CustomDialogProps) => {

  const {
    dialogProps,
    showDialogTitle = false,
    showCustomHead = false,
    dialogHeadChildren,
    dialogContentChildren,
    dialogActionsChildren,
    dialogContentSx,
    dialogTitleSx,
    dialogActionsSx,
    customHeadTitle,
    customFilters,
    searchFieldProps,
    customHeadBtnProps,
    customHeadButtonTitle,
  } = props;

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      PaperProps={{
        sx: {
          maxHeight: 'calc(100vh - 64px)',
          m: 'auto',
        },
      }}
      {...dialogProps}
    >
      {showDialogTitle &&
        <DialogTitle
          sx={{
            p: (theme) => theme.spacing(3, 3, 2, 3),
            ...dialogTitleSx,
          }}
        >
          {dialogHeadChildren}
        </DialogTitle>
      }
      {showCustomHead &&
        <Box
          p='24px 24px 16px 24px'
          gap={3}
          display='flex'
          flexDirection='column'
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography variant='h6'>{customHeadTitle}</Typography>
            {customHeadButtonTitle &&
              <Button
                {...customHeadBtnProps}
              >
                {customHeadButtonTitle}
              </Button>
            }
          </Box>
          <Stack gap={1.5} direction='row'>
            {customFilters}
            <SearchField
              {...searchFieldProps}
            />
          </Stack>
        </Box>
      }
      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          pt: 1,
          border: 'none',
          ...dialogContentSx,
        }}
      >
        {dialogContentChildren}
      </DialogContent>
      <DialogActions
        sx={{
          borderTop: '1px solid var(--components-divider, rgba(145, 158, 171, 0.20))',
          ...dialogActionsSx,
        }}
      >
        {dialogActionsChildren}
      </DialogActions>
    </Dialog>
  );
};
