import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { arrayMoveImmutable } from 'array-move';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortEnd } from 'react-sortable-hoc';

import { CoverImagesType } from '../../../../types';
import { HiddenRHFTextField } from '../hook-form';
import { Iconify } from '../iconify';
import { getFileConfig } from '../upload-file';
import { SortableList } from './list';

export interface UploadPhotosProps {
  boxSx?: SxProps<Theme>;
  imageSx?: SxProps<Theme>;
  chipSx?: SxProps<Theme>;
  uploadBoxError?: boolean;
  uploadBoxHelperText?: string;
  formName?: string;
  coverImages?: CoverImagesType[];
  noScrollToError?: boolean;
  deleteCoverImageHandler?: (file: CoverImagesType, updatedImages: CoverImagesType[]) => void;
  sortCoverImages?: (files: CoverImagesType[]) => void;
  uploadCoverImagesHandler?: (files: CoverImagesType[]) => void;
  setImageAsCoverHandler?: (file: CoverImagesType) => void;
}

export const UploadPhotos = (props: UploadPhotosProps) => {
  const {
    uploadCoverImagesHandler,
    coverImages = [],
    deleteCoverImageHandler,
    setImageAsCoverHandler,
    sortCoverImages,
    boxSx,
    formName = '',
    imageSx,
    chipSx,
    uploadBoxError,
    uploadBoxHelperText,
    noScrollToError,
  } = props;

  const theme = useTheme();
  const [clickedPhoto, setClickedPhoto] = useState<CoverImagesType | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onCoverClick = () => {
    const imageToSetAsCover = coverImages.find(image => image._id === clickedPhoto?._id);
    if (imageToSetAsCover) {
      setImageAsCoverHandler?.(imageToSetAsCover);
    }
    closeMenu();
  };

  const onRemoveClick = () => {
    const imageToDelete = coverImages.find(image => image._id === clickedPhoto?._id);
    if (imageToDelete) {
      const filteredImages = coverImages?.filter(image => image._id !== imageToDelete._id) || [];
      const isCoverExist = filteredImages.find(image => image.isCoverImage);
      const updatedImages = filteredImages.map((image, index) => ({
        ...image,
        isCoverImage: (isCoverExist && filteredImages.length !== 1) ? image.isCoverImage : index === 0,
      }));
      deleteCoverImageHandler?.(imageToDelete, updatedImages);
    }
    closeMenu();
  };

  const onActionsClick = (coverImage: CoverImagesType, actionBtn: HTMLButtonElement) => {
    setClickedPhoto(coverImage);
    setAnchorEl(actionBtn);
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: SortEnd) => {
    if (oldIndex !== newIndex) {
      sortCoverImages?.(arrayMoveImmutable(coverImages, oldIndex, newIndex));
    }
  };

  const onUploadDrop = (files: File[]) => {
    const updatedFiles: CoverImagesType[] = files.map((file, index) => {
      const localUrl = URL.createObjectURL(file);
      return {
        _id: localUrl,
        url: localUrl,
        fileType: file.type,
        filename: file.name,
        sequenceNumber: coverImages.length + index,
        isCoverImage: coverImages.length === 0 && index === 0,
        ...file,
        file: file,
        type: getFileConfig(file.type).type,
      };
    });
    uploadCoverImagesHandler?.(updatedFiles);
  };

  return (
    <>
      {!noScrollToError && <HiddenRHFTextField formName={formName} />}
      <SortableList
        error={uploadBoxError}
        helperText={uploadBoxHelperText}
        coverImages={coverImages}
        onSortEnd={onSortEnd}
        pressDelay={200}
        axis='xy'
        boxSxProps={boxSx}
        imageSxProps={imageSx}
        chipSx={chipSx}
        onActionsClick={onActionsClick}
        onUploadDrop={onUploadDrop}
      />

      <Menu
        id='upload-photo-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
      >
        <MenuItem onClick={onCoverClick}>
          <Stack direction='row' alignItems='center' gap={1}>
            <Iconify icon='solar:camera-add-bold' />
            {t('files.setAsCover')}
          </Stack>
        </MenuItem>
        <MenuItem onClick={onRemoveClick}>
          <Stack
            color={theme.palette.error.main}
            direction='row'
            alignItems='center'
            gap={1}
          >
            <Iconify icon='solar:trash-bin-trash-bold' />
            {t('remove')}
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};
