import { CompanyType, UserRole } from '../../../types';
import { LabelTabType } from '../components';
import { paths } from './paths';

interface RoleAccess {
  allowedPaths?: string[];
  allowedTabs?: LabelTabType[];
  defaultPath: string;
  allowedProfileMenuTabs?: string[];
}

export const rolesAccess: Record<UserRole, RoleAccess> = {
  'user': {
    allowedPaths: ['/dashboard', '/opportunities', '/packaging', '/distribution', '/marketplaces', '/users', '/partners', '/templates', '/settings', '/integrations', '/financing', '/sell'],
    allowedTabs: ['overview', 'reports', 'team', 'files', 'assets', 'suppliers', 'financial', 'offering', 'raise-monitoring'],
    defaultPath: paths.leads.list,
  },
  'admin': {
    allowedPaths: ['/dashboard', '/opportunities', '/packaging', '/distribution', '/marketplaces', '/users', '/partners', '/templates', '/settings', '/integrations', '/financing', '/sell'],
    allowedTabs: ['overview', 'reports', 'team', 'files', 'assets', 'suppliers', 'financial', 'offering', 'raise-monitoring'],
    defaultPath: paths.leads.list,
  },
  'analyst': {
    allowedPaths: ['/opportunities'],
    allowedTabs: ['overview', 'reports', 'team', 'files', 'assets', 'raise-monitoring'],
    defaultPath: paths.leads.list,
  },
  'project-manager': {
    allowedPaths: ['/packaging', '/distribution', '/partners', '/financing'],
    allowedTabs: ['overview', 'reports', 'team', 'files', 'assets', 'suppliers', 'financial', 'offering', 'raise-monitoring'],
    defaultPath: paths.projectPreparation.list,
  },
  'external-user': {
    allowedPaths: ['/dashboard', '/opportunities', '/packaging', '/distribution', '/marketplaces', '/users', '/partners', '/templates', '/settings', '/integrations', '/financing', '/sell'],
    allowedTabs: ['overview', 'reports', 'team', 'files', 'assets', 'suppliers', 'financial', 'offering', 'raise-monitoring'],
    defaultPath: paths.leads.list,
  },
  'investor': {
    allowedPaths: ['/investor'],
    allowedTabs: ['overview', 'reports', 'team', 'files', 'assets', 'suppliers', 'financial', 'offering', 'raise-monitoring'],
    defaultPath: paths.investorPortal.projects.list,
    allowedProfileMenuTabs: [paths.profile.userProfile],
  },
};

export const companyTypeAccess: Record<CompanyType, RoleAccess> = {
  'Investor': { defaultPath: paths.investorPortal.projects.list },
  'Equity Partner': { defaultPath: paths.partnerPortal.overview },
  'Developer': { defaultPath: paths.dashboard },
  'Bank': { defaultPath: paths.bankDashboard },
  'Supplier': { defaultPath: paths.supplierDashboard },
  'Other': { defaultPath: paths.dashboard },
};
