import { TableCell, TableRow } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCustom } from 'src/components/table';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { TableHeadType } from 'src/features/leads-page';
import { fNumber } from 'src/utils';

import { Scenario } from '../../../../../types';

export interface BRFTableProps {
  scenario?: Scenario;
}

export const BRFTable = (props: BRFTableProps) => {
  const { scenario } = props;
  const units = scenario?.calculate?.input?.units_array || [];

  const brfLoanData = useMemo(() => units.filter((unit) => unit.property_type === 'BRF Loan (Sweden only)').map((unit) => ({
    unit: 'BRF Loan',
    unitSize: unit.size,
    sizePrice: ('price_per_unit' in unit && typeof unit.size !== 'string') ? (unit.price_per_unit || 0) / (unit.size || 1) : '',
    totalPrice: 'total_price' in unit && unit.total_price ? unit.total_price : '-',
  })), [units]);

  const { t } = useTranslation('report');
  const tableHead: TableHeadType[] = [
    {
      _id: '',
      label: t('common:companyTypes.Other'),
      width: 180,
    },
    {
      _id: 'pricePerSqm',
      label: t('projectProperties.pricePerSqp'),
      align: 'right',
      width: 120,
    },
    {
      _id: 'sizeSqm',
      label: t('projectProperties.sizeSqm'),
      align: 'right',
      width: 120,
    },
    {
      _id: 'totalPrice',
      label: t('projectProperties.totalPrice'),
      align: 'right',
      width: 120,
    },
  ];

  if (brfLoanData?.length === 0) {
    return null;
  }

  return (
    <TableCustom
      headLabels={tableHead}
      noDataFound={brfLoanData?.length === 0}
      tableContainerSx={{
        borderRadius: '16px',
        border: '1px solid #f4f6f8',
        boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)',
      }}
      tableMinWidth={0}
      bodyCells={
        <>
          {brfLoanData?.map((unit, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{unit.unit}</TableCell>
                <TableCell align='right'>
                  {fNumber(unit.sizePrice)} {DEFAULT_CURRENCY}
                </TableCell>
                <TableCell align='right'>
                  {unit.unitSize}
                </TableCell>
                <TableCell align='right'>
                  {fNumber(unit.totalPrice)} {DEFAULT_CURRENCY}
                </TableCell>
              </TableRow>
            );
          })}
        </>
      }
    />
  );
};
