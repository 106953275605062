import { SendRequestFormType } from 'src/components';

import { apiFetch } from '../api';

export const sendSupportRequest = async (data: SendRequestFormType) => {
  try {
    const {
      files = [],
      ...rest
    } = data;
    const response = await apiFetch('integration/monday/send-support-request', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(rest),
      returnError: true,
    });
    if (!response._id) {
      throw new Error('Failed to create support request');
    }

    await attachFileToSupportRequest(response._id, files);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const attachFileToSupportRequest = async (requestId: string, files: SendRequestFormType['files'] = []) => {
  try {
    await Promise.all(files.map(async (file) => {
      await apiFetch(`integration/monday/upload-file/${requestId}/${file.filename}`, {
        method: 'post',
        headers: { 'Content-Type': file.file?.type || file.filetype || 'image/png' },
        body: file.file,
      });
    }));
  } catch (error) {
    console.error(error);
    throw new Error('Failed to attach file to support request');
  }
};

