import _ from 'lodash';

import {
  ApiFetchReturnType,
  AssignmentMember,
  DashboardOverviewType,
  DetailViewData,
  InvestmentActivityListResponse,
  MarketplaceInvestorResponse,
  OfferingResponse,
  OpportunitiesResponse,
  PaginationParams,
  PublishOpportunityType,
} from '../../../../types';
import { apiFetch } from '../api';
import { getFileSections } from '../app';
import { assigneeTeamMember } from '../lead';
import { setDetailViewData } from '../storage';
import { AppThunk } from '../store';
import { DeleteOfferingActionTypes, GetInvestmentActivityParams, GetOngoingOfferingsParams, LoadPartnersPortalParams } from './types';

export const getNewOpportunities = async (paramBody: LoadPartnersPortalParams, partnerId: string) => {
  try {
    const response: OpportunitiesResponse = await apiFetch('partners-portal/opportunities-list', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      body: JSON.stringify(paramBody),
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteOffering = async (offeringId: string, action: DeleteOfferingActionTypes = 'offering', partnerId: string) => {
  try {
    await apiFetch(`partners-portal/offering-delete/${offeringId}/${action}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const getClosedDeals = async (paramBody: LoadPartnersPortalParams, partnerId: string) => {
  try {
    return await apiFetch('partners-portal/closed-deals-list', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      body: JSON.stringify(paramBody),
    });
  } catch (error) {
    console.error(error);
  }
};

export const getOngoingOfferings = async (paramBody: GetOngoingOfferingsParams, partnerId: string) => {
  try {
    const response: OfferingResponse = await apiFetch('partners-portal/ongoing-offerings-list', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      body: JSON.stringify(paramBody),
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getPartnersInvestmentActivityList = async (paramBody: Partial<GetInvestmentActivityParams>, partnerId: string) => {
  try {
    const response: InvestmentActivityListResponse = await apiFetch('partners-portal/investment-activity-list', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      body: JSON.stringify(paramBody),
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getContactsList = async (paramBody: PaginationParams, partnerId: string) => {
  try {
    const response: MarketplaceInvestorResponse = await apiFetch('partners-portal/contacts-list', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      body: JSON.stringify(paramBody),
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getOfferingDetail = (projectId: string, partnerId: string): AppThunk => async (dispatch) => {
  try {
    const response: DetailViewData = await apiFetch(`partners-portal/offering-detail/${projectId}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
    if (response.project?._id) {
      dispatch(getFileSections(response.lead?._id, response.project?._id));
      dispatch(setDetailViewData(response));
    }
  } catch (error) {
    console.error(error);
  }
};


export const shareOfferingWithEmails = async (offeringId: string, partnerId: string, emails: string[]): Promise<ApiFetchReturnType> => {
  try {
    const requests = emails.map(email =>
      apiFetch(`partners-portal/offering-share/${offeringId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'partnerId': partnerId,
        },
        body: JSON.stringify({ email }),
      }),
    );
    const responses = await Promise.all(requests);
    const hasInvalidResponse = responses.some(response => !response._id);

    if (hasInvalidResponse) {
      throw new Error('An unknown error occurred');
    }
    return {
      data: responses,
      message: 'Offering shared successfully',
      success: true,
    };
  } catch {
    return {
      success: false,
      message: 'An unknown error occurred',
      data: null,
    };
  }
};

export const deleteDocumentFromOffering = async (offeringId: string, documentId: string, partnerId: string) => {
  try {
    await apiFetch(`partners-portal/document-delete/${offeringId}/${documentId}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const publishOffering = async (projectId: string, body: PublishOpportunityType, partnerId: string, leadId?: string, offeringId?: string) => {
  try {
    const {
      documents,
      assignedSalesManager,
      assignedSalesManagerToDelete,
      documentsToDelete,
    } = body;

    const filteredDocs = documents?.map(doc => ({ _id: doc._id }));
    const filteredDocToDelete = documentsToDelete?.map(doc => doc._id);
    const omittedBody = _.omit(body, ['assignedSalesManager', 'assignedSalesManagerToDelete', 'documentsToDelete', 'documents']);

    filteredDocToDelete?.forEach((doc) => doc && deleteDocumentFromOffering(offeringId || '', doc, partnerId));

    const response = await apiFetch(`partners-portal/offering-create/${offeringId || ''}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      body: JSON.stringify({
        projectId,
        documents: filteredDocs,
        ...omittedBody,
      }),
    });
    if (response && leadId) {
      const members: AssignmentMember[] = [];
      (assignedSalesManager && !assignedSalesManager._id) && members.push(assignedSalesManager);
      assignedSalesManagerToDelete && members.push(assignedSalesManagerToDelete);
      await assigneeTeamMember(leadId, partnerId, members);
    }

    return response;
  } catch (error) {
    console.error(error);
  }
};


export const getOverviewData = async (partnerId: string) => {
  try {
    const response: DashboardOverviewType = await apiFetch('partners-portal/overview', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};
