import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PartnerItemType, Partners, ProductType, SupplierProductType } from '../../../../types';
import { AddPartnerActionType, AddSupplierProductType, DeletePartnerActionType, DeleteSupplierProductType, SetPartnersActionType, UpdateCurrentPartnerActionType } from './types';


interface State extends Partners {
  currentPartner?: PartnerItemType;
  currentProduct?: ProductType;
  partnerUsers: PartnerItemType[];
}

export const initialState: State = {
  suppliers: [],
  banks: [],
  equityPartners: [],
  clients: [],
  allPartners: [],
  partnerUsers: [],
};

const Slice = createSlice({
  name: 'Partners',
  initialState,
  reducers: {
    setPartners(state, action: PayloadAction<SetPartnersActionType>) {
      state[action.payload.partnerKey] = action.payload.partners;
    },
    setPartnerUsers(state, action: PayloadAction<PartnerItemType[]>) {
      state.partnerUsers = action.payload;
    },
    addPartner(state, action: PayloadAction<AddPartnerActionType>) {
      state[action.payload.partnerKey] = [...state[action.payload.partnerKey], action.payload.newPartner];
    },
    addProduct(state, action: PayloadAction<AddSupplierProductType>) {
      state.currentPartner = {
        ...state.currentPartner,
        _id: action.payload.partnerId,
        products: [...(state.currentPartner?.products || []), action.payload.product],
        productAmount: (state.currentPartner?.productAmount || 0) + 1,
      };
    },
    deleteProduct(state, action: PayloadAction<DeleteSupplierProductType>) {
      state.currentPartner = {
        ...state.currentPartner,
        _id: action.payload.partnerId,
        products: state.currentPartner?.products?.filter(product => product._id !== action.payload.productId),
        productAmount: (state.currentPartner?.productAmount || 1) - 1,
      };
    },
    deletePartner(state, action: PayloadAction<DeletePartnerActionType>) {
      state[action.payload.partnerKey] = state[action.payload.partnerKey].filter(partner => partner._id !== action.payload.partnerId);
    },
    setCurrentPartner(state, action: PayloadAction<PartnerItemType>) {
      state.currentPartner = action.payload;
    },
    setCurrentProduct(state, action: PayloadAction<SupplierProductType>) {
      state.currentProduct = action.payload;
    },
    updateCurrentProduct(state, action: PayloadAction<Partial<ProductType>>) {
      if (state.currentProduct) {
        state.currentProduct = {
          ...state.currentProduct,
          ...action.payload,
        };
        if (state.currentPartner) {
          state.currentPartner = {
            ...state.currentPartner,
            products: state.currentPartner.products?.map(product => product._id === action.payload._id ? { ...product, ...action.payload } : product),
          };
        }
      }
    },
    updateCurrentPartner(state, action: PayloadAction<UpdateCurrentPartnerActionType>) {
      if (state.currentPartner) {
        state.currentPartner = { ...state.currentPartner, ...action.payload.partner };
        state[action.payload.partnerKey] = state[action.payload.partnerKey].map(partner => partner._id === action.payload.partner?._id ? {
          ...partner,
          ...action.payload.partner,
        } : partner);
      }
    },
  },
});

export const {
  reducer: PartnersReducer,
  actions: {
    setPartners,
    setPartnerUsers,
    addPartner,
    deletePartner,
    updateCurrentPartner,
    setCurrentPartner,
    deleteProduct,
    addProduct,
    updateCurrentProduct,
    setCurrentProduct,
  },
} = Slice;

export * from './actions';
