// scrollbar
import 'simplebar-react/dist/simplebar.min.css';
// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
// map
import 'mapbox-gl/dist/mapbox-gl.css';
// editor
import 'react-quill/dist/quill.snow.css';
// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// image
import 'react-lazy-load-image-component/src/effects/blur.css';
import './i18n';

import { Provider } from 'react-redux';

import { MotionLazy, ProgressBar, SettingsDrawer } from './components';
import { LocalizationProvider, SettingsProvider, SnackbarProvider, ThemeProvider } from './config';
import { Router } from './router';
import { store } from './store';

function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light',
            themeDirection: 'ltr',
            themeContrast: 'default',
            themeLayout: 'vertical',
            themeColorPresets: 'default',
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <SettingsDrawer />
                <ProgressBar />
                <Router />
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
