import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { CustomSnackbar, LoadingScreen, SplashScreen } from './components';
import { routeConfig } from './config';
import { useEmailChangeToken, useLayoutByPath, usePortalFlags, useRestrictRouts } from './hooks';
import { RootState } from './store';
import { changeUserEmailByToken, getCompanyInvites, loadConfig } from './store/app';
import { setCompanyInvites, setCompanyInvitesLoadStatus } from './store/auth';
import { loadNotifications } from './store/storage';
import { authHelper } from './utils';

export const Router = () => {
  const Layout = useLayoutByPath();
  useRestrictRouts();
  const token = useEmailChangeToken();

  const dispatch = useDispatch();
  const configStatus = useSelector((state: RootState) => state.App.configStatus);
  const authStatus = useSelector((state: RootState) => state.Auth.status);
  const companyInvites = useSelector((state: RootState) => state.Auth.companyInvites);
  const companyInvitesLoadStatus = useSelector((state: RootState) => state.Auth.companyInvitesLoadStatus);
  const user = useSelector((state: RootState) => state.Auth.user);
  const { isInvestorPortal } = usePortalFlags();

  const hasInvite = companyInvites.some(invite => invite?.userId !== undefined);

  const loadCompanyInvites = async () => {
    const invites = await getCompanyInvites({ isUser: true });
    dispatch(setCompanyInvites(invites));
    dispatch(setCompanyInvitesLoadStatus(true));
  };

  useEffect(() => {
    dispatch(loadConfig(() => {
      dispatch(loadNotifications({ notificationType: 'notifications' }));
      const intervalId = setInterval(() => {
        dispatch(loadNotifications({ notificationType: 'notifications' }));
      }, 60_000);
      return () => clearInterval(intervalId);
    }));
    loadCompanyInvites();
  }, [dispatch]);

  useEffect(() => {
    if (token && user?.id) {
      dispatch(changeUserEmailByToken(token));
    }
  }, [dispatch, token, user?.id]);

  useEffect(() => {
    if (user?.id && companyInvitesLoadStatus) {
      authHelper({
        authStatus,
        user,
        isAuthCompleted: hasInvite,
      });
    }
  }, [user, authStatus, hasInvite, companyInvitesLoadStatus]);

  if (configStatus === 'load' || authStatus === 'load' || configStatus === 'new' || token) {
    return <SplashScreen />;
  }

  return (
    <Layout mainSx={isInvestorPortal ? { pl: 5 } : {}}>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {Object.values(routeConfig).map((route, index) => {
            return (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            );
          })}
        </Switch>
        <CustomSnackbar />
      </Suspense>
    </Layout>
  );
};
