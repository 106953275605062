import { TableHeadType } from '../leads-page';
import { DealsTableFilters, DealsTableParams } from './types';

export const dealsTableHead: TableHeadType[] = [
  {
    _id: '',
    label: 'Service / Date',
    width: 130,
  },
  {
    _id: '',
    label: 'Project',
    width: 90,
  },
  {
    _id: '',
    label: 'Project Owner',
    width: 90,
  },
  {
    _id: '',
    label: 'Status',
    width: 140,
  },
  {
    _id: 'controls',
    width: 50,
  },
];

export const defaultFilters: DealsTableFilters = {
  text: '',
  projectType: [],
  status: 'New',
};


export const allFilters: DealsTableFilters = {
  ...defaultFilters,
  status: 'All',
};

export const defaultDealsTableCorrespondingBody: DealsTableParams = {
  page: 1,
  limit: 5,
  status: 'New',
};
