import 'src/utils/highlight';

import { alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import { StyledEditor } from './styles';
import { formats, Toolbar } from './toolbar';
import { EditorProps } from './types';

export const Editor = (props: EditorProps) => {
  const {
    id = 'minimal-quill',
    error,
    simple = false,
    helperText,
    sx,
    headings,
    ...other
  } = props;
  const { t } = useTranslation();

  const modules = {
    toolbar: { container: `#${id}` },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: { matchVisual: false },
  };

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
            '& .ql-editor': { bgcolor: (theme) => alpha(theme.palette.error.main, 0.08) },
          }),
          ...sx,
        }}
      >
        <Toolbar id={id} isSimple={simple} headings={headings} />

        <ReactQuill
          modules={modules}
          formats={formats}
          placeholder={t('writeAwesome')}
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
};
