import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FolderMailIcon } from 'src/assets';
import { AddRaiseOffer, OmittedSellOfferType } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { InfoModal } from 'src/features/debt-request';
import { useBoolean } from 'src/hooks';
import { RootState } from 'src/store';
import { addSellOfferThunk } from 'src/store/sell';
import { fNumber } from 'src/utils';

export const OverviewSaleOffer = () => {
  const theme = useTheme();
  const offerBool = useBoolean();
  const infoModal = useBoolean();
  const dispatch = useDispatch();
  const { t } = useTranslation('financing');
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const raiseMonitoring = detailsData.distribution;
  const offer = raiseMonitoring?.offers?.[0];
  const shared = raiseMonitoring?.shared?.[0];
  const price = detailsData?.lead?.price || 0;
  const buyerFeeAmount = detailsData?.lead?.buyerFeeAmount || 0;

  const sendOfferClickHandler = (data: OmittedSellOfferType) => {
    if (shared?._id) {
      dispatch(addSellOfferThunk(data, shared));
      offerBool.onFalse();
      infoModal.onTrue();
    }
  };

  const closeOfferDrawerHandler = () => {
    offerBool.onFalse();
  };

  const makeOnOfferClickHandler = () => {
    offerBool.onTrue();
  };

  return (
    <Card sx={{ background: alpha(theme.palette.info.main, 0.08) }}>
      <CardContent>
        <Stack gap={3}>
          <Stack>
            <Typography variant='body2'>
              {t('sale.projectPriceWithBuyersFee')}
            </Typography>
            <Typography variant='h3'>
              {fNumber(price + buyerFeeAmount)} {DEFAULT_CURRENCY}
            </Typography>
          </Stack>
          <Stack gap={2}>
            <Button
              fullWidth
              variant='contained'
              size='large'
            >
              {t('sale.contactSalesManager')}
            </Button>
            <Button
              fullWidth
              disabled={!!offer?._id}
              variant='outlined'
              size='large'
              onClick={makeOnOfferClickHandler}
            >
              {t('sale.makeAnOffer')}
            </Button>
          </Stack>

        </Stack>
      </CardContent>
      <AddRaiseOffer
        showDrawer={offerBool.value}
        headTitle={t('sale.makeAnOffer')}
        drawerOnClose={closeOfferDrawerHandler}
        sendOfferClickHandler={sendOfferClickHandler}
      />
      <InfoModal
        title={t('sale.yourOfferSentSuccessfully')}
        open={infoModal.value}
        icon={<FolderMailIcon />}
        onClose={infoModal.onFalse}
        btnTitle={t('common:close')}
        btnConfig={{ onClick: infoModal.onFalse }}
        subTitle={t('sale.ourSalesManagerWillContactYouShortly')}
      />
    </Card>
  );
};
