import { Card, CardHeader, Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AssigneeCard, CardDonutChart, EcommerceWidgetSummary, ReportNote } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';
import { RootState } from 'src/store';
import { extractFileExtension, fNumber } from 'src/utils';

import { DebtPartnerId, FileForUpload, NoteSettingType } from '../../../../../../types';
import { InfoBlock } from '../info-block';
import { useFinanceResult } from './use-finance-result';

export interface FinanceProps {
  noteSetting?: NoteSettingType;
  showTermSheet?: boolean;
}

export const Finance = (props: FinanceProps) => {
  const {
    noteSetting,
    showTermSheet,
  } = props;

  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const scenario = detailsData?.project?.scenario;
  const financing = detailsData?.financing;
  const seniorDebts = financing?.find(finance => finance.debts.find(debt => debt.section === 'Senior debt'));
  let fileToDisplay: Partial<FileForUpload> = {};
  let partner: Partial<DebtPartnerId> = {};
  const { t } = useTranslation(['report', 'common', 'financing']);

  seniorDebts?.debts?.find(debt => debt.offers?.find(offer => {
    const condition = (offer.isCalculate && offer.termSheetId?.url) || offer.termSheetId?.url;
    if (condition) {
      fileToDisplay = offer.termSheetId;
      partner = offer.partnerId;
    }
    return condition;
  }));

  const showFile = showTermSheet && fileToDisplay?.url;
  const cardDonutChartSx = showFile ? {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  } : {};

  const {
    rows,
    series,
  } = useFinanceResult(scenario?.calculate?.input, scenario?.calculate?.output);
  const mdUp = useResponsive('up', 'md');

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>{t('reportSections.finance')}</Typography>
      </Grid>
      {noteSetting?.isActive &&
        <Grid hidden={!mdUp} item md={12}>
          {scenario
            ? <ReportNote note={noteSetting.note} files={noteSetting.files} />
            : <Skeleton variant='rounded' height='150px' width='100%' />
          }
        </Grid>
      }
      <Grid item md={8} xs={12}>
        <CardDonutChart
          sx={cardDonutChartSx}
          showWidget={!!scenario}
          title={t('projectProperties.totalProjectCostUpper')}
          chartHeader={`${t('projectProperties.totalProjectCost')}, ${DEFAULT_CURRENCY}`}
          chart={{ series: series }}
        />
      </Grid>
      <Grid container item md={4} rowGap={3} xs={12}>
        <Grid item md={12} xs={12}>
          <EcommerceWidgetSummary
            showWidget={!!scenario}
            title={`${t('projectProperties.totalDebt')}, ${DEFAULT_CURRENCY}`}
            total={scenario?.calculate?.output?.['Total debt']}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <EcommerceWidgetSummary
            showWidget={!!scenario}
            title={`${t('projectProperties.financeCost')}, ${DEFAULT_CURRENCY}`}
            total={scenario?.calculate?.output?.['Finance cost']}
          />
        </Grid>
        <Grid item md={12} hidden={!mdUp}>
          <EcommerceWidgetSummary
            showWidget={!!scenario}
            title={t('projectProperties.profitForDeveloper')}
            total={scenario?.calculate?.output?.['% profit for developer']}
            postfix='%'
          />
        </Grid>
        {showFile &&
          <Grid item md={12} hidden={!mdUp}>
            <AssigneeCard
              headTitle={t('termSheet', { ns: 'financing' })}
              title={partner?.companyName}
              logoUrl={partner?.logoUrl}
              linkText={`Term Sheet${extractFileExtension(fileToDisplay?.filename)}`}
              linkTo={fileToDisplay?.url}
            />
          </Grid>
        }
      </Grid>
      <Grid item md={12} hidden={!mdUp}>
        {scenario ?
          <Card>
            <CardHeader title={t('projectProperties.financeCostsBreakdown')} />
            <Table sx={{ marginTop: '24px' }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('tableRows.type', { ns: 'common' })}
                  </TableCell>
                  <TableCell align='right'>{t('tableRows.totalFunds', { ns: 'common' })}, {DEFAULT_CURRENCY}</TableCell>
                  <TableCell align='right'>{t('tableRows.costs', { ns: 'common' })}, {DEFAULT_CURRENCY}</TableCell>
                  <TableCell align='right'>{t('tableRows.rate', { ns: 'common' })}</TableCell>
                  <TableCell align='right'>{t('tableRows.fees', { ns: 'common' })}</TableCell>
                  <TableCell align='right'>{t('tableRows.ltc', { ns: 'common' })}</TableCell>
                  <TableCell align='right'>{t('tableRows.ltgdv', { ns: 'common' })}</TableCell>
                  <TableCell align='right'>{t('tableRows.profitShare', { ns: 'common' })}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.type}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>{row.type}</TableCell>
                    <TableCell
                      align='right'>{row.total_funds === 0 ? '-' : fNumber(row.total_funds)}</TableCell>
                    <TableCell
                      align='right'>{row.costs === 0 ? '-' : fNumber(row.costs)}</TableCell>
                    <TableCell
                      align='right'>{row.rate === 0 ? '-' : fNumber(row.rate) + '%'}</TableCell>
                    <TableCell
                      align='right'>{row.fees === 0 ? '-' : fNumber(row.fees) + '%'}</TableCell>
                    <TableCell
                      align='right'>{row.ltc === 0 ? '-' : fNumber(row.ltc) + '%'}</TableCell>
                    <TableCell
                      align='right'>{row.ltgdv === 0 ? '-' : fNumber(row.ltgdv) + '%'}</TableCell>
                    <TableCell
                      align='right'
                    >
                      {row.profit_share === 0 ? '-' : fNumber(row.profit_share) + '%'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
          : <Skeleton variant='rounded' height='407px' width='100%' />
        }
      </Grid>
      <Grid item xs={12} hidden={mdUp}>
        <InfoBlock />
      </Grid>
    </Grid>
  );
};
