import { Avatar, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import { memo, MouseEvent } from 'react';
import { ConfirmDialog, ListItemCustom, MoreOptions, OptionType, StyledTableRow } from 'src/components';
import { allChipColors } from 'src/constants/constants';
import { useBoolean, usePopover } from 'src/hooks';
import { fDate, isCLickOnRow } from 'src/utils';

import { PartnerDashboardInterface } from '../../../../../types';

interface DealTableRowProps {
  row: PartnerDashboardInterface;
  onViewRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onDeclineRow: VoidFunction;
  onUploadTs: VoidFunction;
}

export const DealsRow = memo((props: DealTableRowProps) => {
  const {
    row,
    onViewRow,
    onDeleteRow,
    onDeclineRow,
    onUploadTs,
  } = props;

  const {
    status,
    project,
    createdAt,
    requestType,
    owner,
  } = row;

  const theme = useTheme();
  const confirm = useBoolean();
  const popover = usePopover();

  const preparationMoreOption: OptionType[] = [
    {
      label: 'Open',
      icon: 'view',
      closePopover: popover.onClose,
      itemOnClickHandler: onViewRow,
    },
    {
      label: 'Upload TS',
      icon: 'send',
      closePopover: popover.onClose,
      itemOnClickHandler: onUploadTs,
      hidden: status !== 'TS requested',
    },
    {
      label: 'Decline',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: onDeclineRow,
      hidden: status !== 'TS requested' && status !== 'New request' && status !== 'Offer sent',
      color: 'error.main',
    },
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: confirm.onTrue,
      hidden: status === 'Rejected' || status === 'TS signed',
      color: 'error.main',
    },
  ];

  const handleOnDeleteRow = () => {
    onDeleteRow();
    confirm.onFalse();
  };

  const handleRowOnClick = (event: MouseEvent<HTMLTableRowElement>) => {
    isCLickOnRow(event) && onViewRow();
  };

  return (
    <>
      <StyledTableRow hover onClick={handleRowOnClick}>
        <TableCell>
          <ListItemCustom
            primaryText={requestType}
            secondaryText={fDate(createdAt)}
            secondaryTextTypographySx={{
              color: theme.palette.text.secondary,
              typography: 'caption',
            }}
            listItemTextProps={{
              primaryTypographyProps: {
                whiteSpace: 'nowrap',
                variant: 'body2',
              },
            }}
          />
        </TableCell>
        <TableCell>
          <ListItemCustom
            primaryText={project?.name}
            secondaryText={project?.projectType}
            secondaryTextTypographySx={{
              color: theme.palette.text.secondary,
              typography: 'caption',
            }}
            listItemTextProps={{
              primaryTypographyProps: {
                whiteSpace: 'nowrap',
                variant: 'body2',
              },
            }}
          />
        </TableCell>
        <TableCell>
          <Stack alignItems='center' direction='row' gap={2}>
            <Avatar
              sx={{
                width: 24,
                height: 24,
              }}
              alt={owner?.companyName}
              src={owner?.logoUrl}
            />
            <Typography variant='body2'>{owner?.companyName}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Chip
            size='small'
            variant='outlined'
            color={allChipColors[status]}
            label={status}
          />
        </TableCell>
        <TableCell align='right'>
          <MoreOptions
            options={preparationMoreOption}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
            popoverOpen={popover.open}
          />
        </TableCell>
      </StyledTableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Are you sure want to delete?'
        action={
          <Button variant='contained' color='error' onClick={handleOnDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
});
