import { ListItemText, TableCell } from '@mui/material';
import { useState } from 'react';
import { StyledTableRow, TableCustom } from 'src/components';
import { useTable } from 'src/hooks';
import { fDate, showEmptyValue, sortCustom } from 'src/utils';

import { MarketplaceProjectActivity } from '../../../../../types';
import { funding_details_table_head } from '../mock-data';

export interface FundingTableProps {
  tableItems?: MarketplaceProjectActivity[];
}

export const FundingTable = (props: FundingTableProps) => {
  const { tableItems = [] } = props;
  const table = useTable({ tableKey: 'funding-table' });
  const [tableData] = useState<MarketplaceProjectActivity[]>(tableItems);

  const dataFiltered: MarketplaceProjectActivity[] = sortCustom(tableItems, table);

  return (
    <TableCustom
      tableMinWidth={0}
      headLabels={funding_details_table_head}
      table={table}
      rowCount={tableData?.length}
      noDataFound={dataFiltered.length === 0}
      bodyCells={(
        <>
          {dataFiltered?.map((row, index) => (
            <StyledTableRow
              key={index}
            >
              <TableCell>
                <ListItemText
                  primary={row.investorName}
                  secondary={row.investorEmail}
                  primaryTypographyProps={{ typography: 'body2' }}
                  secondaryTypographyProps={{
                    component: 'span',
                    typography: 'caption',
                    color: 'text.disabled',
                  }}
                />
              </TableCell>
              <TableCell align='right'>
                <ListItemText
                  primary={showEmptyValue(row.amount)}
                  secondary={showEmptyValue(fDate(row.lastUpdate))}
                  primaryTypographyProps={{
                    typography: 'body2',
                    ml: 'auto',
                  }}
                  secondaryTypographyProps={{
                    component: 'span',
                    typography: 'caption',
                    color: 'text.disabled',
                    ml: 'auto',
                  }}
                />
              </TableCell>
              <TableCell align='right'>
                <ListItemText
                  primary={showEmptyValue(row.reserved)}
                  secondary={showEmptyValue(fDate(row.createdAt))}
                  primaryTypographyProps={{
                    typography: 'body2',
                    ml: 'auto',
                  }}
                  secondaryTypographyProps={{
                    component: 'span',
                    typography: 'caption',
                    color: 'text.disabled',
                    ml: 'auto',
                  }}
                />
              </TableCell>
            </StyledTableRow>
          ))}
        </>
      )}
    />
  );
};
