import { useDispatch, useSelector } from 'react-redux';
import { UploadedFilesType } from 'src/components';
import { paths } from 'src/config';
import { useDocuments } from 'src/hooks';
import { RootState } from 'src/store';
import { loadPartnerDashboardData } from 'src/store/storage';
import { updateQuotation } from 'src/store/tendering';
import { openInNewTab } from 'src/utils';

import { PartnerDashboardInterface } from '../../../../types';
import { DealsPage } from './deals-page';
import { DealsTableParams } from './types';

export const SupplierDeals = () => {
  const dispatch = useDispatch();
  const { createAndUploadDocument } = useDocuments();
  const user = useSelector((state: RootState) => state.Auth.user);

  const onRowClickHandler = (item: PartnerDashboardInterface) => {
    openInNewTab(paths.reports.supplierReport(item.lead._id, item.reportIdentifier));
  };

  const loadSuppliersDeals = async (params: DealsTableParams) => {
    if (!user?.partnerId) return;
    return await loadPartnerDashboardData(params, 'supplier', user?.partnerId);
  };

  const uploadTsFileHandler = async (item: PartnerDashboardInterface, file: UploadedFilesType, callBack?: VoidFunction) => {
    if (item.termSheet?.sectionId) {
      const uploadedFile = await createAndUploadDocument(file, user?.partnerId || '', '', '', {
        quotationId: item._id,
        sectionIds: [item.termSheet.sectionId],
      });
      dispatch(updateQuotation(item._id, {
        termSheet: {
          ...item.termSheet,
          response: {
            constructionCost: 0,
            constructionPsqm: 0,
            buildTime: 0,
            partnerFee: 0,
            documentId: uploadedFile?._id ? [uploadedFile._id] : [],
          },
        },
      }, callBack));
    }
  };

  return (
    <DealsPage
      dashboardType='supplier'
      onRowClick={onRowClickHandler}
      uploadTsFileHandler={uploadTsFileHandler}
      loadDealsFunc={loadSuppliersDeals}
    />
  );
};
