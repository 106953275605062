import { LoadingButton } from '@mui/lab';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { DrawerCustom, Iconify, UploadedFilesType, UploadFile } from 'src/components';
import { UseBooleanReturnType } from 'src/hooks';

export interface UploadDealTsProps {
  drawer: UseBooleanReturnType;
  isLoading: UseBooleanReturnType;
  onSubmitClick: (file: UploadedFilesType) => void;
}

export const UploadDealTs = (props: UploadDealTsProps) => {
  const {
    drawer,
    isLoading,
    onSubmitClick,
  } = props;

  const [file, setFile] = useState<UploadedFilesType | null>(null);
  const theme = useTheme();

  const uploadFileHandler = (files: UploadedFilesType[]) => {
    setFile(files[files.length - 1]);
  };

  const onSubmitClickHandler = () => {
    if (file) {
      onSubmitClick(file);
    }
  };

  const onCloseDrawerHandler = () => {
    setFile(null);
    drawer.onFalse();
  };

  return (
    <DrawerCustom
      open={drawer.value}
      onCloseDrawerHandler={onCloseDrawerHandler}
      headChildren={
        <Stack
          width='100%'
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          gap={2}
        >
          <Typography variant='h6'>Upload Term Sheet</Typography>
          <IconButton
            onClick={onCloseDrawerHandler}
          >
            <Iconify icon='mingcute:close-line' width={18} />
          </IconButton>
        </Stack>
      }
      bodyChildren={
        <Stack gap={3}>
          <UploadFile
            containerSx={{ width: '100%' }}
            innerContainerSx={{ p: 2.5 }}
            section='Tendering'
            uploadTitle='Upload file'
            titleStyleConfig={{
              variant: 'body2',
              color: theme.palette.text.disabled,
            }}
            uploadedFiles={file ? [file] : []}
            uploadFileHandler={uploadFileHandler}
            fileIconSx={{ mr: 0 }}
            customIcon={
              <Iconify
                icon='eva:cloud-upload-fill'
                width={40}
                color={theme.palette.text.disabled}
              />
            }
          />
        </Stack>
      }
      footerChildren={
        <LoadingButton
          fullWidth
          onClick={onSubmitClickHandler}
          disabled={!file}
          variant='contained'
          size='large'
          loading={isLoading.value}
        >
          Confirm
        </LoadingButton>
      }
    />
  );
};
