import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ChatIcon } from 'src/assets';
import { CardRadialBarChart, DetailsList, generateSummaryField, ItemType, ListItemCustom } from 'src/components';
import { OverviewLocation } from 'src/features/investor-portal/projects/project-details/overview/components/overview-info/overview-location';
import { ReserveSharesDrawer } from 'src/features/investor-portal/projects/project-details/reserve-shares-drawer';
import { OverviewButtons } from 'src/features/report-detail';
import { useBoolean } from 'src/hooks';
import { RootState } from 'src/store';
import { fNumberWithDefaultCurrency, fPercent, getPartnerProfileUrl, openMailClickHandler } from 'src/utils';

import { DetailViewData } from '../../../../../../../../../../types';
import { OverviewDetails } from './overview-details';
import { OverviewInfoAbout } from './overview-info-about';

export type OverviewInfoProps = {
  isPartner?: boolean;
  showProposalDetails?: boolean;
  showInvestmentTarget?: boolean;
  showOverviewButtons?: boolean;
  showAddress?: boolean;
  showContactExpertButton?: boolean;
  isPreview?: boolean;
  totalValue?: number;
  investedValue?: number;
  openDetailedProjectClickHandler?: VoidFunction;
  openDocumentsClickHandler?: VoidFunction;
  detailsData?: DetailViewData;
}

export const OverviewInfo = (props: OverviewInfoProps) => {
  const {
    isPartner,
    isPreview,
    detailsData = {},
    showOverviewButtons,
    showProposalDetails,
    showInvestmentTarget,
    showContactExpertButton,
    showAddress,
    totalValue,
    investedValue = 0,
    openDocumentsClickHandler,
    openDetailedProjectClickHandler,
  } = props;

  const theme = useTheme();
  const drawer = useBoolean();
  const user = useSelector((state: RootState) => state.Auth.user);

  const developer = detailsData.developer;
  const debtFinancing = detailsData.debtFinancing;
  const assets = detailsData.assets;
  const project = detailsData.project;
  const lead = detailsData.lead;
  const scenario = project?.scenario;
  const activities = detailsData.offeringDetails?.activities?.data;
  const timeToMaturity = scenario?.calculate?.input.build_time_months;
  const minAmountToInvest = assets?.investing?.minInvestmentAmount;
  const projectROI = scenario?.calculate?.output.ROI;
  const numberOfInvestors = detailsData.offeringDetails?.investorsCount;
  const isUserInActivity = !!activities?.find(activity => activity.customerId === user?.id);
  const assignees = lead?.assignees;
  const salesManager = assignees?.find(assignee => assignee.designation === 'Sales manager');
  const isPartnerEquity = user?.partner?.companyType === 'Equity Partner';

  const fields: ItemType[] = useMemo(() => {
    return generateSummaryField({
      numberOfInvestors: numberOfInvestors,
      type: 'Equity',
      projectROE: fPercent(projectROI),
      minAmountToInvest: fNumberWithDefaultCurrency(minAmountToInvest),
      timeToMaturity: `${timeToMaturity} months`,
    });
  }, [projectROI, minAmountToInvest, numberOfInvestors, timeToMaturity]);

  return (
    <Stack direction='row' gap={3}>
      <Stack gap={3} flex={1}>
        <OverviewInfoAbout detailsData={detailsData} />
        <OverviewLocation
          showAddress={showAddress}
          mapHeight={449}
        />
        {showOverviewButtons &&
          <OverviewButtons
            openDocumentsClickHandler={openDocumentsClickHandler}
            openDetailedProjectClickHandler={openDetailedProjectClickHandler}
          />
        }
      </Stack>
      <Stack gap={3} sx={{ minWidth: 376 }}>
        {showProposalDetails &&
          <OverviewDetails />
        }
        {showInvestmentTarget &&
          <Card sx={{ p: '40px 24px' }}>
            <Stack gap={3}>
              <CardRadialBarChart
                chartOnly
                chartHeader='Investment Target'
                chartTotalFormat={fNumberWithDefaultCurrency}
                totalLabel='Total'
                customTotal={totalValue}
                chart={{
                  series: [
                    {
                      value: investedValue,
                      label: 'Total',
                    },
                  ],
                }}
              />
              <DetailsList
                fields={fields}
                labelContainerSx={{ fontSize: 14 }}
              />
              <Button
                size='large'
                variant='contained'
                onClick={drawer.onTrue}
                disabled={isUserInActivity || isPreview || !isPartnerEquity}
              >
                Reserve Shares
              </Button>
            </Stack>
          </Card>
        }
        {developer?._id &&
          <Card>
            <CardContent>
              <Stack gap={3}>
                <Typography variant='h6'>Developer</Typography>
                <ListItemCustom
                  showAvatar
                  avatarSx={{ borderRadius: 1.5 }}
                  avatarSrc={developer?.logoUrl}
                  avatarAlt={developer?.companyName}
                  primaryText={developer?.companyName}
                  secondaryChildren={
                    <Link
                      underline='none'
                      target='_blank'
                      href={getPartnerProfileUrl(developer)}
                    >
                      Profile
                    </Link>
                  }
                />
              </Stack>
            </CardContent>
          </Card>
        }
        {debtFinancing?._id &&
          <Card>
            <CardContent>
              <Stack gap={3}>
                <Typography variant='h6'>Debt Financing</Typography>
                <ListItemCustom
                  showAvatar
                  avatarSx={{ borderRadius: 1.5 }}
                  avatarSrc={debtFinancing.logoUrl}
                  avatarAlt={debtFinancing.companyName}
                  primaryText={debtFinancing.companyName}
                  secondaryChildren={
                    <Link
                      underline='none'
                      target='_blank'
                      href={getPartnerProfileUrl(debtFinancing)}
                    >
                      Profile
                    </Link>
                  }
                />
              </Stack>
            </CardContent>
          </Card>
        }

        {(!isPartner && salesManager?.profileLink) &&
          <Card>
            <CardContent>
              <Stack gap={4}>
                <ListItemCustom
                  showAvatar
                  avatarSrc={salesManager.name}
                  avatarAlt={salesManager.name}
                  primaryText={salesManager.name}
                  secondaryText='Sales Manager'
                  secondaryTextVariant='caption'
                  secondaryTextTypographySx={{ color: theme.palette.text.disabled }}
                />
                {showContactExpertButton &&
                  <Button
                    variant='outlined'
                    size='large'
                    startIcon={<ChatIcon width={24} height={24} />}
                    onClick={openMailClickHandler(salesManager.email)}
                  >
                    Contact us
                  </Button>
                }
              </Stack>
            </CardContent>
          </Card>
        }
      </Stack>
      <ReserveSharesDrawer drawer={drawer} />
    </Stack>
  );
};
