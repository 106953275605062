import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ChatIcon } from 'src/assets';
import { ListItemCustom } from 'src/components';
import { openMailClickHandler } from 'src/utils';

import { AssigneeType, ReportKeyType } from '../../../../../../../types';
import { OverviewFinOffer } from './overview-fin-offer';
import { OverviewOffer } from './overview-offer';
import { OverviewSaleOffer } from './overview-sale-offer';

type OverviewSidebarProps = {
  type: ReportKeyType;
  projectManager?: AssigneeType;
}

export const OverviewSidebar = (props: OverviewSidebarProps) => {
  const {
    type,
    projectManager,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const isBank = type === 'bank';
  const isSale = type === 'seller';
  const isSupplier = type === 'supplier';
  const showContactPerson = !isSale;

  return (
    <Stack
      gap={3}
      sx={{
        width: 320,
        [theme.breakpoints.down('md')]: {
          margin: 'auto',
          minWidth: 320,
          width: '100%',
        },
      }}
    >
      {isBank &&
        <OverviewFinOffer />
      }
      {isSupplier &&
        <OverviewOffer />
      }

      {isSale &&
        <OverviewSaleOffer />
      }

      {showContactPerson &&
        <Card>
          <CardContent>
            <Stack gap={4}>
              <ListItemCustom
                showAvatar
                avatarSrc={projectManager?.name}
                avatarAlt={projectManager?.name}
                primaryText={projectManager?.name}
                secondaryText={t('projectManager')}
                secondaryTextVariant='caption'
                secondaryTextTypographySx={{ color: theme.palette.text.disabled }}
              />
              <Button
                variant='outlined'
                size='large'
                startIcon={<ChatIcon width={24} height={24} />}
                onClick={openMailClickHandler(projectManager?.email)}
              >
                {t('contact')}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      }
    </Stack>
  );
};
