import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UNSPECIFIED_VALUE } from 'src/constants/constants';

import { CompanyType, UserRole } from '../../../types';
import { DetailViewTabType, getAllItems, useMenuData } from '../components';
import { companyTypeAccess, rolesAccess } from '../config';
import { RootState } from '../store';
import { usePathname } from './use-pathname';
import { useRouter } from './use-router';

export const useRestrictRouts = () => {
  const navData = useMenuData();
  const pathname = usePathname();
  const router = useRouter();
  const user = useSelector((state: RootState) => state.Auth.user);
  const userPartnerRole: UserRole = user?.partner?.role || 'admin';
  const allItems = getAllItems({ data: navData });
  const allowedProfileMenuTabs = rolesAccess[userPartnerRole]?.allowedProfileMenuTabs || [];
  const hadBanedProfileMenuTabs = allowedProfileMenuTabs.length > 0;
  const isInvestor = userPartnerRole === 'investor';
  const companyType: CompanyType = user?.partner?.companyType || 'Developer';

  const itemByPath = allItems.find(item => {
    return pathname.includes(item.path || UNSPECIFIED_VALUE);
  });

  const partnerItem = allItems.find(item => {
    if (item.path === undefined && item.group === itemByPath?.group) {
      return item;
    }
    return undefined;
  });

  useEffect(() => {
    if ((partnerItem?.disabled || itemByPath?.disabled) && userPartnerRole) {
      const pathToPush = isInvestor
        ? rolesAccess[userPartnerRole].defaultPath
        : companyTypeAccess[companyType].defaultPath;
      router.push(pathToPush);
    }
  }, [companyType, isInvestor, itemByPath?.disabled, partnerItem?.disabled, router, userPartnerRole]);

  useEffect(() => {
    if (!itemByPath && userPartnerRole && hadBanedProfileMenuTabs) {
      const pathToPush = rolesAccess[userPartnerRole].allowedProfileMenuTabs?.[0] || '/';
      router.push(pathToPush);
    }
  }, [hadBanedProfileMenuTabs, itemByPath, router, userPartnerRole]);

};

export const useRestrictTabs = (tabs: DetailViewTabType[], activeTab: string) => {
  const router = useRouter();
  const user = useSelector((state: RootState) => state.Auth.user);
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const itemByPath = tabs.find(item => {
    return item.value === activeTab;
  });

  useEffect(() => {
    if (itemByPath?.disabled && user && detailsData.lead?._id) {
      router.replace('overview');
    }
  }, [detailsData.lead?._id, itemByPath?.disabled, router, user]);
};
