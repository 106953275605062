import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CalculateInput, CalculateOutput } from '../../../../../../types';

interface Row {
  [key: string]: string | number;
}

export const useConstructionResult = (input: CalculateInput, output: CalculateOutput) => {
  const { t } = useTranslation(['report', 'common']);

  const rows = useMemo(() => {
    const rows: Row[] = [];
    output?.['Build costs breakdown']?.map?.((item) =>
      rows.push({
        type: item.title === 'Total construction costs' ? 'Total development costs' : item.title || 'Unspecified',
        total: item.total || 'Unspecified',
        build_costs: item.percentConstructionCost || 'Unspecified',
        total_costs: item.percentTotalCost || 'Unspecified',
        per_unit: item.perUnit || 'Unspecified',
      }));
    return rows;
  }, [output]);

  /*eslint-disable-next-line*/
  const costsBreakdown = useMemo(() => input?.construction_costs_array?.filter?.((item: any) => !!item.total_cost)?.reduce((sum: any, item: any) => ({
    ...sum,
    [item?.construction_cost_category]: (sum[item?.construction_cost_category] || 0) + item.total_cost,
  }), {}), [input]);

  const costBreakdownTotal = useMemo(() => Object.values(costsBreakdown || {}).reduce<number>((sum, item) => sum + (item as number), 0), [costsBreakdown]);

  const contingency = useMemo(() => {
    if (input?.contingency_total) {
      return input?.contingency_total;
    } else if (input?.contingency) {
      return Math.round((costBreakdownTotal / 100 * input?.contingency));
    }
  }, [costBreakdownTotal, input]);


  const series = useMemo(() => [[
    {
      label: t('reportSections.construction'),
      value: costsBreakdown?.Construction || 0,
    },
    {
      label: 'Pre - construction',
      value: costsBreakdown?.['Pre-construction'] || 0,
    },
    {
      label: 'Professional fees',
      value: costsBreakdown?.['Professional fees'] || 0,
    },
    {
      label: t('projectProperties.contingency'),
      value: contingency || 0,
    },
  ].filter(elem => elem.value !== 0),
  [
    {
      label: t('projectProperties.totalBuildCosts'),
      value: output?.['Total build costs'] || 0,
    },
    {
      label: t('projectProperties.otherCosts'),
      value: Math.round(((output?.['Total costs']) || 0) - costBreakdownTotal - (contingency || 0)) || 0,
    },
  ]], [contingency, costBreakdownTotal, costsBreakdown, output]);

  return {
    rows,
    series,
  };
};

