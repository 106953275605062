import { AppBar, IconButton, Stack, Toolbar, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { HEADER, NAV } from 'src/constants/constants';
import { useOffSetTop, useResponsive } from 'src/hooks';
import { RootState } from 'src/store';
import { bgBlur } from 'src/theme/css';

import { SvgColor } from '../svg-color';
import { AccountPopover, LanguagePopover, NotificationsPopover, Searchbar } from './components';

interface HeaderProps {
  onOpenNav: VoidFunction;
}

export const Header = (props: HeaderProps) => {
  const { onOpenNav } = props;

  const theme = useTheme();
  const isDeepSideMenuOpen = useSelector((state: RootState) => state.Storage.isDeepSideMenuOpen);
  const lgUp = useResponsive('up', 'lg');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const renderContent = (
    <>

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src='/assets/navbar/burger-menu-icon.svg' />
        </IconButton>
      )}

      <Searchbar />

      <Stack
        flexGrow={1}
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        spacing={{
          xs: 0.5,
          sm: 1,
        }}
      >
        <LanguagePopover />

        <NotificationsPopover />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({ color: theme.palette.background.default }),
        transition: theme.transitions.create(['height'], { duration: theme.transitions.duration.shorter }),
        height: HEADER.H_DESKTOP,
        ...(offsetTop && { height: HEADER.H_DESKTOP_OFFSET }),
        ...(lgUp &&
          { width: isDeepSideMenuOpen ? `calc(100% - ${NAV.W_MINI_WITH_SECTION}px)` : `calc(100% - ${NAV.W_MINI}px)` }
        ),
        ...(!lgUp &&
          { height: HEADER.H_DESKTOP_OFFSET }
        ),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
};
