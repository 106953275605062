import { useSelector } from 'react-redux';
import { DEFAULT_AREA, DEFAULT_CURRENCY, UNSPECIFIED_VALUE } from 'src/constants/constants';
import { TableHeadType } from 'src/features/leads-page';
import { RootState } from 'src/store';
import { fDate, fNumber } from 'src/utils';

import { Scenario } from '../../../../../types';
import { useScenarioLocalMarket } from '../components';
import { useScenarioCalculation } from './use-scenario-calculation';

export const useScenarioReport = (scenario?: Scenario) => {
  const comparables = useSelector((state: RootState) => state.Storage.comparables);
  const notes = useSelector((state: RootState) => state.Storage.notes);
  const scenarioOutput = scenario?.calculate?.output;
  const scenarioInput = scenario?.calculate?.input;

  const {
    medianPriceMonths,
    listingsPrices,
    medianPrices,
    distributionLabels,
    distributionData,
  } = useScenarioLocalMarket(scenario);
  const {
    timelineTotalCost,
    buildCostPsqft,
    costPSQM,
  } = useScenarioCalculation(scenario);
  const niagea = fNumber(scenarioInput?.['nia/gea'], '0.00');
  const totalNia = fNumber(scenarioOutput?.['Total nia']);
  return {
    proposedUnits: {
      scenario,
      note: notes?.find(note => note.section === 'Proposed Units')?.note,
    },
    timelineCashflow: {
      showData: Boolean(scenario),
      totalCosts: `${fNumber(timelineTotalCost)} ${DEFAULT_CURRENCY}`,
      costPSQM: (!buildCostPsqft || !costPSQM)
        ? UNSPECIFIED_VALUE
        : `${buildCostPsqft}/${costPSQM} ${DEFAULT_CURRENCY}`,
      niagea: niagea !== '0' ? niagea : UNSPECIFIED_VALUE,
      totalNia: totalNia !== '0' ? `${totalNia} ${DEFAULT_AREA}` : UNSPECIFIED_VALUE,
      purchaseComplete: fDate(scenarioOutput?.Timeline?.purchase_complete_month),
      constructionStarts: fDate(scenarioOutput?.Timeline?.construction_start_month),
      constructionComplete: fDate(scenarioOutput?.Timeline?.construction_complete_month),
      lastUnitSold: fDate(scenarioOutput?.Timeline?.exit_month),
      cashflowItemHeader: scenarioOutput?.['Main budget months']?.map(month => ({
        _id: month,
        label: month,
        align: 'right',
      })) as TableHeadType[],
      cashflowItems: scenarioOutput?.mainBudget,
    },
    localArea: {
      showData: Boolean(scenario),
      locality: scenario?.localArea?.locality,
      meanIncomeLocal: scenario?.localArea?.local?.meanIncomeLocal,
      medianAgeLocal: scenario?.localArea?.local?.medianAgeLocal,
      mapCoordinates: scenario?.locationService?.getGeoCodes?.geometry?.coordinates,
      narrativeAreaTitle: scenario?.localArea?.title,
      narrative: scenario?.localArea?.narrative,
      schools: scenario?.schools,
      stations: scenario?.stations,
    },
    localMarket: {
      showData: Boolean(scenario),
      marketMappingValues: scenario?.localMarket?.marketMappingValues?.result,
      ...scenario?.localMarket?.market,
      // TODO in future use another key
      narrativeTitle: scenario?.localArea?.title,
      narrative: scenario?.localMarket?.narrative,
      coordinates: scenario?.locationService?.getGeoCodes?.geometry?.coordinates,
      medianPriceMonths,
      medianPrices,
      listingsPrices,
      distributionLabels,
      distributionData,
    },
    comparables: {
      comparables,
      coordinates: scenario?.locationService?.getGeoCodes?.geometry?.coordinates,
    },
  };
};
