import { useMemo } from 'react';
import { generateSummaryField } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { fDate, fNumber, formatFullName } from 'src/utils';

import { Lead, Scenario } from '../../../../../../types';

export const useDefaultOverview = (lead?: Lead, scenario?: Scenario) => {
  const scenarioOutput = scenario?.calculate?.output;

  const summaryItems = useMemo(() => {
    return generateSummaryField({
      location: lead?.location?.address,
      totalUnits: scenarioOutput?.['Total units'],
      size: scenarioOutput?.['Residual land value'],
      mainTypeOfUnits: scenarioOutput?.['Main unit type'],
    });
  }, [lead?.location?.address, scenarioOutput]);

  const leadSummaryItems = useMemo(() => {
    return generateSummaryField({
      contactPerson: formatFullName(lead?.contactDetails?.firstName, lead?.contactDetails?.lastName),
      companyName: lead?.contactDetails?.company,
    });
  }, [lead?.contactDetails]);

  const learnMoreItems = useMemo(() => {
    return generateSummaryField({
      contactPerson: formatFullName(lead?.contactDetails?.firstName, lead?.contactDetails?.lastName),
      email: lead?.contactDetails?.email,
      phone: lead?.contactDetails?.phone,
      companyName: lead?.contactDetails?.company,
      source: lead?.source,
      createdBy: formatFullName(lead?.contactDetails?.firstName, lead?.contactDetails?.lastName),
      dateCreated: fDate(lead?.createdAt),
    });
  }, [lead?.contactDetails?.company, lead?.contactDetails?.email, lead?.contactDetails?.firstName, lead?.contactDetails?.lastName, lead?.contactDetails?.phone, lead?.createdAt, lead?.source]);

  const landFields = useMemo(() => [
    {
      label: 'Plot price',
      value: `${fNumber(lead?.price)} ${DEFAULT_CURRENCY}`,
    },
    {
      label: 'Location',
      value: lead?.location?.address,
      color: '#3698AF',
    },
  ], [lead?.location?.address, lead?.price]);


  return {
    summaryItems,
    leadSummaryItems,
    learnMoreItems,
    landFields,
  };
};
