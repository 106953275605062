import { debounce } from 'lodash';
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableCustom, TablePaginationCustom } from 'src/components';
import { paths } from 'src/config';
import { useRouter, useTable } from 'src/hooks';
import { RootState } from 'src/store';
import { deleteProductThunk, loadProducts } from 'src/store/partners';
import { LoadProductsParams } from 'src/store/partners/types';
import { sortCustom } from 'src/utils';

import { BankProductType } from '../../../../../../types';
import { bankTableHead } from '../../mock-data';
import { BankProductTableRow } from './bank-product-table-row';

export interface BankProductsTableProps {
  partnerId: string;
}

export const BankProductsTable = (props: BankProductsTableProps) => {
  const { partnerId } = props;
  const table = useTable({ tableKey: 'bank-products-table' });
  const dispatch = useDispatch();
  const router = useRouter();

  const [correspondingBody, setCorrespondingBody] = useState<LoadProductsParams>({
    page: 1,
    limit: table.rowsPerPage,
    partnerId: partnerId,
  });

  const currentPartner = useSelector((state: RootState) => state.Partners.currentPartner);
  const products = currentPartner?.products || [];
  const netCount = currentPartner?.netCount || 0;

  useEffect(() => {
    dispatch(loadProducts(correspondingBody));
  }, [dispatch, partnerId]);

  const updateProductList = useCallback((newBodyPart: Partial<LoadProductsParams>) => {
    const newBody: LoadProductsParams = {
      ...correspondingBody,
      ...newBodyPart,
    };
    dispatch(loadProducts(newBody));
    setCorrespondingBody(newBody);
  }, [correspondingBody, dispatch]);

  const dataFiltered: BankProductType[] = sortCustom(products, table);

  const noDataFound = dataFiltered?.length === 0;
  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage,
  );

  const handleDeleteRow = useCallback((id: string) => () => {
    dispatch(deleteProductThunk(partnerId, id));
    table.onUpdatePageDeleteRow(dataInPage?.length);
  }, [dataInPage?.length, dispatch, partnerId, table]);


  const onRowClickHandler = useCallback((id: string) => () => {
    router.push(paths.bankPartners.editProduct(partnerId, id));
  }, [partnerId, router]);

  const onPageChange = debounce((event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    updateProductList({ page: page + 1 });
    table.onChangePage(event, page);
  }, 200);

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updateProductList({
      limit: +event.target.value,
      page: 1,
    });
    table.onChangeRowsPerPage(event);
  };

  const onSortChange = debounce((id: string) => {
    const {
      orderBy,
      order,
    } = table;
    const isAsc = orderBy === id && order === 'asc';
    updateProductList({
      sort: id,
      sortType: isAsc ? 'desc' : 'asc',
    });
    table.onSort(id);
  }, 200);

  return (
    <>
      <TableCustom
        headLabels={bankTableHead}
        table={table}
        tableMinWidth={600}
        rowCount={netCount}
        noDataFound={noDataFound}
        onSort={onSortChange}
        bodyCells={(
          <>
            {products?.map((row) => (
              <BankProductTableRow
                key={row._id}
                item={row}
                onDeleteRowHandler={handleDeleteRow(row._id)}
                onRowClickHandler={onRowClickHandler(row._id)}
              />
            ))}
          </>
        )}
      />
      <TablePaginationCustom
        count={netCount}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </>
  );
};
