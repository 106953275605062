import { Grid, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EcommerceWidgetSummary, ReportNote } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';
import { RootState } from 'src/store';

import { NoteSettingType } from '../../../../../../types';

export interface ProjectMultiplesProps {
  noteSetting?: NoteSettingType;
}

export const ProjectMultiples = (props: ProjectMultiplesProps) => {
  const { noteSetting } = props;
  const scenario = useSelector((state: RootState) => state.Storage.detailsData.project?.scenario);
  const mdUp = useResponsive('up', 'md');
  const { t } = useTranslation(['report', 'common']);

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <Typography variant='h4' gutterBottom>{t('reportSections.projectMultiples')}</Typography>
      </Grid>
      {noteSetting?.isActive &&
        <Grid hidden={!mdUp} item md={12}>
          {scenario
            ? <ReportNote note={noteSetting.note} files={noteSetting.files} />
            : <Skeleton variant='rounded' height='150px' width='100%' />
          }
        </Grid>
      }
      <Grid item md={3} xs={6}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title={t('projectProperties.profitOnCost')}
          total={scenario?.calculate?.output?.['Profit on cost']}
          postfix='%'
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title={t('projectProperties.profitOnGdv')}
          total={scenario?.calculate?.output?.['Profit on GDV']}
          postfix='%'
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title={`${t('projectProperties.netProfit')}, ${DEFAULT_CURRENCY}`}
          total={scenario?.calculate?.output?.['Net profit']}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title={t('projectProperties.returnOnEquity')}
          total={scenario?.calculate?.output?.['ROI']}
          postfix='%'
        />
      </Grid>
    </Grid>
  );
};
